import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'

import { _ } from '../../helpers/translations'
import countries from '../../helpers/countries'
import { formatDate } from '../../helpers/utils'
import { timezones } from '../../helpers/timezones'
import { InitializeScroll, SetHeight } from '../../assets/js/common'
import createReactClass from 'create-react-class'
import { find } from 'lodash'


const AdminAccountComponent = createReactClass({
    propTypes: {
        account: PropTypes.object
    },
    componentDidMount() {
        SetHeight()
        InitializeScroll()
    },
    render() {
        const account = this.props.account
        return (
            <React.Fragment>
                <div className="box">
                    <div className="layout">
                        <div className="main_title">
                            <h1>{_('account.info.header')}</h1>
                        </div>
                        <div className="grid_2_first_s">
                            <div>
                                <div className="center profile_intro">
                                    <div className="index_top">
                                        <div className="profile_name">{account.name}</div>
                                        <div className="size_30">({account.username})</div>
                                        <div className="date">{_('Created on')} {formatDate(account.created_date)}</div>
                                        <Link className="change_password" to={`/accounts/${account.username}/change-password`}>{_('account.info.change-password')}</Link>
                                    </div>
                                </div>
                                <div className="tools">
                                    <div className="adjust">
                                        <div>
                                            <Link className="round sing" to={`/accounts/${account.username}/edit`}>
                                                <div className="edit_icon icon"></div>
                                            </Link>
                                            <div className="size_13 mtop_5">{_('common.edit')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="white_panel">
                                    <table className="profile_data">
                                       <tbody>
                                        <tr>
                                            <td><div className="label">{_('common.country')}:</div></td>
                                            <td>{(find(countries, i => i.code === account.country) || {}).name}</td>
                                        </tr>
                                        <tr>
                                            <td><div className="label">{_('common.timezone')}:</div></td>
                                            <td>{(find(timezones, i => i.value === account.timezone) || {}).text}</td>
                                        </tr>
                                        <tr>
                                            <td><div className="label">{_('common.phone')}:</div></td>
                                            <td>{account.phone}</td>
                                        </tr>
                                        <tr>
                                            <td><div className="label">{_('common.email')}:</div></td>
                                            <td>{account.email}</td>
                                        </tr>
                                        <tr>
                                            <td><div className="label">{_('common.language')}:</div></td>
                                            <td>{account.language.split('_')[0]}</td>
                                        </tr>
                                       </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>)
    }
})

let mapStateToProps = (state) => {
    const account = state.account
    return {
        account
    }
}

let mapDispatchToProps = () => ({
})

const AccountInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminAccountComponent)

export default AccountInfo
