/* global $ */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import createReactClass from 'create-react-class'
import axios from 'axios'

import { deleteProject } from '../../actions/project'

import { sortAndKeepNullsAtTheEnd, formatDate, Explained } from '../../helpers/utils'
import { _ } from '../../helpers/translations'

import * as conf from '../../conf'

import { InitializeDatatables, InitializeScroll, SetHeight } from '../../assets/js/common'
import { round } from 'lodash'


const ProjectsListComponent = createReactClass({
    propTypes: {
        isFetching: PropTypes.bool,
        accounts: PropTypes.array,
        projects: PropTypes.array,
        role: PropTypes.string,
        account: PropTypes.object,
        deleteProject: PropTypes.func,
    },
    componentDidMount() {
        SetHeight()
        InitializeDatatables()
        InitializeScroll()

        $('.sidebar').removeClass('opened')

        axios.get(conf.host_utils + '/active-projects?__a=1')
            .then(response => {
                if (response.status === 200) {
                    for (let i = 0; i < response.data.projects.length; i++) {
                        const activeProject = response.data.projects[i]
                        const filteredProjects = this.state.projects.filter(p => p.id === activeProject.id)
                        if (filteredProjects.length > 0) {
                            response.data.projects[i].connection = ''
                            if (filteredProjects[0].keys.length > 0) {
                                response.data.projects[i].connection = filteredProjects[0].keys[0].username
                            }
                        }
                    }
                    this.setState({ activeProjects: response.data.projects, totalCPH: response.data.total_calls_per_hour })
                }
            })
    },
    componentDidUpdate() {
        InitializeDatatables()
    },
    getInitialState() {
        return {
            projects: sortAndKeepNullsAtTheEnd(this.props.projects, 'created_date', false),
            filter: 'all',
            orderIdAsc: true,
            orderNameAsc: true,
            orderUsernameAsc: true,
            orderServicesAsc: true,
            orderTypeAsc: true,
            orderCreatedDateAsc: false,
            activeProjects: [],
            showActiveProjects: false,
            totalCPH: 0,
        }
    },
    componentWillReceiveProps(nextProps) {
        this.setState({ projects: sortAndKeepNullsAtTheEnd(nextProps.projects, 'created_date', false) })
    },
    filterProjects(projects) {
        const now = (new Date()).toISOString()
        let filtered_projects = []
        if (this.state.filter === 'ongoing') {
            filtered_projects = projects.filter(a => a.start_date <= now && a.end_date > now)
        } else if (this.state.filter === 'upcoming') {
            filtered_projects = projects.filter(a => a.start_date > now)
        } else if (this.state.filter === 'finished') {
            filtered_projects = projects.filter(a => a.end_date <= now)
        } else {
            filtered_projects = projects
        }
        return filtered_projects
    },
    checkIngester(e, project_uid) {
        e.stopPropagation()
        axios.get(conf.host_utils + '/ingester-not-working/result?__a=1&ingester=Instagram%20Hashtag&project_uid=' + project_uid)
            .then(response => {
                if (response.status === 200) {
                    const data = response.data
                    if (data.is_collecting) {
                        alert('This project should be collecting posts. Please double check. In case of any issues, contact tech support.')
                    } else {
                        alert('This project is NOT collecting posts due to following reason: ' + data.reason)
                    }
                }
            })
    },
    render() {
        const account = this.props.account
        const projects = this.filterProjects(this.state.projects)
        $('.table').DataTable().destroy()

        const all_accounts_by_ids = this.props.accounts.reduce((map, account) => {
            map[account.id] = {
                username: account.username,
            }
            return map
        }, {})
        const snsAbbr = {
            'instagram': 'ig',
            'twitter': 'tw',
            'facebook': 'fb',
            'vkontakte': 'vk',
            'tiktok': 'tk',
        }
        // const projectPriorities = {
        //     1: _('projects.info.priorities.high'),
        //     2: _('projects.info.priorities.medium'),
        //     3: _('projects.info.priorities.low')
        // }
        const projectStatus = (start, end) => {
            const now = (new Date()).toISOString()
            if (now < start) {
                return 'upcoming'
            } else if (start <= now && now < end) {
                return 'ongoing'
            }
            return 'finished'
        }
        const projectStatusIcon = (start, end) => {
            const status = projectStatus(start, end)
            let statusClass = 'project_status_finished_icon'
            let title = 'Finished'
            if (status === 'upcoming') {
                statusClass = 'project_status_upcoming_icon'
                title = 'Upcoming'
            } else if (status === 'ongoing') {
                statusClass = 'project_status_ongoing_icon'
                title = 'Ongoing'
            }
            return <div title={title} className={'icon_action sing relative ' + statusClass}><span style={{ visibility: 'hidden' }}>{title}</span></div>
        }
        // Add self
        all_accounts_by_ids[account.id] = { username: account.username }
        const activeProjects = () => {
            let trs = [
                <div key='a0' className='active-projects-table-header'><b>#</b></div>,
                <div key='a1' className='active-projects-table-header'><b>Account</b></div>,
                <div key='a2' className='active-projects-table-header'><b>Name</b></div>,
                <div key='a4' className='active-projects-table-header'><b>EventType</b></div>,
                <div key='a5' className='active-projects-table-header'><b>Tags Count</b></div>,
                <div key='a6' className='active-projects-table-header'><b>Connection</b></div>,
                <div key='a7' className='active-projects-table-header'><b>ig_hashtag rate</b></div>,
                <div key='a8' className='active-projects-table-header'><b>ig_phototag rate</b></div>,
            ]
            for (var i = 0; i < this.state.activeProjects.length; i++) {
                const p = this.state.activeProjects[i]
                const tagsLength = p.tags.split(',').length
                const cph = rate => round((3600 / rate) * tagsLength)
                trs.push(<div key={i + '0'}>{i + 1}</div>)
                trs.push(<div key={i + '1'}>{p.pretty_name}</div>)
                trs.push(<div key={i + '2'}>{p.name}</div>)
                trs.push(<div key={i + '4'}>{p.event_type}</div>)
                trs.push(<div key={i + '5'}>{tagsLength}</div>)
                trs.push(<div key={i + '6'}>{p.connection}</div>)
                trs.push(<div key={i + '7'}>{p.ig_hashtag_refresh_rate}s : {cph(p.ig_hashtag_refresh_rate)} cph</div>)
                trs.push(<div key={i + '8'}>{p.event_type === 'hashtag_or_mention' ? <span>{p.ig_phototag_refresh_rate}s : {cph(p.ig_phototag_refresh_rate)} cph</span> : ''}</div>)
            }
            return trs
        }
        return (
            <React.Fragment>
                <div className="box">
                    <div className="layout">
                        <div className="main_title">
                            <h1>
                                {_('projects.list.header')}{/* {(this.state.filter === 'ongoing' && this.state.totalCPH > 0) ? '(current CPH ' + this.state.totalCPH + ')' : ''} */}
                            </h1>
                        </div>
                        <div className="filters">
                            <button type="button" style={{ background: this.state.showActiveProjects ? '#c52c26' : '#999' }} onClick={() => this.setState({ showActiveProjects: !this.state.showActiveProjects })}>Active-projects</button>
                            <div style={{ flexGrow: 1 }}></div>
                            {this.state.showActiveProjects ? null : <div style={{ display: 'flex' }}>
                                <button type='button' onClick={() => this.setState({ filter: 'all' })}><div className="project_status_all_icon_24"></div>{_('common.all')}</button>
                                <button type='button' onClick={() => this.setState({ filter: 'ongoing' })}><div className="project_status_ongoing_icon_24"></div>{_('projects.list.filters.ongoing')}</button>
                                <button type='button' onClick={() => this.setState({ filter: 'upcoming' })}><div className="project_status_upcoming_icon_24"></div>{_('projects.list.filters.upcoming')}</button>
                                <button type='button' onClick={() => this.setState({ filter: 'finished' })}><div className="project_status_finished_icon_24"></div>{_('projects.list.filters.finished')}</button>
                            </div>}
                        </div>
                        {this.state.showActiveProjects ?
                            <div>
                                <span style={{ marginBottom: 30, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <span style={{ fontSize: 20, color: 'hsl(27, 98%, 45%)', display: 'flex', alignItems: 'center' }}>Facebook API is called <span style={{ fontSize: 24, padding: '6px 12px', margin: '0 10px', borderRadius: 8, backgroundColor: 'hsl(26, 62%, 100%)', color: 'hsl(27, 98%, 18%)' }}>{this.state.totalCPH}</span> times per hour</span>
                                    <code style={{ fontSize: 10, marginLeft: 20, padding: '5px 20px', borderRadius: 8, backgroundColor: 'hsl(26, 62%, 100%)' }}><b>cph</b> <i>(Calls Per Hour)</i> = (3600 / Rate) * TagsCount</code>
                                </span>
                                <div className="active-projects-table">
                                    {activeProjects()}
                                </div>
                            </div> :
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="all">{_('common.column.id')}</th>
                                        <th className="all">{_('projects.list.column.name')}</th>
                                        <th className="no_sort all" style={{ zIndex: 1 }}>
                                            <div style={{ display: 'flex' }}>
                                                <div>{_('common.login-as')}</div>
                                                <div style={{ transform: 'translateX(3px)' }}>
                                                    <Explained message={_('explained.projects-login-as')}></Explained>
                                                </div>
                                            </div>
                                        </th>
                                        <th>{_('common.services')}</th>
                                        <th>{_('projects.list.column.sns-connections')}</th>
                                        <th>{_('projects.list.column.dates')}</th>
                                        <th>{_('projects.list.column.status')}</th>
                                        <th>{_('projects.list.column.posts-count')}</th>
                                        <th>{_('projects.list.column.client')}</th>
                                        {/* <th>{_('common.manager')}</th>
                                    <th>{_('projects.list.column.priority')}</th>
                                    <th>{_('projects.list.column.hs_ig_hashtag_rate')}</th>
                                    <th>{_('projects.list.column.hs_ig_phototag_rate')}</th>
                                    <th></th>
                                    <th className="no_sort">{_('common.delete')}</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {projects.map((project, index) => (
                                        <tr key={index}>
                                            <td className="center relative">
                                                {project.id}
                                                <div className="table_hover"></div>
                                            </td>
                                            <td className='capitalize'>
                                                <Link title='Edit' to={`/accounts/${account.username}/clients/${project.username}/projects/${project.uid}`} className='theme'>
                                                    {project.name}
                                                </Link>
                                            </td>
                                            <td className="center relative size_of_icon">
                                                <a target='_blank' title='Sign in' href={`${conf.host_client}/accounts/${project.username}/projects/${project.uid}`} className="icon_action sing relative">
                                                    <div className="login_list_icon icon"></div>
                                                </a>
                                            </td>
                                            <td className="center uppercase">{project.services.join(', ')}</td>
                                            <td className="center">
                                                {project.event_type === 'upload_only' ? <div>-</div> :
                                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 17 }}>
                                                        {project.keys.map(k => `${snsAbbr[k.resolver]}:${k.username}`).join(', ')}
                                                        {(
                                                            projectStatus(project.start_date, project.end_date) === 'ongoing' &&
                                                            this.state.activeProjects.length > 0 &&
                                                            this.state.activeProjects.filter(i => i.id === project.id).length === 0
                                                        ) ?
                                                            <div className="project-warning" onClick={(e) => this.checkIngester(e, project.uid)}></div> :
                                                            null
                                                        }
                                                    </div>}
                                            </td>
                                            <td className="center">{formatDate(project.start_date) + ' ~ ' + formatDate(project.end_date)}</td>
                                            <td className="center relative">{projectStatusIcon(project.start_date, project.end_date)}</td>
                                            <td className="center theme">{project.stats.posts_count - project.stats.deleted_posts_count - project.stats.volatile_posts_count}</td>
                                            <td className="center">{project.username}</td>
                                            {/* <td className="center">{(project.admin_id && all_accounts_by_ids[project.admin_id]) ? all_accounts_by_ids[project.admin_id].username : ''}</td>
                                    <td className="center">{projectPriorities[project.priority]}</td>
                                    <td className="center">{project.ig_hashtag_refresh_rate}</td>
                                    <td className="center">{project.ig_phototag_refresh_rate}</td>
                                    <td className="center">{project.uid}</td>
                                    <td className="center relative">
                                        <a href='#' role='button' className="cursor relative icon_action" onClick={() => this.props.deleteProject(project.uid)}>
                                            <div className="delete_list_icon icon"></div>
                                        </a>
                                    </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </React.Fragment>)
    }
})


// Container
const mapStateToProps = (state) => {
    const role = state.account.topRole || ''
    const accounts = state.admin_accounts.accounts || []
    let projects = accounts.filter(i => !i.deleted).reduce((agg, a) => [...agg, ...a.projects.map(
        p => ({ ...p, username: a.username, account_id: a.id, admin_id: a.admin_id }))], [])
    projects = projects.filter(p => p.status === 'activated')
    return {
        isFetching: state.admin_accounts.isFetching,
        accounts,
        projects: projects.filter(i => !i.deleted),
        account: state.account,
        role,
    }
}

const mapDispatchToProps = (dispatch) => ({
    deleteProject(project_uid) {
        dispatch({
            type: 'MODAL_DELETE_SHOW', callback: (isDeleted) => {
                if (isDeleted) {
                    dispatch(deleteProject(project_uid))
                }
            }
        })
    }
})

const ProjectsList = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectsListComponent)

export default ProjectsList
