import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { Router, Route, browserHistory } from 'react-router'

import configureStore from './configureStore'

import { fetchAccount, fetchAdminAccounts } from './actions/account'

import translations from './helpers/translations'
import cookies from './helpers/cookies'

import Main from './components/Main'

import * as conf from './conf'

import AccountInfo from './components/Account/Info'
import AccountEdit from './components/Account/Edit'
import AccountChangePassword from './components/Account/ChangePassword'

import AdminsList from './components/Admins/List'
import AdminInfo from './components/Admins/Info'
import AdminEdit from './components/Admins/Edit'
import AdminNew from './components/Admins/New'

import ClientsList from './components/Clients/List'
import ClientInfo from './components/Clients/Info'
import ClientEdit from './components/Clients/Edit'
import ClientNew from './components/Clients/New'

import ProjectsList from './components/Projects/List'
import ProjectInfo from './components/Projects/Info'
import ProjectNew from './components/Projects/New'
import ProjectEdit from './components/Projects/Edit'

import ProjectRequestsList from './components/ProjectRequests/List'
import ProjectRequestInfo from './components/ProjectRequests/Info'
import ProjectRequestEdit from './components/ProjectRequests/Edit'

import Kiosks from './components/Kiosks'

function reLogin() {
    window.location.href = conf.host_auth + '/sign-out?next=' + conf.host_admin
}

function initialise() {
    const store = configureStore()
    let auth = cookies.getItem(conf.local_storage_variables.token)
    if (auth) {
        auth = JSON.parse(auth)
        if (auth.token && auth.username) {    
            store.dispatch({type: 'SET_TOKEN', token: auth.token, username: auth.username})
        } else {
            reLogin()
        }
    } else {
        reLogin()
    }
    return {store}
}

const { store } = initialise()

const onLoad = (nextState) => {
    const username = nextState.params.account_username
    return store.dispatch(fetchAccount(username))
    .then(() => {
        return store.dispatch(fetchAdminAccounts(username))})
    .then(() => {
        const account = store.getState().account
        translations.setLanguage(account.language)
        if (!account.error && nextState.location.pathname.split('/').filter(i => i).length < 3) {
            browserHistory.replace(`/accounts/${username}/clients`)
        }
    })
}

const createElement = (Component, allProps) => {
    return <Component {...allProps} />
}

const Root = ({ history }) => {
    return (
    <Provider store={store}>
      <Router history={history} createElement={createElement}>
        <Route path='/sign-out' onEnter={reLogin} />
        
        <Route path='/' onEnter={() => {
            browserHistory.replace(`/accounts/${store.getState().auth.username}/clients`)
        }} />

        <Route path='/accounts/:account_username' component={Main} onEnter={onLoad} >
            <Route path='info' component={AccountInfo} />
            <Route path='edit' component={AccountEdit} />
            <Route path='change-password' component={AccountChangePassword} />

            <Route path='admins' component={AdminsList} />
            <Route path='admins/new' component={AdminNew} />
            <Route path='admins/:admin_username' component={AdminInfo} />
            <Route path='admins/:admin_username/edit' component={AdminEdit} />

            <Route path='clients' component={ClientsList} />
            <Route path='clients/new' component={ClientNew} />
            <Route path='clients/:client_username' component={ClientInfo} />
            <Route path='clients/:client_username/projects/new' component={ProjectNew} />
            <Route path='clients/:client_username/projects/:project_uid' component={ProjectInfo} />
            <Route path='clients/:client_username/projects/:project_uid/edit' component={ProjectEdit} />
            <Route path='clients/:client_username/project-requests/:project_uid' component={ProjectRequestInfo} />
            <Route path='clients/:client_username/project-requests/:project_uid/edit' component={ProjectRequestEdit} />
            <Route path='clients/:client_username/edit' component={ClientEdit} />

            <Route path='projects' component={ProjectsList} />

            <Route path='project-requests' component={ProjectRequestsList} />

            <Route path='kiosks' component={Kiosks} />
        </Route>
      </Router>
    </Provider>
    )
}
Root.propTypes = {
    history: PropTypes.object.isRequired,
}

export default Root
