import * as conf from '../conf'

const translations = () => {
    let translations = {
        // common
        'common.save': {
            en: 'Save',
            kr: '저장',
            jp: '저장',
        },
        'common.edit': {
            en: 'Edit',
            kr: '수정',
            jp: '수정',
        },
        'common.cancel': {
            en: 'Cancel',
            kr: '취소',
            jp: '취소',
        },
        'common.login-as': {
            en: 'Login as',
            kr: '계정 접속',
            jp: '계정 접속',
        },
        'common.delete': {
            en: 'Delete',
            kr: '삭제',
            jp: '삭제',
        },
        'common.select': {
            en: 'Select',
            kr: '',
            jp: '',
        },
        'common.search': {
            en: 'Search',
            kr: '',
            jp: '',
        },
        'common.all': {
            en: 'ALL',
            kr: '모두',
            jp: '모두',
        },
        'common.demo': {
            en: 'DEMO',
            kr: 'DEMO',
            jp: 'DEMO',
        },
        'common.standard': {
            en: 'STAN',
            kr: 'STAN',
            jp: 'STAN',
        },
        'common.vip': {
            en: 'VIP',
            kr: 'VIP',
            jp: 'VIP',
        },
        'common.column.id': {
            en: 'N',
            kr: 'N',
            jp: 'N',
        },
        'common.created-on': {
            en: 'Created on',
            kr: '생성 날짜:',
            jp: '생성 날짜:',
        },
        'common.country': {
            en: 'Country',
            kr: '국가',
            jp: '국가',
        },
        'common.timezone': {
            en: 'Timezone',
            kr: '타임존',
            jp: '타임존',
        },
        'common.phone': {
            en: 'Phone',
            kr: '전화번호',
            jp: '전화번호',
        },
        'common.email': {
            en: 'Email',
            kr: 'Email',
            jp: 'Email',
        },
        'common.language': {
            en: 'Language',
            kr: '언어',
            jp: '언어',
        },
        'common.services': {
            en: 'Services',
            kr: '서비스',
            jp: '서비스',
        },
        'common.admin': {
            en: 'Admin',
            kr: '어드민',
            jp: '어드민',
        },
        'common.manager': {
            en: 'Manager',
            kr: '담당자',
            jp: '담당자',
        },
        'common.active': {
            en: 'Active',
            kr: '활성화',
            jp: '활성화',
        },
        'common.validation-errors.select-at-least-one-service': {
            en: 'Select at least one service',
            kr: '최소 1개 이상의 서비스를 선택해 주세요',
            jp: '최소 1개 이상의 서비스를 선택해 주세요',
        },
        'common.validation-erros.select-at-least-one-social-network': {
            en: 'Select at least one social network',
            kr: '최소 1개 이상의 SNS를 선택해 주세요',
            jp: '최소 1개 이상의 SNS를 선택해 주세요',
        },
        'common.validation-erros.start-date-must-be-before-end-date': {
            en: 'Start date must be before end date',
            kr: '프로젝트 시작날짜는 종료날짜 앞에 있어야 합니다',
            jp: '프로젝트 시작날짜는 종료날짜 앞에 있어야 합니다',
        },
        'common.modal-alert.enter-at-least-one-service': {
            en: 'Please select at least one service',
            kr: 'Please select at least one service',
            jp: 'Please select at least one service',
        },
        'common.modal-alert.select-social-platform': {
            en: 'Please select Social platform',
            kr: 'Please select Social platform',
            jp: 'Please select Social platform',
        },
        'common.modal-alert.enter-at-least-one-hashtag': {
            en: 'Please enter at least one hashtag',
            kr: '최소 1개의 해시태그를 입력해 주세요',
            jp: '최소 1개의 해시태그를 입력해 주세요',
        },
        'common.daterange.today': {
            en: 'Today',
            kr: '오늘',
            jp: '오늘',
        },
        'common.daterange.tomorrow': {
            en: 'Tomorrow',
            kr: '내일',
            jp: '내일',
        },
        'common.daterange.next-2-days': {
            en: 'Following 2 Days',
            kr: '2일 후까지',
            jp: '2일 후까지',
        },
        'common.daterange.next-3-days': {
            en: 'Following 3 Days',
            kr: '3일 후까지',
            jp: '3일 후까지',
        },
        'common.daterange.next-4-days': {
            en: 'Following 4 Days',
            kr: '4일 후까지',
            jp: '4일 후까지',
        },
        'common.daterange.next-5-days': {
            en: 'Following 5 Days',
            kr: '5일 후까지',
            jp: '5일 후까지',
        },
        'common.daterange.next-7-days': {
            en: 'Following 7 Days',
            kr: '7일 후까지',
            jp: '7일 후까지',
        },
        'common.daterange.next-30-days': {
            en: 'Following 30 Days',
            kr: '한달 후까지',
            jp: '한달 후까지',
        },
        'common.project.max-printers': {
            en: '#of Printer',
            kr: '프린터 개수',
            jp: '프린터 개수',
        },
        'common.project.max-printers.1': {
            en: '1',
            kr: '1',
            jp: '1',
        },
        'common.project.max-printers.2': {
            en: '2',
            kr: '2',
            jp: '2',
        },
        'common.project.max-printers.3': {
            en: '3',
            kr: '3',
            jp: '3',
        },
        'common.project.max-printers.4': {
            en: '4',
            kr: '4',
            jp: '4',
        },
        'common.project.max-printers.5': {
            en: '5',
            kr: '5',
            jp: '5',
        },
        'common.project.main-settings': {
            en: 'Social settings',
            kr: 'SNS 세팅',
            jp: 'SNS 세팅',
        },
        'common.project.main-settings.max-tags': {
            en: '#of Hashtags',
            kr: '해시태그 개수',
            jp: '해시태그 개수',
        },
        'common.project.main-settings.ig-hashtag-refresh-rate': {
            en: 'IG hashtag refresh rate (seconds)',
            kr: 'IG hashtag refresh rate (seconds)',
            jp: 'IG hashtag refresh rate (seconds)',
        },
        'common.project.main-settings.ig-phototag-refresh-rate': {
            en: 'IG phototag refresh rate (seconds)',
            kr: 'IG phototag refresh rate (seconds)',
            jp: 'IG phototag refresh rate (seconds)',
        },
        'common.project.main-settings.ig-story-refresh-rate': {
            en: 'IG story refresh rate (seconds)',
            kr: 'IG story refresh rate (seconds)',
            jp: 'IG story refresh rate (seconds)',
        },
        'common.project.main-settings.tw-hashtag-refresh-rate': {
            en: 'TW hashtag refresh rate (seconds)',
            kr: 'TW hashtag refresh rate (seconds)',
            jp: 'TW hashtag refresh rate (seconds)',
        },
        'common.project.main-settings.tw-mention-refresh-rate': {
            en: 'TW mention refresh rate (seconds)',
            kr: 'TW mention refresh rate (seconds)',
            jp: 'TW mention refresh rate (seconds)',
        },
        'common.project.main-settings.vk-hashtag-refresh-rate': {
            en: 'VK hashtag refresh rate (seconds)',
            kr: 'VK hashtag refresh rate (seconds)',
            jp: 'VK hashtag refresh rate (seconds)',
        },
        'common.project.main-settings.max-posts': {
            en: '#of Post',
            kr: '포스트 개수',
            jp: '포스트 개수',
        },
        'common.project.main-settings.max-views': {
            en: '#of Views',
            kr: '뷰 개수',
            jp: '뷰 개수',
        },
        'common.project.tags': {
            en: 'Hashtags (without #)',
            kr: '해시태그 (#없이 입력)',
            jp: '해시태그 (#없이 입력)',
        },
        'common.project.tags-or': {
            en: 'OR-condition',
            kr: 'OR 조건',
            jp: 'OR 조건',
        },
        'common.project.tags-and': {
            en: 'AND-condition',
            kr: 'AND 조건',
            jp: 'AND 조건',
        },
        'common.project.condition': {
            en: 'Condition',
            kr: '조건',
            jp: '조건',
        },
        'common.project.optional-services': {
            en: 'Optional Service',
            kr: '부가 서비스',
            jp: '부가 서비스',
        },
        'common.project.crm-link': {
            en: 'CRM Link',
            kr: 'CRM Link',
            jp: 'CRM Link',
        },
        'common.project.extra': {
            en: 'Additional Info',
            kr: '부가 정보',
            jp: '부가 정보',
        },
        'common.project.parse-usernames': {
            en: 'Username fetching',
            kr: '유저네임 수집',
            jp: '유저네임 수집',
        },
        'common.project.parse-location': {
            en: 'Parse location',
            kr: 'Parse location',
            jp: 'Parse location',
        },
        'common.project.parse-sentiment': {
            en: 'Parse sentiment',
            kr: 'Parse sentiment',
            jp: 'Parse sentiment',
        },
        'common.form.required-input': {
            en: 'Required Input',
            kr: '필수입력사항',
            jp: '필수입력사항',
        },
        // Main
        'main.loading': {
            en: 'loading',
            kr: 'loading',
            jp: 'loading',
        },
        'main.hashsnap': {
            en: 'Hashsnap',
            kr: 'Hashsnap',
            jp: 'Hashsnap',
        },
        // Sidebar
        'sidebar.admins': {
            en: 'Admins',
            kr: '어드민 관리',
            jp: '어드민 관리',
        },
        'sidebar.clients': {
            en: 'Clients',
            kr: '클라이언트 관리',
            jp: '클라이언트 관리',
        },
        'sidebar.projects': {
            en: 'Projects',
            kr: '프로젝트 관리',
            jp: '프로젝트 관리',
        },
        'sidebar.requests': {
            en: 'Project Requests',
            kr: '요청프로젝트 관리',
            jp: '요청프로젝트 관리',
        },
        'sidebar.kiosks': {
            en: 'Kiosks',
            kr: '키오스크',
            jp: '키오스크',
        },
        // Kiosks
        'kiosks.header': {
            en: 'Kiosks Management',
            kr: '',
            jp: ''
        },
        'kiosks.add': {
            en: 'Add Kiosk',
            kr: '키오스크 추가',
            jp: '키오스크 추가',
        },
        'kiosks.connect': {
            en: 'Connect',
            kr: '',
            jp: '',
        },
        'kiosks.disconnect': {
            en: 'Disconnect',
            kr: '',
            jp: '',
        },
        'kiosks.allow': {
            en: 'Allow',
            kr: '',
            jp: '',
        },
        'kiosks.disallow': {
            en: 'Disallow',
            kr: '',
            jp: '',
        },
        'kiosks.kiosk-name': {
            en: 'Kiosk Name',
            kr: '키오스크 이름',
            jp: '키오스크 이름',
        },
        'kiosks.kiosk-windows-cd-key': {
            en: 'Windows CD Key',
            kr: '',
            jp: '',
        },
        'kiosks.kiosk-printer-model': {
            en: 'Printer Model',
            kr: '',
            jp: '',
        },
        'kiosks.kiosk-printer-serial-number': {
            en: 'Printer Serial Number',
            kr: '',
            jp: '',
        },
        'kiosks.kiosk-location': {
            en: 'Location',
            kr: '',
            jp: '',
        },
        'kiosks.kiosk-rent-or-sold': {
            en: 'Rent or sold?',
            kr: '',
            jp: '',
        },
        'kiosks.kiosk-rent': {
            en: 'Rent',
            kr: '',
            jp: '',
        },
        'kiosks.kiosk-sold': {
            en: 'Sold',
            kr: '',
            jp: '',
        },
        'kiosks.kiosk-client': {
            en: 'Client',
            kr: '',
            jp: '',
        },
        'kiosks.kiosk-comment': {
            en: 'Comment',
            kr: '',
            jp: '',
        },
        'kiosks.view': {
            en: 'Select View',
            kr: '',
            jp: '',
        },
        // Admins List
        'admins.list.header': {
            en: 'Admins List',
            kr: '어드민 관리',
            jp: '어드민 관리',
        },
        'admins.list.add-new-admin': {
            en: '+ Add New Admin',
            kr: '+ 어드민 추가',
            jp: '+ 어드민 추가',
        },
        'admins.list.column.name': {
            en: 'Name',
            kr: '이름',
            jp: '이름',
        },
        'admins.list.column.username': {
            en: 'ID',
            kr: 'ID',
            jp: 'ID',
        },
        'admins.list.column.type': {
            en: 'Type',
            kr: '타입',
            jp: '타입',
        },
        'admins.list.column.clients-count': {
            en: 'Clients Count',
            kr: '관리 클라이언트',
            jp: '관리 클라이언트',
        },
        'admins.list.column.clients-accounts': {
            en: 'Client Accounts',
            kr: '클라이언트 계정',
            jp: '클라이언트 계정',
        },
        'admins.list.type.sales': {
            en: 'Sales',
            kr: '세일즈',
            jp: '세일즈',
        },
        'admins.list.type.super': {
            en: 'Super',
            kr: '총관리자',
            jp: '총관리자',
        },
        // Admin Info
        'admins.info.header': {
            en: 'Admin Info',
            kr: '어드민 정보',
            jp: '어드민 정보',
        },
        'admins.info.clients-list': {
            en: 'Clients List',
            kr: '클라이언트 리스트',
            jp: '클라이언트 리스트',
        },
        'admins.info.clients-list.name': {
            en: 'Name',
            kr: '이름',
            jp: '이름',
        },
        'admins.info.clients-list.username': {
            en: 'ID',
            kr: 'ID',
            jp: 'ID',
        },
        'admins.info.clients-list.type': {
            en: 'Level',
            kr: '등급',
            jp: '등급',
        },
        'admins.info.clients-list.joined': {
            en: 'Joined',
            kr: '생성일자',
            jp: '생성일자',
        },
        // Admin New
        'admins.new.header': {
            en: 'Add New Admin',
            kr: '어드민 생성',
            jp: '어드민 생성',
        },
        'admins.new.input.name': {
            en: 'Admin Name',
            kr: '관리자 이름',
            jp: '관리자 이름',
        },
        'admins.new.input.username': {
            en: 'Input ID',
            kr: 'ID 입력',
            jp: 'ID 입력',
        },
        'admins.new.input.password': {
            en: 'Input Password',
            kr: '비밀번호 입력',
            jp: '비밀번호 입력',
        },
        // Admin Edit
        'admins.edit.header': {
            en: 'Edit Admin',
            kr: '어드민 수정',
            jp: '어드민 수정',
        },
        // Clients List
        'clients.list.header': {
            en: 'Clients List',
            kr: '클라이언트 관리',
            jp: '클라이언트 관리',
        },
        'clients.list.add-new': {
            en: '+ Add New Client',
            kr: '+ 클라이언트 추가',
            jp: '+ 클라이언트 추가',
        },
        'clients.list.column.name': {
            en: 'Name',
            kr: '이름',
            jp: '이름',
        },
        'clients.list.column.username': {
            en: 'ID',
            kr: 'ID',
            jp: 'ID',
        },
        'clients.list.column.type': {
            en: 'Level',
            kr: '등급',
            jp: '등급',
        },
        'clients.list.column.joined': {
            en: 'Joined',
            kr: '생성일자',
            jp: '생성일자',
        },
        'clients.list.column.add-project': {
            en: 'Add Project',
            kr: '프로젝트 추가',
            jp: '프로젝트 추가',
        },
        // Client Info
        'clients.info.header': {
            en: 'Client Info',
            kr: '클라이언트 정보',
            jp: '클라이언트 정보',
        },
        'clients.info.extra': {
            en: 'Comment by Manager',
            kr: '담당자 코멘트',
            jp: '담당자 코멘트',
        },
        'clients.info.projects-list': {
            en: 'Project List',
            kr: '프로젝트 리스트',
            jp: '프로젝트 리스트',
        },
        'clients.info.projects-list.new': {
            en: '+ Create New Project',
            kr: '+ 새로운 프로젝트 생성',
            jp: '+ 새로운 프로젝트 생성',
        },
        'clients.info.projects-list.name': {
            en: 'Name',
            kr: '프로젝트명',
            jp: '프로젝트명',
        },
        'clients.info.projects-list.package': {
            en: 'Package',
            kr: '패키지',
            jp: '패키지',
        },
        'clients.info.projects-list.printer': {
            en: 'Printer',
            kr: '프린터 수',
            jp: '프린터 수',
        },
        'clients.info.projects-list.period': {
            en: 'Period',
            kr: '기간',
            jp: '기간',
        },
        'clients.info.projects-list.created': {
            en: 'Created',
            kr: '생성일자',
            jp: '생성일자',
        },
        'clients.info.projects-list.status': {
            en: 'Status',
            kr: '진행상황',
            jp: '진행상황',
        },
        'clients.info.projects-list.filtering': {
            en: 'Filtering',
            kr: '필터링',
            jp: '필터링',
        },
        'clients.info.projects-list.days': {
            en: 'days',
            kr: '일',
            jp: '일',
        },
        // Client New
        'clients.new.header': {
            en: 'Add New Client',
            kr: '클라이언트 추가',
            jp: '클라이언트 추가',
        },
        'clients.new.input.company': {
            en: 'Company Name',
            kr: '클라이언트 명',
            jp: '클라이언트 명',
        },
        'clients.new.input.username': {
            en: 'Input ID',
            kr: 'ID 입력',
            jp: 'ID 입력',
        },
        'clients.new.input.password': {
            en: 'Input Password',
            kr: '비밀번호 입력',
            jp: '비밀번호 입력',
        },
        'clients.new.input.name': {
            en: 'Client Name',
            kr: '담당자명',
            jp: '담당자명',
        },
        'clients.new.input.extra': {
            en: 'Comment by Manager',
            kr: '관리자 코멘트',
            jp: '관리자 코멘트',
        },
        // Client Edit
        'clients.edit.header': {
            en: 'Edit Client',
            kr: '클라이언트 수정',
            jp: '클라이언트 수정',
        },
        'clients.edit.input.company': {
            en: 'Company Name',
            kr: '클라이언트 명',
            jp: '클라이언트 명',
        },
        'clients.edit.input.name': {
            en: 'Client Name',
            kr: '담당자명',
            jp: '담당자명',
        },
        'clients.edit.input.extra': {
            en: 'Comment by Manager',
            kr: '관리자 코멘트',
            jp: '관리자 코멘트',
        },
        // Projects List
        'projects.list.header': {
            en: 'Projects List',
            kr: '프로젝트 리스트',
            jp: '프로젝트 리스트',
        },
        'projects.list.filters.ongoing': {
            en: 'On-going',
            kr: '진행중',
            jp: '진행중',
        },
        'projects.list.filters.upcoming': {
            en: 'Upcoming',
            kr: '진행예정',
            jp: '진행예정',
        },
        'projects.list.filters.finished': {
            en: 'Finished',
            kr: '종료',
            jp: '종료',
        },
        'projects.list.column.name': {
            en: 'Project',
            kr: '프로젝트',
            jp: '프로젝트',
        },
        'projects.list.column.priority': {
            en: 'Priority',
            kr: 'Priority',
            jp: 'Priority',
        },
        'projects.list.column.hs_ig_hashtag_rate': {
            en: 'IG hashtag rate',
            kr: 'IG hashtag rate',
            jp: 'IG hashtag rate',
        },
        'projects.list.column.hs_ig_phototag_rate': {
            en: 'IG phototag rate',
            kr: 'IG phototag rate',
            jp: 'IG phototag rate',
        },
        'projects.list.column.package': {
            en: 'Package',
            kr: '패키지',
            jp: '패키지',
        },
        'projects.list.column.dates': {
            en: 'Project Dates',
            kr: '프로젝트 날짜',
            jp: '프로젝트 날짜',
        },
        'projects.list.column.client': {
            en: 'Client',
            kr: '클라이언트',
            jp: '클라이언트',
        },
        'projects.list.column.sns-connections': {
            en: 'SNS conn',
            kr: 'SNS계정 연결',
            jp: 'SNS계정 연결',
        },
        'projects.list.column.posts-count': {
            en: 'Posts',
            kr: '포스트',
            jp: '포스트',
        },
        'projects.list.column.status': {
            en: 'Project status',
            kr: '프로젝트 상태',
            jp: '프로젝트 상태',
        },
        // Project Info
        'projects.info.types.demo': {
            en: 'Demo',
            kr: 'Demo',
            jp: 'Demo',
        },
        'projects.info.types.standard': {
            en: 'Standard',
            kr: 'Standard',
            jp: 'Standard',
        },
        'projects.info.priorities.high': {
            en: 'High',
            kr: 'High',
            jp: 'High',
        },
        'projects.info.priorities.medium': {
            en: 'Medium',
            kr: 'Medium',
            jp: 'Medium',
        },
        'projects.info.priorities.low': {
            en: 'Low',
            kr: 'Low',
            jp: 'Low',
        },
        'projects.info.header': {
            en: 'Project Info',
            kr: '프로젝트 정보',
            jp: '프로젝트 정보',
        },
        'projects.info.name': {
            en: 'Project Name',
            kr: '프로젝트명',
            jp: '프로젝트명',
        },
        'projects.info.dates': {
            en: 'Project Dates',
            kr: '프로젝트 날짜',
            jp: '프로젝트 날짜',
        },
        'projects.info.event-type': {
            en: 'Event Type',
            kr: 'Event Type',
            jp: 'Event Type',
        },
        'projects.info.social-networks': {
            en: 'Social Platforms',
            kr: 'SNS',
            jp: 'SNS',
        },
        'projects.info.status': {
            en: 'Project Status',
            kr: '프로젝트 상태',
            jp: '프로젝트 상태',
        },
        'projects.info.status.activated': {
            en: 'Activated',
            kr: '진행중',
            jp: '진행중',
        },
        'projects.info.status.request': {
            en: 'The project will be proceeded',
            kr: '진행예정',
            jp: '진행예정',
        },
        'projects.info.type': {
            en: 'Project Type',
            kr: '프로젝트 타입',
            jp: '프로젝트 타입',
        },
        'projects.info.priority': {
            en: 'Priority',
            kr: 'Priority',
            jp: 'Priority',
        },
        'projects.info.backup': {
            en: 'Backup',
            kr: 'Backup',
            jp: 'Backup',
        },
        'projects.info.go-to-list': {
            en: 'Go to List',
            kr: '리스트로 이동',
            jp: '리스트로 이동',
        },
        'projects.info.edit': {
            en: 'Edit',
            kr: '수정',
            jp: '수정',
        },
        'projects.info.delete': {
            en: 'Delete',
            kr: '삭제',
            jp: '삭제',
        },
        // Project Edit
        'projects.edit.header': {
            en: 'Edit Project Info',
            kr: '프로젝트 정보 수정',
            jp: '프로젝트 정보 수정',
        },
        'projects.edit.input.name': {
            en: 'Project Name',
            kr: '프로젝트명',
            jp: '프로젝트명',
        },
        'projects.edit.input.dates': {
            en: 'Project Dates',
            kr: '프로젝트 날짜',
            jp: '프로젝트 날짜',
        },
        'projects.edit.input.services': {
            en: 'Services',
            kr: '서비스',
            jp: '서비스',
        },
        'projects.edit.input.social-networks': {
            en: 'Social Platforms',
            kr: 'SNS',
            jp: 'SNS',
        },
        'projects.edit.status': {
            en: 'Project Status',
            kr: '프로젝트 상태',
            jp: '프로젝트 상태',
        },
        'projects.edit.priority': {
            en: 'Project Priority',
            kr: 'Project Priority',
            jp: 'Project Priority',
        },
        'projects.edit.status.activated': {
            en: 'Activated',
            kr: '진행중',
            jp: '진행중',
        },
        // Project New
        'projects.new.header': {
            en: 'Create new project',
            kr: '새로운 프로젝트 생성',
            jp: '새로운 프로젝트 생성',
        },
        'projects.new.input.name': {
            en: 'Project Name',
            kr: '프로젝트명',
            jp: '프로젝트명',
        },
        'projects.new.input.dates': {
            en: 'Project Dates',
            kr: '프로젝트 날짜',
            jp: '프로젝트 날짜',
        },
        'projects.new.input.services': {
            en: 'Services',
            kr: '서비스',
            jp: '서비스',
        },
        'projects.new.input.social-networks': {
            en: 'Social Platforms',
            kr: 'SNS',
            jp: 'SNS',
        },
        'projects.new.create': {
            en: 'Create',
            kr: '생성',
            jp: '생성',
        },
        // Requests List
        'requests.list.header': {
            en: 'Requests List',
            kr: '요청프로젝트 리스트',
            jp: '요청프로젝트 리스트',
        },
        'requests.list.column.name': {
            en: 'Project Request',
            kr: '요청 프로젝트',
            jp: '요청 프로젝트',
        },
        'requests.list.column.client': {
            en: 'Client',
            kr: '클라이언트',
            jp: '클라이언트',
        },
        'requests.list.column.date': {
            en: 'Request Date',
            kr: '요청 날짜',
            jp: '요청 날짜',
        },
        // Request Info
        'requests.info.type.demo': {
            en: 'Demo',
            kr: 'Demo',
            jp: 'Demo',
        },
        'requests.info.type.standard': {
            en: 'Standard',
            kr: 'Standard',
            jp: 'Standard',
        },
        'requests.info.header': {
            en: 'Project Request Info',
            kr: '요청프로젝트 정보',
            jp: '요청프로젝트 정보',
        },
        'requests.info.name': {
            en: 'Project Name',
            kr: '프로젝트명',
            jp: '프로젝트명',
        },
        'requests.info.dates': {
            en: 'Project Dates',
            kr: '프로젝트 날짜',
            jp: '프로젝트 날짜',
        },
        'requests.info.event-type': {
            en: 'Event Type',
            kr: 'Event Type',
            jp: 'Event Type',
        },
        'requests.info.social-networks': {
            en: 'Social Platforms',
            kr: 'SNS',
            jp: 'SNS',
        },
        'requests.info.request-message': {
            en: 'Request Message',
            kr: '요청 메시지',
            jp: '요청 메시지',
        },
        'requests.info.status': {
            en: 'Project Status',
            kr: '프로젝트 상태',
            jp: '프로젝트 상태',
        },
        'requests.info.status.activated': {
            en: 'Activated',
            kr: '진행중',
            jp: '진행중',
        },
        'requests.info.status.request': {
            en: 'The project will be proceeded',
            kr: '진행예정',
            jp: '진행예정',
        },
        'requests.info.type': {
            en: 'Project Type',
            kr: '프로젝트 타입',
            jp: '프로젝트 타입',
        },
        'requests.info.go-to-list': {
            en: 'Go to List',
            kr: '리스트로 이동',
            jp: '리스트로 이동',
        },
        'requests.info.activate': {
            en: 'Open this Project',
            kr: '요청 승인',
            jp: '요청 승인',
        },
        // Request Edit
        'requests.edit.header': {
            en: 'Edit Project Request',
            kr: '요청프로젝트 수정',
            jp: '요청프로젝트 수정',
        },
        'requests.edit.input.name': {
            en: 'Project Name',
            kr: '프로젝트명',
            jp: '프로젝트명',
        },
        'requests.edit.input.dates': {
            en: 'Project Dates',
            kr: '프로젝트 날짜',
            jp: '프로젝트 날짜',
        },
        'requests.edit.input.social-networks': {
            en: 'Social Platforms',
            kr: 'SNS',
            jp: 'SNS',
        },
        'requests.edit.input.request-message': {
            en: 'Request Message',
            kr: '요청메시지',
            jp: '요청메시지',
        },
        // Account Info
        'account.info.header': {
            en: 'Account Info',
            kr: '계정 정보',
            jp: '계정 정보',
        },
        'account.info.change-password': {
            en: 'Change Password',
            kr: '비밀번호 변경',
            jp: '비밀번호 변경',
        },
        // Account Edit
        'account.edit.header': {
            en: 'Account Edit',
            kr: '계정 수정',
            jp: '계정 수정',
        },
        // Account Change Password
        'account.change-password.modal-alert.old-password-missing': {
            en: 'Old password missing',
            kr: '기존 비밀번호를 입력해 주세요',
            jp: '기존 비밀번호를 입력해 주세요',
        },
        'account.change-password.modal-alert.new-password-missing': {
            en: 'New password missing',
            kr: '새로운 비밀번호를 입력해 주세요',
            jp: '새로운 비밀번호를 입력해 주세요',
        },
        'account.change-password.modal-alert.password-repeat-no-match': {
            en: 'Passwords repeat does not match',
            kr: '새로운 비밀번호가 일치하지 않습니다',
            jp: '새로운 비밀번호가 일치하지 않습니다',
        },
        'account.change-password.modal-alert.password-saved': {
            en: 'Password successfully changed!',
            kr: '비밀번호가 변경되었습니다',
            jp: '비밀번호가 변경되었습니다',
        },
        'account.change-password.header': {
            en: 'Change Account Password',
            kr: '비밀번호 변경',
            jp: '비밀번호 변경',
        },
        'account.change-password.input.old-password': {
            en: 'Old Password',
            kr: '기존 비밀번호',
            jp: '기존 비밀번호',
        },
        'account.change-password.input.new-password': {
            en: 'New Password',
            kr: '새로운 비밀번호',
            jp: '새로운 비밀번호',
        },
        'account.change-password.input.new-password-repeat': {
            en: 'Repeat New Password',
            kr: '비밀번호 재입력',
            jp: '비밀번호 재입력',
        },
        // Inputs
        'inputs.render-field.checking': {
            en: 'CHECKING',
            kr: 'CHECKING',
            jp: 'CHECKING',
        },
        'inputs.abilities.advanced-moderation': {
            en: 'Advanced Moderation',
            kr: '고급 포스트 관리',
            jp: '고급 포스트 관리',
        },
        'inputs.abilities.advanced-moderation.description': {
            en: 'Pre-Approved Users, Blacklisted Users, Blacklisted Words',
            kr: '화이트리스트 유저, 블랙리스트 유저, 블랙리스트 단어',
            jp: '화이트리스트 유저, 블랙리스트 유저, 블랙리스트 단어',
        },
        'inputs.abilities.css': {
            en: 'CSS',
            kr: 'CSS',
            jp: 'CSS',
        },
        'inputs.abilities.direct-upload': {
            en: 'Direct Upload',
            kr: 'PC 업로드',
            jp: 'PC 업로드',
        },
        'inputs.abilities.active-screens': {
            en: 'Active Screens',
            kr: '액티브 스크린',
            jp: '액티브 스크린',
        },
        'inputs.abilities.active-screens.description': {
            en: 'List screens that are currently running, and refresh them remotely',
            kr: '리스트에는 현재 활성화된 스크린을 표시하며, 원격으로 새로고침할 수 있습니다',
            jp: '리스트에는 현재 활성화된 스크린을 표시하며, 원격으로 새로고침할 수 있습니다',
        },
        'inputs.abilities.mosaic': {
            en: 'Mosaic',
            kr: '모자이크',
            jp: '모자이크',
        },
        'inputs.abilities.reports': {
            en: 'Reports',
            kr: '리포트',
            jp: '리포트',
        },
        'inputs.abilities.voting': {
            en: 'Voting',
            kr: '투표',
            jp: '투표',
        },
        'inputs.abilities.voting.description': {
            en: 'Can users vote posts on Grid and Web views?',
            kr: 'Can users vote posts on Grid and Web views?',
            jp: 'Can users vote posts on Grid and Web views?',
        },
        'inputs.abilities.shopping': {
            en: 'Shopping',
            kr: 'Shopping',
            jp: 'Shopping',
        },
        'inputs.abilities.shopping.description': {
            en: 'Can users assign product links to posts?',
            kr: 'Can users assign product links to posts?',
            jp: 'Can users assign product links to posts?',
        },
        'inputs.abilities.lottery': {
            en: 'Lottery (for printer)',
            kr: '당첨 (프린터용)',
            jp: '당첨 (프린터용)',
        },
        'inputs.abilities.language-pack': {
            en: 'Language Pack (for printer)',
            kr: '다국어팩 (프린터용)',
            jp: '다국어팩 (프린터용)',
        },
        'inputs.event-type.hashtag': {
            en: '#Hashtag event',
            kr: '#Hashtag event',
            jp: '#Hashtag event',
        },
        'inputs.event-type.mention': {
            en: '@Mention event',
            kr: '@Mention event',
            jp: '@Mention event',
        },
        'inputs.event-type.hashtag-or-mention': {
            en: '# OR @ event',
            kr: '# OR @ event',
            jp: '# OR @ event',
        },
        'inputs.event-type.hashtag-and-mention': {
            en: '# AND @ event',
            kr: '# AND @ event',
            jp: '# AND @ event',
        },
        'inputs.event-type.my-page': {
            en: 'Show my posts',
            kr: 'Show my posts',
            jp: 'Show my posts',
        },
        'inputs.event-type.upload-only': {
            en: 'Upload Only',
            kr: 'Upload Only',
            jp: 'Upload Only',
        },
        'inputs.services.screen': {
            en: 'Screen',
            kr: '스크린',
            jp: '스크린',
        },
        'inputs.services.web': {
            en: 'Web',
            kr: '웹',
            jp: '웹',
        },
        'inputs.services.printer': {
            en: 'Printer',
            kr: '프린터',
            jp: '프린터',
        },
        'inputs.services.broadcast': {
            en: 'Broadcast',
            kr: '브로드캐스트',
            jp: '브로드캐스트',
        },
        'inputs.social-networks.instagram': {
            en: 'Instagram',
            kr: 'Instagram',
            jp: 'Instagram',
        },
        'inputs.social-networks.twitter': {
            en: 'Twitter',
            kr: 'Twitter',
            jp: 'Twitter',
        },
        'inputs.social-networks.facebook': {
            en: 'Facebook',
            kr: 'Facebook',
            jp: 'Facebook',
        },
        'inputs.social-networks.vkontakte': {
            en: 'Vkontakte',
            kr: 'Vkontakte',
            jp: 'Vkontakte',
        },
        'inputs.social-networks.tiktok': {
            en: 'TikTok',
            kr: 'TikTok',
            jp: 'TikTok',
        },
        'explained.project-dates': {
            en: '<ul style="list-style-type: circle; margin-left: 10px"><li>Post crawling begins when a Facebook account is connected (the start date is for information only).</li><li style="margin-top: 5px">Post crawling stops after <b><i>one week</i></b> of the project end date.</li></ul>',
            kr: '<ul style="list-style-type: circle; margin-left: 10px"><li>프로젝트 시작 일자와 상관없이, 페이스북 계정 연결되면 즉시 포스트 수집 시작합니다.</li><li style="margin-top: 5px">포스트 수집 종료 시점은 프로젝트 종료 일자 1주일 뒤입니다. (프로젝트 종료 이후에도 1주일 유예기간 동안 포스트는 계속 수집됩니다.)</li></ul>',
            jp: '<ul style="list-style-type: circle; margin-left: 10px"><li>Post crawling begins when a Facebook account is connected (the start date is for information only).</li><li style="margin-top: 5px">Post crawling stops after <b><i>one week</i></b> of the project end date.</li></ul>',
        },
        'explained.projects-login-as': {
            en: '<div style="text-align:left;padding-left:10px"><div>When you click \'Login As\',</div><ul style="list-style-type:decimal"><li>Project settings<ul style="list-style-type:circle;padding-left:10px"><li>Social connection</li><li>project view creation</li></ul></li><li>Post Management</li><li>Project Report</li><li>QR upload can be generated.</li></ul></div>',
            kr: '<div style="text-align:left;padding-left:10px"><div>계정 접속을 클릭하면,</div><ul style="list-style-type:decimal"><li>프로젝트 세팅<ul style="list-style-type:circle;padding-left:10px"><li>SNS연결</li><li>프로젝트 뷰 생성</li></ul></li><li>포스트 관리</li><li>프로젝트 리포트</li><li>QR업로드 생성이 가능합니다.</li></ul></div>',
            jp: '<div style="text-align:left;padding-left:10px"><div>계정 접속을 클릭하면,</div><ul style="list-style-type:decimal"><li>프로젝트 세팅<ul style="list-style-type:circle;padding-left:10px"><li>SNS연결</li><li>프로젝트 뷰 생성</li></ul></li><li>포스트 관리</li><li>프로젝트 리포트</li><li>QR업로드 생성이 가능합니다.</li></ul></div>',
        },
        'explained.and-condition': {
            en: '<ul style="list-style-type: circle; margin-left: 10px"> <li>AND condition setting</li> <li style="margin-top: 5px">\'Unpopular hashtag\' must be set first.</li> <li style="margin-top: 5px">1. When FB collects posts, it checks the first hashtag first.</li> <li style="margin-top: 5px">2. posts are collected after verifying that all the hashtags you set are tagged.</li> <li style="margin-top: 5px">※ If you set up a popular hashtag for the first, there are so many new posts that you may not be able to collect them because it takes a lot of time to check.</li> </ul>',
            kr: '<ul style="list-style-type: circle; margin-left: 10px"> <li>AND조건 설정</li> <li style="margin-top: 5px">‘비인기 해시태그’를 첫번째로 설정해야 합니다.</li> <li style="margin-top: 5px">1. FB는 포스트를 수집할 때, 첫번째 해시태그를 우선 확인합니다.</li> <li style="margin-top: 5px">2. 확인된 포스트에서 설정한 해시태그가 모두 태그된 것을 확인 후 포스트를 수집합니다. </li> <li style="margin-top: 5px">※인기 많은 해시태그를 첫번째에 설정할 경우, 새로운 포스트가 너무 많아 확인하는데, 시간 소요가 커 포스트를 못불러올 수 있습니다.</li> </ul>',
            jp: '<ul style="list-style-type: circle; margin-left: 10px"> <li>AND조건 설정</li> <li style="margin-top: 5px">‘비인기 해시태그’를 첫번째로 설정해야 합니다.</li> <li style="margin-top: 5px">1. FB는 포스트를 수집할 때, 첫번째 해시태그를 우선 확인합니다.</li> <li style="margin-top: 5px">2. 확인된 포스트에서 설정한 해시태그가 모두 태그된 것을 확인 후 포스트를 수집합니다. </li> <li style="margin-top: 5px">※인기 많은 해시태그를 첫번째에 설정할 경우, 새로운 포스트가 너무 많아 확인하는데, 시간 소요가 커 포스트를 못불러올 수 있습니다.</li> </ul>',
        }
    }
    const languages = ['en', 'kr', 'jp']
    let language = localStorage.getItem(conf.local_storage_variables.language) || 'en'
    if (languages.indexOf(language) === -1) {
        language = 'en'
    }
    return {
        setLanguage(l) {
            localStorage.setItem(conf.local_storage_variables.language, l)
            language = l
        },
        getLanguages() {
            return languages
        },
        translate(s) {
            if (!s) {
                return s
            }
            const l = s.toString().toLowerCase()
            const o = translations[l]
            let response = s
            if (o !== undefined) {
                response = o[language]
            }
            if (response === null || response === undefined || response === '') {
                return s
            }
            return response
        }
    }
}

const tr = translations()

export default tr
export let _ = tr.translate
export let getLanguages = tr.getLanguages
