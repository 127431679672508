import React from 'react'
import { connect } from 'react-redux'

import { _ } from '../../helpers/translations'


let AlertModalComponent = ({ model, hide }) => (
    <div id="alertModal" className={'___alert modal ___alert_' + model.level}>
        <div className="block">
            <div className="text">{model.message}</div>
            <div>
                <button className="ok_modal" type="submit" onClick={() => hide(model)}>
                    <div className="circle">!</div>
                    <div>{_('Okay')}</div>
                </button>
            </div>
        </div>
    </div>)

let mapStateToProps = (state) => ({
    model: state.modalAlert
})

let mapDispatchToProps = (dispatch) => ({
    hide(model) {
        if (model.callback) {
            model.callback()
        }
        dispatch({type: 'MODAL_ALERT_HIDE'})
    }
})

AlertModalComponent = connect(
    mapStateToProps,
    mapDispatchToProps
  )(AlertModalComponent)

export default AlertModalComponent