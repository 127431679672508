import { _ } from './translations'

export const requiredError = (value) => {
    if (!value) {
        return _('Required')
    }
}

export const phoneError = (phone, required=true) => {
    if (!phone && required) {
        return _('Required')
    } else if (/[^+0-9 ]/i.test(phone)) {
        return _('Phone allows +, spaces and numbers only')
    }
}

export const usernameError = (username, required=true) => {
    if (!username && required) {
        return _('Required')
    } else if (username && username.length > 0 && username.length < 6) {
        return _('Username must be at least 6 characters long')
    } else if (/[^a-z0-9_]/i.test(username)) {
        return _('Username allows alphanumeric characters and underscore only')
    }
}

export const emailError = (email, required=true) => {
    if (!email && required) {
        return _('Required')
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        return _('Invalid email address')
    }
}

export const passwordError = (password, required=true) => {
    if (!password && required) {
        return _('Required')
    } else if (password && password.length > 0 && password.length < 6) {
        return _('Password must be at least 6 characters long')
    }
}

export const maxPostsError = (posts_max, posts_to_share) => {
  // posts_max: value from the form
  // posts_to_share: how many posts current admin has to share
    if (posts_max < 1) {
        return _('Max posts needs to be at least 1')
    }
    const error = _('You don\'t have anough posts to share')
    if (posts_to_share === 'infinity') {
        return
    }
    const _posts_to_share = parseInt(posts_to_share, 10)
    const _posts_max = parseInt(posts_max, 10)
    if (!_posts_to_share) {
        return error
    }
    if (!_posts_max) {
        return
    }
    if (_posts_max > _posts_to_share) {
        return error
    }
}