/* global $ */
import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import { Link } from 'react-router'
import classnames from 'classnames'
import { _ } from '../helpers/translations'

import logo from '../assets/images/logo.svg?v2'

const Header = createReactClass({
    propTypes: {
        account: PropTypes.object,
        auth: PropTypes.object,
    },
    toggleSideMenu() {
        $('.sidebar').toggleClass('opened')
    },
    render() {
        const account = this.props.account
        const auth = this.props.auth
        const notyou = auth.username && account.username && auth.username !== account.username
        return (
    <div className={classnames('header', {notyou})}>
        <div className="logo">
          <Link to={`/accounts/${account.username}/clients`}>
                <img alt="HashsnapLogo" src={logo} />
            </Link>
        </div>
        <div className="___menu" onClick={this.toggleSideMenu}><i className="icon-menu3"></i></div>
        <div className="user">
                <div className="info">
                    <div>
                        <div className="txt">{account.name}</div>
                        <Link title='Account' to={`/accounts/${account.username}/info`} className="icon second">
                            <div className="settings_icon"></div>
                        </Link>
                        <Link title='Sign out' to={'/sign-out'} className="icon first">
                            <div className="log_out_icon"></div>
                        </Link>
                    </div>
                    {notyou &&
                    <div
                        className="admin_header"
                        onClick={() => alert(`You are acting on behalf of ${account.username}, but real you is ${auth.username}.`)}>
                        {_('common.admin')}
                    </div>}
                </div>
            </div>
      </div>)
    }
})
export default Header

