import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'

import { isNil, isEmpty } from 'lodash'

import { updateAccount } from '../../actions/account'
import { renderField } from '../inputs'

import { _ } from '../../helpers/translations'
import { phoneError, emailError } from '../../helpers/validators'
import { formatDate } from '../../helpers/utils'
import countries from '../../helpers/countries'
import { timezones } from '../../helpers/timezones'
import createReactClass from 'create-react-class'
import {InitializeDatatables, InitializeScroll, SetHeight} from '../../assets/js/common'


const AdminAccountEditComponent = createReactClass({
    propTypes: {
        account: PropTypes.object,
        handleSubmit: PropTypes.func.isRequired,
        goBack: PropTypes.func
    },
    componentDidMount() {
        SetHeight()
        InitializeDatatables()
        InitializeScroll()
    },
    render() {
        const account = this.props.account
        return (
    <React.Fragment>
        <div className="box">
            <div className="layout">
                <div className="main_title">
                    <h1>{_('account.edit.header')}</h1>
                </div>
                <form onSubmit={this.props.handleSubmit}>
                    <fieldset className="mtop_25">
                        <div className="grid_2_first_s">
                            <div>
                                <div className="center profile_intro new">
                                    <div className="index_top">
                                        <div className="profile_name">
                                            <Field name='name' component={renderField} id="name" type='text'></Field>
                                        </div>
                                        <div className="date dark">{_('common.created-on')} {formatDate(account.created_date)}</div>
                                    </div>
                                </div>
                                <div className="tools">
                                    <div className="adjust">
                                        <div>
                                            <button type='submit'>
                                                {this.props.isFetching ? <div><i className="icon-spinner9 size_25 orange spin"></i></div> : <div className="save_icon icon"></div>}
                                            </button>
                                            <div className="size_13 mtop_5">{_('common.save')}</div>
                                        </div>
                                        <div>
                                            <a href="#" className="round sing" onClick={this.props.goBack}>
                                                <div className="cancel_icon icon"></div>
                                            </a>
                                            <div className="size_13 mtop_5">{_('common.cancel')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="white_panel">
                                    <table className="profile_data">
                                        <tbody>
                                            <tr>
                                                <td><div className="label">{_('common.country')}:</div></td>
                                                <td>
                                                    <div className="select_arrow">
                                                        <Field className='form-control' name='country' component='select' id='country'>
                                                            {countries.map((i, n) => <option key={n} value={i.code}>{i.name}</option>)}
                                                        </Field>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><div className="label">{_('common.timezone')}:</div></td>
                                                <td>
                                                    <div className="select_arrow timezone-select">
                                                        <Field className='form-control' name='timezone' component='select' id='timezone'>
                                                            {timezones.map((i, n) => <option key={n} value={i.value}>{`${i.text} (${i.abbr})`}</option>)}
                                                        </Field>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><div className="label">{_('common.phone')}:</div></td>
                                                <td>
                                                    <Field name='phone' component={renderField} id="phone" placeholder=""  type='text'></Field>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><div className="label">{_('common.email')}:</div></td>
                                                <td>
                                                    <Field name='email' component={renderField} id="email" placeholder=""  type='email'></Field>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                     </fieldset>
                </form>
            </div>
        </div>
    </React.Fragment>)
    }
})

// Container
const form = 'account_edit'
const validate = (values) => {
    const errors = {}
    if (isNil(values) || isEmpty(values)) {
        return errors
    }
    if (emailError(values.email)) {
        errors.email = emailError(values.email)
    }
    if (phoneError(values.phone)) {
        errors.phone = phoneError(values.phone)
    }
    return errors
}


let AdminAccountEdit = reduxForm({
    form,
    validate,
    enableReinitialize: true,
})(AdminAccountEditComponent)

let mapStateToProps = (state) => {
    const account = state.account
    const props = {
        account
    }
    if (account) {
        props.initialValues = {
            name: account.name,
            phone: account.phone,
            email: account.email,
            timezone: account.timezone,
            country: account.country,
        }
    }
    return props
}

let mapDispatchToProps = (dispatch, ownProps) => ({
    onSubmit(data) {
        dispatch(updateAccount(data))
        .then(() => {
            ownProps.router.goBack()
        })
    },
    goBack(e) {
        e.preventDefault()
        ownProps.router.goBack()
    },
})

AdminAccountEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminAccountEdit)

export default AdminAccountEdit
