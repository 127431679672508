import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import { connect } from 'react-redux'
import { reduxForm, change, Field } from 'redux-form'

import { isNil, isEmpty } from 'lodash'

import { adminCreateAccount, checkUsername } from '../../actions/account'
import { usernameError, emailError, passwordError, phoneError, requiredError } from '../../helpers/validators'
import { renderField } from '../inputs'

import { _, getLanguages } from '../../helpers/translations'
import countries from '../../helpers/countries'
import { timezones } from '../../helpers/timezones'

import { InitializeScroll, SetHeight } from '../../assets/js/common'


const AdminNewComponent = createReactClass({
    propTypes: {
        account: PropTypes.object.isRequired,
        isFetching: PropTypes.bool,
        change: PropTypes.func,
        handleSubmit: PropTypes.func,
        back: PropTypes.func,
        asyncValidating: PropTypes.any,
        // admins: PropTypes.array,
        languages: PropTypes.array,
    },
    componentDidMount() {
        SetHeight()
        InitializeScroll()
    },
    render() {
        return (
    <React.Fragment>
        <div className="box">
            <div className="layout">
                <div className="main_title">
                    <h1>{_('admins.new.header')}</h1>
                </div>
                <form onSubmit={this.props.handleSubmit}>
                    <fieldset className="mtop_25">
                        <div className="grid_2_first_s">
                            <div>
                                <div className="center profile_intro new">
                                    <div className="index_top">
                                        <div className="profile_name">
                                            <Field name='name' component={renderField} id="name" placeholder={_('admins.new.input.name')} type='text'></Field>
                                        </div>
                                        <div className="profile_round mtop_25">
                                            <Field name='username' component={renderField} id="username" placeholder={_('admins.new.input.username')}  type='text'></Field>
                                        </div>
                                        <div className="profile_round mtop_15">
                                            <Field name='password' component={renderField} id="password" placeholder={_('admins.new.input.password')}  type='password'></Field>
                                        </div>
                                    </div>
                                </div>
                                <div className="tools">
                                    <div className="adjust">
                                        <div>
                                            <button type='submit'>
                                                {this.props.isFetching ? <div><i className="icon-spinner9 size_25 orange spin"></i></div> : <div className="save_icon icon"></div>}
                                            </button>
                                            <div className="size_13 mtop_5">{_('common.save')}</div>
                                        </div>
                                        <div>
                                            <a href="#" className="round sing" onClick={this.props.back}>
                                                <div className="cancel_icon icon"></div>
                                            </a>
                                            <div className="size_13 mtop_5">{_('common.cancel')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="white_panel">
                                    <table className="profile_data">
                                       <tbody>
                                        <tr>
                                            <td><div className="label">{_('common.country')}:</div></td>
                                            <td>
                                                <div className="select_arrow">
                                                    <Field name='country' component='select' id='country'>
                                                        {countries.map((i, n) => <option key={n} value={i.code}>{i.name}</option>)}
                                                    </Field>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><div className="label">{_('common.timezone')}:</div></td>
                                            <td>
                                                <div className="select_arrow timezone-select">
                                                    <Field name='timezone' component='select' id='timezone'>
                                                        {timezones.map((i, n) => <option key={n} value={i.value}>{`${i.text} (${i.abbr})`}</option>)}
                                                    </Field>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><div className="label">{_('common.phone')}:</div></td>
                                            <td>
                                                <Field name='phone' component={renderField} id="phone" placeholder=""  type='text'></Field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><div className="label">{_('common.email')}:</div></td>
                                            <td>
                                                <Field name='email' component={renderField} id="email" placeholder=""  type='email'></Field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><div className="label">{_('common.language')}:</div></td>
                                            <td>
                                                <div className="select_arrow">
                                                    <Field name='language' component='select' id='language'>
                                                        {this.props.languages.map((l, n) => <option key={n} value={l}>{l}</option>)}
                                                    </Field>
                                                </div>
                                            </td>
                                        </tr>
                                       </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                     </fieldset>
                </form>
            </div>
        </div>
    </React.Fragment>)
    }
})


// Container
const form = 'admin_new'
const validate = (values) => {
    const errors = {}
    if (isNil(values) || isEmpty(values)) {
        return errors
    }
    if (usernameError(values.username)) {
        errors.username = usernameError(values.username)
    }
    if (emailError(values.email)) {
        errors.email = emailError(values.email)
    }
    if (passwordError(values.password)) {
        errors.password = passwordError(values.password)
    }
    if (requiredError(values.name)) {
        errors.name = requiredError(values.name)
    }
    if (phoneError(values.phone)) {
        errors.phone = phoneError(values.phone)
    }
    return errors
}

let AdminNew = reduxForm({
    form,
    enableReinitialize: true,
    validate,
    asyncValidate: checkUsername,
    asyncBlurFields: ['username'],
})(AdminNewComponent)

let mapStateToProps = (state) => {
    const account = state.account
    const languages = getLanguages()
    const initialValues = {
        username: '',
        country: 'KR',
        timezone: 'Korea Standard Time',
        language: 'kr',
        name: '',
        phone: '',
        email: '',
        admin_id: account.id,
        extra: '',
        is_admin: true,
        type: 2
    }
    return {
        account,
        isFetching: state.admin_accounts.isFetching,
        // admins: [account, ...state.admin_accounts.accounts.filter(a => a.roles.includes('ROLE_ADMIN'))],
        languages,
        initialValues,
    }
}

let mapDispatchToProps = (dispatch, ownProps) => ({
    onSubmit(data) {
        dispatch(adminCreateAccount(data))
        .then(admin => {
            let url = `/accounts/${ownProps.params.account_username}/admins/${admin.username}`
            ownProps.router.push(url)
        })
    },
    change(field, value) {
        dispatch(change(form, field, value))
    },
    back() {
        ownProps.router.goBack()
    },
})

AdminNew = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminNew)

export default AdminNew
