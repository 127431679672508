const reducer = (state={
    isVisible: false,
    inputValue: '',
    noMatch: false,
    callback: () => {}
}, action) => {
    switch (action.type) {
    case 'MODAL_DELETE_SHOW':
        return {...state, isVisible: true, callback: action.callback}
    case 'MODAL_DELETE_ON_INPUT':
        return {...state, noMatch: false, inputValue: action.value}
    case 'MODAL_DELETE_NO_MATCH':
        return {...state, noMatch: true}
    case 'MODAL_DELETE_HIDE':
    case 'MODAL_ALERT_SHOW':
    case 'MODAL_CONFIRM_SHOW':
        return {...state, isVisible: false, noMatch: false, inputValue: '', callback: () => {}}
    default:
        return state
    }
}

export default reducer