import { combineReducers } from 'redux'
import auth from './auth'
import account from './account'
import modalAlert from './modal-alert'
import modalConfirm from './modal-confirm'
import modalDelete from './modal-delete'
import admin_accounts from './admin_accounts'
import { reducer as form } from 'redux-form'

const rootReducer = combineReducers({
    account,
    auth,
    form,
    modalAlert,
    modalConfirm,
    modalDelete,
    admin_accounts,
})

export default rootReducer
