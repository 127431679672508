/* global $ */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import createReactClass from 'create-react-class'
import { connect } from 'react-redux'

import { sortAndKeepNullsAtTheEnd, formatDate } from '../../helpers/utils'
import { _ } from '../../helpers/translations'

import { InitializeDatatables, InitializeScroll, SetHeight } from '../../assets/js/common'


const ProjectRequestsListComponent = createReactClass({
    propTypes: {
        account: PropTypes.object,
        projectRequests: PropTypes.array,
    },
    componentDidMount() {
        SetHeight()
        InitializeDatatables()
        InitializeScroll()

        $('.sidebar').removeClass('opened')
    },
    getInitialState() {
        return {
            projectRequests: sortAndKeepNullsAtTheEnd(this.props.projectRequests, 'created_date', false),
            search: '',
            orderIdAsc: true,
            orderUsernameAsc: true,
            orderTypeAsc: true,
            orderCreatedDateAsc: false,
        }
    },
    componentWillReceiveProps(nextProps) {
        this.setState({projectRequests: sortAndKeepNullsAtTheEnd(nextProps.projectRequests, 'created_date', false)})
    },
    search(e) {
        this.setState({search: e.target.value})
    },
    render() {
        const account = this.props.account
        const projectRequests = this.state.projectRequests
        return (
        <React.Fragment>
            <div className="box">
                <div className="layout">
                    <div className="main_title">
                        <h1>
                            {_('requests.list.header')}
                        </h1>
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>{_('common.column.id')}</th>
                                <th>{_('requests.list.column.name')}</th>
                                <th>{_('requests.list.column.client')}</th>
                                <th>{_('requests.list.column.date')}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {projectRequests.map((projectRequest, index) => (
                            <tr key={index}>
                                <td className="center relative">{projectRequest.id}<div className="table_hover"></div></td>
                                <td className='capitalize'>
                                    <Link title='Edit' to={`/accounts/${account.username}/clients/${projectRequest.username}/project-requests/${projectRequest.uid}`} className='theme'>
                                        {projectRequest.name}
                                    </Link>
                                </td>
                                <td className="center">{projectRequest.username}</td>
                                <td className="center">{formatDate(projectRequest.created_date)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>)
    }
})


// Container
const mapStateToProps = (state) => {
    const account = state.account
    const accounts = state.admin_accounts.accounts || []
    const projects = accounts.filter(i => !i.deleted).reduce((agg, a) => [...agg, ...a.projects.map(
        p => ({...p, username: a.username, account_id: a.id, admin_id: a.admin_id}))], [])
    const projectRequests = projects.filter(p => !p.deleted && p.status === 'request')
    return {
        account,
        projectRequests
    }
}

const mapDispatchToProps = () => ({
})

const ProjectRequestsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectRequestsListComponent)

export default ProjectRequestsList
