import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import createReactClass from 'create-react-class'
import { connect } from 'react-redux'
// import { Link } from 'react-router'

import { _ } from '../../helpers/translations'
import * as conf from '../../conf'
import { InitializeScroll, SetHeight } from '../../assets/js/common'


const AccountAccountChangePasswordComponent = createReactClass({
    propTypes: {
        account: PropTypes.object,
    },
    getInitialState() {
        return {    
            oldPassword: '',
            newPassword: '',
            repeatNewPassword: '',
        }
    },
    AccountChangePassword() {
        const dispatch = this.props.dispatch
        if (!this.state.oldPassword) {
            dispatch({
                type: 'MODAL_ALERT_SHOW',
                level: 'error',
                message: _('account.change-password.modal-alert.old-password-missing'),
            })
            return
        }
        if (!this.state.newPassword) {
            dispatch({
                type: 'MODAL_ALERT_SHOW',
                level: 'error',
                message: _('account.change-password.modal-alert.new-password-missing'),
            })
            return
        }
        if (this.state.newPassword !== this.state.repeatNewPassword) {
            dispatch({
                type: 'MODAL_ALERT_SHOW',
                level: 'error',
                message: _('account.change-password.modal-alert.password-repeat-no-match'),
            })
            return
        }
        axios.post(`${conf.host_api}/accounts/${this.props.account.username}/change-password`, {
            old_password: this.state.oldPassword,
            new_password: this.state.newPassword,
        }).then(response => {
            if (response.data.status === 0) {
                dispatch({
                    type: 'MODAL_ALERT_SHOW',
                    message: _('account.change-password.modal-alert.password-saved'),
                })
                this.props.router.goBack()
            } else {
                dispatch({
                    type: 'MODAL_ALERT_SHOW',
                    level: 'error',
                    message: _(response.data.error),
                })
                this.setState({error: response.data.error})
            }
        })
    },
    componentDidMount() {
        SetHeight()
        InitializeScroll()
    },
    render() {
        const account = this.props.account
        if (!account) {
            return null
        }
        return (
    <React.Fragment>
        <div className="box">
            <div className="layout">
                <div className="main_title">
                    <h1>{_('account.change-password.header')}</h1>
                </div>
                <form>
                    <fieldset className="mtop_25">
                        <div className="grid_2_first_s">
                            <div>
                                <div className="center profile_intro new">
                                    <div className="index_top">
                                        <div className="profile_name col_dark">
                                            {account.name}
                                        </div>
                                    </div>
                                </div>
                                <div className="tools">
                                    <div className="adjust">
                                        <div>
                                            <button type='button' onClick={this.AccountChangePassword}>
                                                <div className="save_icon icon"></div>
                                            </button>
                                            <div className="size_13 mtop_5">{_('common.save')}</div>
                                        </div>
                                        <div>
                                            <button type='button' className="round sing" onClick={this.props.router.goBack}>
                                                <div className="cancel_icon icon"></div>
                                            </button>
                                            <div className="size_13 mtop_5">{_('common.cancel')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="white_panel white_panel_client">
                                    <table className="profile_data">
                                       <tbody>
                                        <tr>
                                            <td><div className="label">{_('account.change-password.input.old-password')}:</div></td>
                                            <td>
                                                <input name='old_password' id="old_password" type='password' value={this.state.oldPassword} onChange={e => this.setState({oldPassword: e.target.value})}></input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><div className="label">{_('account.change-password.input.new-password')}:</div></td>
                                            <td>
                                                <input name='new_password' id="new_password" type='password' value={this.state.newPassword} onChange={e => this.setState({newPassword: e.target.value})}></input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><div className="label">{_('account.change-password.input.new-password-repeat')}:</div></td>
                                            <td>
                                                <input name='repeat_new_password' id="repeat_new_password" type='password' value={this.state.repeatNewPassword} onChange={e => this.setState({repeatNewPassword: e.target.value})}></input>
                                            </td>
                                        </tr>
                                       </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                     </fieldset>
                </form>
            </div>
        </div>
    </React.Fragment>)}
})

let mapStateToProps = (state) => {
    const account = state.account
    return {
        account
    }
}

let mapDispatchToProps = (dispatch) => ({
    dispatch
})

const AccountChangePassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountAccountChangePasswordComponent)

export default AccountChangePassword
