/* global $ */
$(document).ready(function() {
    // const height = window.innerHeight - 82
    // $('#container').css('height', height + 'px')
})


export const InitializeScroll = ()=> {
    // $('.box').mCustomScrollbar({
    //     scrollInertia: '300',
    //     advanced: {
    //         updateOnContentResize: false,
    //     }
    // })
}

export const SetHeight = ()=> {
    // let height = window.innerHeight - 65
    // $('.box').css('height', height +'px')

    // $('.sidebar').css('height', window.innerHeight +'px')
    // $('#container, .container').css('height', window.innerHeight +'px')
}

export const InitializeDatatables = (customConf)=> {
    $('.table').DataTable({
        ...{
            'processing': true,
            'pageLength': 25,
            'sorting': [0, 'desc'],
            'ordering': true,
            'responsive': true,
            'searching': true,
            'info': true,
            'paging': true,
            'autoWidth': false,
            'lengthChange': false,
            'select': false,
            "drawCallback": function (settings) {
                $('.box').mCustomScrollbar("update")
                $('.box').mCustomScrollbar("scrollTo","top",{
                    scrollInertia:300
                });
            }
        , ...customConf}
    });


}

export const InitializeDatatablesAsc = ()=> {
    $('.table').DataTable({
        'processing': true,
        'pageLength': 25,
        'sorting': [0, 'asc'],
        'ordering': true,
        'responsive': true,
        'searching': true,
        'info': true,
        'paging': true,
        'autoWidth': false,
        'lengthChange': false,
        'select': false,
        "drawCallback": function (settings) {
            $('.box').mCustomScrollbar("update");
        }
    });
}
