/* global $ */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import createReactClass from 'create-react-class'
import { deleteAccount } from '../../actions/account'
import { sortAndKeepNullsAtTheEnd, Explained } from '../../helpers/utils'
import { _ } from '../../helpers/translations'
import * as conf from '../../conf'

import { InitializeDatatables } from '../../assets/js/common'
import { InitializeScroll,  } from '../../assets/js/common'
import { SetHeight,  } from '../../assets/js/common'


const AdminsListComponent = createReactClass({
    propTypes: {
        isFetching: PropTypes.bool,
        accounts: PropTypes.array,
        account: PropTypes.object,
        deleteAccount: PropTypes.func,
    },
    componentDidMount() {
        SetHeight()
        InitializeDatatables({columnDefs: [ { targets: [0, 1, 2, 3, 7], orderable: false } ]})
        InitializeScroll()

        $('.sidebar').removeClass('opened')
    },
    getInitialState() {
        return {
            accounts: sortAndKeepNullsAtTheEnd(this.props.accounts, 'created_date', false),
            filter: 'all',
            search: '',
            orderIdAsc: true,
            orderNameAsc: true,
            orderUsernameAsc: true,
            orderRoleAsc: true,
            orderCreatedDateAsc: false,
        }
    },
    componentWillReceiveProps(nextProps) {
        this.setState({accounts: sortAndKeepNullsAtTheEnd(nextProps.accounts, 'created_date', false)})
    },
    filterAdmins(admins) {
        if (this.state.search) {
            const r = new RegExp(this.state.search, 'i')
            admins = admins.filter(a => a.id.toString() === this.state.search || a.username.match(r) || a.name.match(r))
        }
        return admins
    },
    render() {
        const account = this.props.account
        let admins = this.state.accounts.filter(a => a.topRole === 'admin' || a.topRole === 'superadmin')
        admins = this.filterAdmins(admins).map(a => ({
            ...a,
            clients: this.props.accounts.filter(c => c.admin_id === a.id).map(c => c.username)
        }))
        return (
        <React.Fragment>
            <div className="box">
                <div className="layout">
                    <div className="main_title">
                        <h1>
                            {_('admins.list.header')}
                            <Link title='Add' className='add_btn table_list_btn' role='button' to={`/accounts/${account.username}/admins/new`}>{_('admins.list.add-new-admin')}</Link>
                        </h1>
                    </div>
                    {account.topRole === 'superadmin' &&

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="no_sort all">{_('common.column.id')}</th>
                                        <th className="no_sort all">{_('admins.list.column.name')}</th>
                                        <th className="no_sort all">
                                            <div style={{display:'flex'}}>
                                                <div>{_('common.login-as')}</div>
                                                <div style={{transform:'translateX(3px)'}}>
                                                    <Explained message={_('explained.projects-login-as')}></Explained>
                                                </div>
                                            </div>
                                        </th>
                                        <th>{_('admins.list.column.username')}</th>
                                        <th>{_('admins.list.column.type')} <i className='fa fa-sort' aria-hidden='true'></i></th>
                                        <th>{_('admins.list.column.clients-count')}</th>
                                        <th>{_('admins.list.column.clients-accounts')}</th>
                                        <th className="no_sort">{_('common.delete')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {admins.map((admin, index) => (
                                    <tr key={index}>
                                        <td className="center relative">{admin.id}<div className="table_hover"></div></td>
                                        <td className='capitalize'>
                                            <Link title='Edit' to={`/accounts/${account.username}/admins/${admin.username}`} className='theme'>
                                                {admin.name}
                                            </Link>
                                        </td>
                                        <td className="center relative">
                                            <a target='_blank' title='Sign in' href={`${conf.host_admin}/accounts/${admin.username}/clients`} className="icon_action sing relative">
                                                <div className="login_list_icon icon"></div>
                                            </a>
                                        </td>
                                        <td className="center">{admin.username}</td>
                                        <td className="uppercase center">{admin.topRole === 'superadmin' ? <div>{_('admins.list.type.super')}</div> : <div>{_('admins.list.type.sales')}</div> }</td>
                                        <td className="center table_num">{admin.clients.length}</td>
                                        <td className="size_12 center">{admin.clients.join(', ')}</td>
                                        <td className="center relative">
                                            <a href='#' role='button' className="cursor relative icon_action" onClick={() => this.props.deleteAccount(admin.username)}>
                                                <div className="delete_list_icon icon"></div>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                    }
                </div>
            </div>
        </React.Fragment>)
    }
})


// Container
const mapStateToProps = (state) => {
    const account = state.account
    const accounts = state.admin_accounts.accounts || []
    return {
        isFetching: state.admin_accounts.isFetching,
        accounts: accounts.filter(i => !i.deleted),
        account,
    }
}

const mapDispatchToProps = (dispatch) => ({
    deleteAccount(username) {
        dispatch({type: 'MODAL_DELETE_SHOW', callback: (isDeleted) => {
            if (isDeleted) {
                dispatch(deleteAccount(username))
            }
        }})
    },
})

const AdminsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminsListComponent)

export default AdminsList
