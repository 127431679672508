import { values } from 'lodash'
import { topRole } from '../helpers/utils'

const admin_accounts = (state={
    isFetching: true,
    accounts: [],
}, action) => {
    switch (action.type) {
    case 'REQUEST_ADMIN_ACCOUNTS':
    case 'REQUEST_SAVE_ADMIN_ACCOUNT':
    case 'REQUEST_DELETE_ACCOUNT':
    case 'REQUEST_ADMIN_CREATE_ACCOUNT':
    case 'REQUEST_FETCH_PROJECT':
    case 'REQUEST_CREATE_PROJECT':
    case 'REQUEST_DELETE_PROJECT':
    case 'REQUEST_UPDATE_PROJECT':
        return {...state, isFetching: true}
    case 'RECEIVE_ADMIN_ACCOUNTS':
        return {
            ...state,
            isFetching: false,
            accounts: action.accounts.map(a => ({...a, topRole: topRole(a.roles)}))
        }
    case 'RECEIVE_SAVE_ADMIN_ACCOUNT':
        return {
            ...state,
            isFetching: false,
            accounts: state.accounts.map(
          a => a.id === action.account.id ? {...action.account, topRole: topRole(action.account.roles)} : a)}
    case 'RECEIVE_DELETE_ACCOUNT':
        return {...state, isFetching: false, accounts: state.accounts.filter(account => account.username !== action.username)}
    case 'RECEIVE_ADMIN_CREATE_ACCOUNT':
        return {...state, isFetching: false, accounts: [...state.accounts, {...action.account, topRole: topRole(action.account.roles)}]}
    case 'RECEIVE_CREATE_PROJECT':
        return {
            ...state,
            isFetching: false,
            accounts: state.accounts.map(i => i.id !== action.accountId ? i : {
                ...i,
                projects: [...i.projects, action.project]
            })
        }
    case 'RECEIVE_DELETE_PROJECT':
        return {
            ...state,
            isFetching: false,
            accounts: state.accounts.map(i => ({
                ...i,
                projects: i.projects.filter(j => j.uid !== action.projectUid)
            }))
        }
    case 'RECEIVE_FETCH_PROJECT':
    case 'RECEIVE_UPDATE_PROJECT':
        return {
            ...state,
            isFetching: false,
            accounts: state.accounts.map(i => ({
                ...i,
                projects: i.projects.map(j => j.uid !== action.project.uid ? j : ({
                    ...action.project,
                    whitelisted_users: values(action.project.whitelisted_users),
                    blacklisted_users: values(action.project.blacklisted_users),
                    keys: values(action.project.keys),
                }))
            }))
        }
    case 'ADMIN_ACCOUNTS_ERROR':
    case 'SAVE_ADMIN_ACCOUNT_ERROR':
    case 'ADMIN_CREATE_ACCOUNT_ERROR':
    case 'DELETE_ACCOUNT_ERROR':
    case 'FETCH_PROJECT_ERROR':
    case 'CREATE_PROJECT_ERROR':
    case 'DELETE_PROJECT_ERROR':
    case 'UPDATE_PROJECT_ERROR':
        return {...state, isFetching: false}
    default:
        return state
    }
}

export default admin_accounts

