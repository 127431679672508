import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import classnames from 'classnames'

import { includes } from 'lodash'

import { _ } from '../helpers/translations'

import social_instagram from '../assets/images/icons/social_instagram.png'
import social_twitter from '../assets/images/icons/social_twitter.png'
import social_facebook from '../assets/images/icons/social_facebook.png'
// import social_vkontakte from '../assets/images/icons/social_vkontakte.png'
// import social_tiktok from '../assets/images/icons/social_tiktok.png'

const renderField = ({ className, input, type, id, placeholder, disabled, meta: { touched, error, asyncValidating } }) => {
    return (
        <React.Fragment>
            <input className={'form-control' + className ? ' ' + className : ''} {...input} type={type} id={id} placeholder={placeholder} disabled={disabled} />
            {(touched && error) && <span className="help-block invalid-feedback" style={{ display: 'block' }}><i className="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;{error}</span>}
            {(touched && asyncValidating) && <div>{_('inputs.render-field.checking')}</div>}
        </React.Fragment>)
}
renderField.propTypes = {
    className: PropTypes.string,
    input: PropTypes.object,
    type: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    meta: PropTypes.object,
}

const CheckboxHelper = ({ className, label, obj, field_name }) => (
    <div className={className}>
        <input type="checkbox" id={field_name} className='styled-checkbox' checked={obj.state[field_name]} onChange={(e) => obj.change(field_name, e.target.checked)} />
        <label htmlFor={field_name} className="label-text"><font>{label}</font></label>
    </div>
)

const CheckboxWithImages = ({ className, label, obj, field_name, path }) => (
    <div className={className}>
        <input type="checkbox" id={field_name} className='styled-checkbox' checked={obj.state[field_name]} onChange={(e) => obj.change(field_name, e.target.checked)} />
        <label className="label-text" htmlFor={field_name}>
            <img src={path} />
        </label>
    </div>
)

const EventTypeInput = createReactClass({
    propTypes: {
        value: PropTypes.string,
        onChange: PropTypes.func,
    },
    getInitialState() {
        return {
            value: this.props.value || 'hashtag'
        }
    },
    componentWillReceiveProps(nextProps) {
        this.setState({ value: nextProps.value })
    },
    change(value) {
        this.setState({ value }, () => {
            this.props.onChange(value)
        })
    },
    render() {
        return (
            <div className="EventTypeInput">
                <div className={this.state.value === 'hashtag' ? 'selected' : ''} onClick={() => this.change('hashtag')}>{_('inputs.event-type.hashtag')}</div>
                <div className={this.state.value === 'mention' ? 'selected' : ''} onClick={() => this.change('mention')}>{_('inputs.event-type.mention')}</div>
                {/* <div className={this.state.value === 'hashtag_or_mention' ? 'selected' : ''} onClick={() => this.change('hashtag_or_mention')}>{_('inputs.event-type.hashtag-or-mention')}</div>
                <div className={this.state.value === 'hashtag_and_mention' ? 'selected' : ''} onClick={() => this.change('hashtag_and_mention')}>{_('inputs.event-type.hashtag-and-mention')}</div>
                <div className={this.state.value === 'my_page' ? 'selected' : ''} onClick={() => this.change('my_page')}>{_('inputs.event-type.my-page')}</div> */}
                <div className={this.state.value === 'upload_only' ? 'selected' : ''} onClick={() => this.change('upload_only')}>{_('inputs.event-type.upload-only')}</div>
            </div>)
    }
})

const AbilitiesInput = createReactClass({
    propTypes: {
        abilities: PropTypes.array,
        onChange: PropTypes.func,
    },
    getParams(abilities) {
        return {
            advanced_moderation: includes(abilities, 'advanced_moderation'),
            custom_styles: includes(abilities, 'custom_styles'),
            cloudupload: includes(abilities, 'cloudupload'),
            // active_screens: includes(abilities, 'active_screens'),

            mosaic: includes(abilities, 'mosaic'),
            reports: includes(abilities, 'reports'),
            // voting: includes(abilities, 'voting'),
            // shopping: includes(abilities, 'shopping'),
            // printer_lottery: includes(abilities, 'printer_lottery'),
            // printer_language_pack: includes(abilities, 'printer_language_pack'),
        }
    },
    getInitialState() {
        return this.getParams(this.props.abilities || [])
    },
    componentWillReceiveProps(nextProps) {
        this.setState(this.getParams(nextProps.abilities))
    },
    change(field, value) {
        this.setState({ [field]: value }, () => {
            const abilities = []
            if (this.state.custom_styles) {
                abilities.push('custom_styles')
            }
            if (this.state.advanced_moderation) {
                abilities.push('advanced_moderation')
            }
            if (this.state.cloudupload) {
                abilities.push('cloudupload')
            }
            if (this.state.reports) {
                abilities.push('reports')
            }
            // if (this.state.active_screens) {
            //     abilities.push('active_screens')
            // }
            // if (this.state.voting) {
            //     abilities.push('voting')
            // }
            // if (this.state.shopping) {
            //     abilities.push('shopping')
            // }
            if (this.state.mosaic) {
                abilities.push('mosaic')
            }
            // if (this.state.printer_lottery) {
            //     abilities.push('printer_lottery')
            // }
            // if (this.state.printer_language_pack) {
            //     abilities.push('printer_language_pack')
            // }
            this.props.onChange(abilities)
        })
    },
    render() {
        return (
            <React.Fragment>
                {/* <h5>Optional</h5>
                <CheckboxHelper obj={this} label={_('inputs.abilities.advanced-moderation')} field_name='advanced_moderation' title={_('inputs.abilities.advanced-moderation.description')}/>
                <CheckboxHelper obj={this} label={_('inputs.abilities.css')} field_name='custom_styles' />
                <CheckboxHelper obj={this} label={_('inputs.abilities.direct-upload')} field_name='cloudupload' /> */}
                {/* <CheckboxHelper obj={this} label={_('inputs.abilities.active-screens')} field_name='active_screens' title={_('inputs.abilities.active-screens.description')}/> */}
                <h5>Paid</h5>
                <CheckboxHelper obj={this} label={_('inputs.abilities.mosaic')} field_name='mosaic' />
                <CheckboxHelper obj={this} label={_('inputs.abilities.reports')} field_name='reports' />
                {/* <CheckboxHelper obj={this} label={_('inputs.abilities.voting')} field_name='voting' title={_('inputs.abilities.voting.description')}/>
                <CheckboxHelper obj={this} label={_('inputs.abilities.shopping')} field_name='shopping' title={_('inputs.abilities.shopping.description')}/>
                <CheckboxHelper obj={this} label={_('inputs.abilities.lottery')} field_name='printer_lottery' />
                <CheckboxHelper obj={this} label={_('inputs.abilities.language-pack')} field_name='printer_language_pack' /> */}
            </React.Fragment>)
    }
})

const ServicesInput = createReactClass({
    propTypes: {
        services: PropTypes.array,
        onChange: PropTypes.func,
    },
    getParams(services) {
        return {
            screen: includes(services, 'screen'),
            web: includes(services, 'web'),
            printer: includes(services, 'printer'),
            // broadcast: includes(services, 'broadcast'),
        }
    },
    getInitialState() {
        return this.getParams(this.props.services || [])
    },
    componentWillReceiveProps(nextProps) {
        this.setState(this.getParams(nextProps.services))
    },
    change(field, value) {
        this.setState({ [field]: value }, () => {
            const services = []
            if (this.state.screen) {
                services.push('screen')
            }
            if (this.state.web) {
                services.push('web')
            }
            if (this.state.printer) {
                services.push('printer')
            }
            // if (this.state.broadcast) {
            //     services.push('broadcast')
            // }
            this.props.onChange(services)
        })
    },
    render() {
        return (
            <React.Fragment>
                <CheckboxHelper obj={this} label={_('inputs.services.screen')} field_name='screen' />
                <CheckboxHelper obj={this} label={_('inputs.services.web')} field_name='web' />
                <CheckboxHelper obj={this} label={_('inputs.services.printer')} field_name='printer' />
                {/* <CheckboxHelper obj={this} label={_('inputs.services.broadcast')} field_name='broadcast' /> */}
            </React.Fragment>)
    }
})

const SocialNetworksInput = createReactClass({
    propTypes: {
        social_networks: PropTypes.array,
        event_type: PropTypes.string,
        onChange: PropTypes.func,
    },
    getParams(social_networks, event_type) {
        return {
            instagram: includes(social_networks, 'instagram') && !this.disabled('instagram', event_type),
            twitter: includes(social_networks, 'twitter') && !this.disabled('twitter', event_type),
            facebook: includes(social_networks, 'facebook') && !this.disabled('facebook', event_type),
            // vkontakte: includes(social_networks, 'vkontakte') && !this.disabled('vkontakte', event_type),
            // tiktok: includes(social_networks, 'tiktok') && !this.disabled('tiktok', event_type),
        }
    },
    getInitialState() {
        return this.getParams(this.props.social_networks || [], this.props.event_type || 'hashtag')
    },
    componentWillReceiveProps(nextProps) {
        this.setState(this.getParams(nextProps.social_networks, nextProps.event_type))
    },
    disabled(network, event_type) {
        if (event_type === 'upload_only') {
            return true
        }
        if (network === 'instagram') {
            // event_type in prohibited list (['mention'])
            return [].indexOf(event_type) !== -1
        } else if (network === 'twitter') {
            return ['mention'].indexOf(event_type) !== -1
        } else if (network === 'facebook') {
            // return true
            return ['hashtag'].indexOf(event_type) !== -1
            // } else if (network === 'vkontakte') {
            //     return ['mention', 'hashtag_and_mention', 'my_page'].indexOf(event_type) !== -1
            // } else if (network === 'tiktok') {
            //     return ['mention', 'hashtag_and_mention', 'my_page'].indexOf(event_type) !== -1
        }
    },
    change(social_network, value) {
        if (!this.disabled(social_network, this.props.event_type)) {
            this.setState({ [social_network]: value }, () => {
                const social_networks = []
                if (this.state.instagram) {
                    social_networks.push('instagram')
                }
                if (this.state.twitter) {
                    social_networks.push('twitter')
                }
                if (this.state.facebook) {
                    social_networks.push('facebook')
                }
                // if (this.state.vkontakte) {
                //     social_networks.push('vkontakte')
                // }
                // if (this.state.tiktok) {
                //     social_networks.push('tiktok')
                // }
                this.props.onChange(social_networks)
            })
        }
    },
    render() {
        return (
            <React.Fragment>
                <CheckboxWithImages className={classnames('checkbox', { disabled: this.disabled('instagram', this.props.event_type) })}
                    obj={this} label={_('inputs.social-networks.instagram')} field_name='instagram' path={social_instagram} />
                <CheckboxWithImages className={classnames('checkbox', { disabled: this.disabled('twitter', this.props.event_type) })}
                    obj={this} label={_('inputs.social-networks.twitter')} field_name='twitter' path={social_twitter} />
                <CheckboxWithImages className={classnames('checkbox', {disabled: this.disabled('facebook', this.props.event_type)})}
                    obj={this} label={_('inputs.social-networks.facebook')} field_name='facebook' path={social_facebook} />
                {/* <CheckboxWithImages className={classnames('checkbox', {disabled: this.disabled('tiktok', this.props.event_type)})}
                    obj={this} label={_('inputs.social-networks.tiktok')} field_name='tiktok' path={social_tiktok} />
                <CheckboxWithImages className={classnames('checkbox', {disabled: this.disabled('vkontakte', this.props.event_type)})}
                    obj={this} label={_('inputs.social-networks.vkontakte')} field_name='vkontakte' path={social_vkontakte} /> */}
            </React.Fragment>)
    }
})

const TagsInput = createReactClass({
    propTypes: {
        tags: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
    },
    getInitialState() {
        return {
            input: ''
        }
    },
    onInput(e) {
        this.setState({ input: e.target.value })
    },
    addTag(tag) {
        if (tag && tag !== '') {
            const processedTag = tag.replace(/ /g, '')
            if (this.props.tags.indexOf(processedTag) === -1) {
                this.props.onChange([...this.props.tags, processedTag])
            }
            this.setState({ input: '' })
        }

    },
    removeTag(tag) {
        this.props.onChange(this.props.tags.filter(t => t !== tag))
    },
    onKeyPress(e) {
        if (e.key === 'Enter') {
            this.addTag(this.state.input)
        }
    },
    onBlur() {
        this.addTag(this.state.input)
    },
    render() {
        return (
            <div className="tags_input">
                <div>
                    {this.props.tags.map((t, i) =>
                        <div className="tag_tabs" key={i}>
                            <span>{i + 1}</span>
                            <div>{t}</div>
                            <span onClick={() => this.removeTag(t)}>x</span>
                        </div>
                    )}
                </div>
                <input value={this.state.input} onChange={this.onInput} onBlur={this.onBlur} onKeyPress={this.onKeyPress} />
            </div>
        )
    }
})

const menuRenderer = ({ options, selectValue }) => (
    <div>
        {options.map((option, index) => (
            <div key={index} onClick={() => selectValue(option)}
                style={{ fontFamily: option.value }} className='FontOption' >{option.label}</div>
        ))}
    </div>)
menuRenderer.propTypes = {
    options: PropTypes.any,
    selectValue: PropTypes.any,
}

export {
    renderField,
    EventTypeInput,
    AbilitiesInput,
    ServicesInput,
    SocialNetworksInput,
    TagsInput
}

