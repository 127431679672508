import React from 'react'
import { connect } from 'react-redux'

import { _ } from '../../helpers/translations'


let ConfirmModalComponent = ({ model, hide }) => (
    <div id="confirmModal" className="___confirm modal">
        <div className="block">
            <div>
                <button className="cancel_modal" type="submit" onClick={() => hide(model, false)}>
                    <div className="circle">«</div>
                    <div>{_('Cancel')}</div>
                </button>
            </div>
            <div className="text">{_('Are you sure?')}</div>
            <div>
                <button className="ok_modal" type="submit" onClick={() => hide(model, true)}>
                    <div className="circle">!</div>
                    <div>{_('Okay')}</div>
                </button>
            </div>
        </div>
    </div>)

let mapStateToProps = (state) => ({
    model: state.modalConfirm
})

let mapDispatchToProps = (dispatch) => ({
    hide(model, isConfirmed) {
        model.callback(isConfirmed)
        dispatch({type: 'MODAL_CONFIRM_HIDE'})
    }
})

ConfirmModalComponent = connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConfirmModalComponent)

export default ConfirmModalComponent