import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import axios from 'axios'

import { updateProject } from '../../actions/project'
import { getProject, formatDate, Explained } from '../../helpers/utils'
import { _ } from '../../helpers/translations'
import { InitializeScroll, SetHeight } from '../../assets/js/common'
import ChangelogTable from '../ChangelogTable'

import * as conf from '../../conf'


const ProjectInfoComponent = createReactClass({
    propTypes: {
        isFetching: PropTypes.bool.isRequired,
        project: PropTypes.any,
        account: PropTypes.any,
    },
    getInitialState() {
        return {
            views_printer: [],
            views_printer_checked: false,
            viewChangelog: false,
            changelog: [],
        }
    },
    componentDidMount() {
        SetHeight()
        InitializeScroll()
        axios.get(`${conf.host_api}/changelog/${this.props.project.uid}`)
            .then(({ data }) => {
                this.setState({ changelog: data })
            })
    },
    componentWillUnmount() {
        this.setState({ views_printer: [], views_printer_checked: false })
    },
    componentWillReceiveProps(nextProps) {
        if (nextProps.project && !this.state.views_printer_checked) {
            axios.get(`${conf.host_api}/views_printer/${nextProps.project.uid}`)
                .then(({ data }) => {
                    this.setState({ views_printer: data, views_printer_checked: true })
                })
        }
    },
    render() {
        const isFetching = this.props.isFetching
        const project = this.props.project
        if (!project) {
            return null
        }
        const projectTypes = {
            1: _('requests.info.type.demo'),
            2: _('requests.info.type.standard')
        }
        const eventTypes = {
            hashtag: _('inputs.event-type.hashtag'),
            mention: _('inputs.event-type.mention'),
            hashtag_or_mention: _('inputs.event-type.hashtag-or-mention'),
            hashtag_and_mention: _('inputs.event-type.hashtag-and-mention'),
            my_page: _('inputs.event-type.my-page'),
            upload_only: _('inputs.event-type.upload-only'),
        }
        const account = this.props.account
        // const expiredTokensWarnings = project.keys.filter(key => key.is_expired).map((key, i) => (
        //     <div key={i} className="alert alert-icon alert-danger border-danger alert-dismissible fade show" role="alert">
        //         <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
        //         <i className="material-icons list-icon">not_interested</i>
        //         Your <strong>{key.resolver}</strong> token has expired. Please reconnect.
        //     </div>))
        project.abilities = project.abilities.map(i => i === 'cloudupload' ? 'Direct Upload' : i.replace(/_/g, ' '))
        return (
            <React.Fragment>
                <div className="box">
                    <div className="layout">
                        <div className="main_title">
                            <h1>{_('requests.info.header')}</h1>
                        </div>
                        <div className="white_panel shadow ptop_90 pleft_225">
                            {/* {expiredTokensWarnings.length > 0 && <div>{expiredTokensWarnings}</div>} */}
                            <table className="profile_data">
                                <tbody>
                                    <tr>
                                        <td className="bullet_circle"><div className="label">{_('requests.info.name')}:</div></td>
                                        <td className="border_with_padding capitalize">{project.name}</td>
                                    </tr>
                                    <tr>
                                        <td className="bullet_circle"><div className="label">{_('requests.info.dates')}:</div></td>
                                        <td className="border_with_padding">
                                            <Explained message={_('explained.project-dates')}>
                                                {formatDate(project.start_date) + ' ~ ' + formatDate(project.end_date)}
                                            </Explained>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bullet_circle"><div className="label">{_('common.services')}:</div></td>
                                        <td className="uppercase border_with_padding">{project.services.join(', ')}</td>
                                    </tr>
                                    <tr>
                                        <td className="bullet_circle"><div className="label">{_('requests.info.event-type')}:</div></td>
                                        <td className="uppercase border_with_padding bold">{eventTypes[project.event_type]}</td>
                                    </tr>
                                    <tr>
                                        <td className="bullet_circle"><div className="label">{_('requests.info.social-networks')}:</div></td>
                                        <td className="uppercase border_with_padding">{project.social_networks.join(', ')}</td>
                                    </tr>
                                    <tr>
                                        <td className="bullet_circle"><div className="label">{_('common.project.main-settings')}:</div></td>
                                        <td className="border_with_padding">
                                            <table className="settings_list">
                                                <tbody>
                                                    {['mention', 'my_page', 'upload_only'].indexOf(project.event_type) === -1 && (
                                                        <tr>
                                                            <td>{_('common.project.main-settings.max-tags')}</td>
                                                            <td>{project.tags_max}</td>
                                                        </tr>)}
                                                    <tr>
                                                        <td>{_('common.project.main-settings.max-posts')}</td>
                                                        <td>{project.posts_max}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{_('common.project.main-settings.max-views')}</td>
                                                        <td>{project.views_max}</td>
                                                    </tr>
                                                    {project.services.indexOf('printer') !== -1 &&
                                                        <tr>
                                                            <td>{_('common.project.max-printers')}</td>
                                                            <td>{project.physical_printers_count}</td>
                                                        </tr>}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    {['mention', 'my_page', 'upload_only'].indexOf(project.event_type) === -1 && (
                                        <tr>
                                            <td className="bullet_circle"><div className="label">{_('common.project.tags')}:</div></td>
                                            <td className="uppercase border_with_padding">{project.tags.map(i => `${i}`).join(', ')}</td>
                                        </tr>)}
                                    {['mention', 'my_page', 'upload_only'].indexOf(project.event_type) === -1 && (
                                        <tr>
                                            <td className="bullet_circle"><div className="label">{_('common.project.tags-and')}:</div></td>
                                            <td className="uppercase border_with_padding">{project.tags_and ? 'Yes' : 'No'}</td>
                                        </tr>)}
                                    <tr>
                                        <td className="bullet_circle"><div className="label">{_('common.project.optional-services')}:</div></td>
                                        <td className="uppercase border_with_padding yellow_tab">{project.abilities.map((a, i) => <div key={i}>{a}</div>)}</td>
                                    </tr>
                                    {/* <tr>
                                    <td className="bullet_circle"><div className="label">{_('common.project.extra')}:</div></td>
                                    <td className="border_with_padding">{project.extra}</td>
                                </tr> */}
                                    <tr>
                                        <td className="bullet_circle"><div className="label">{_('requests.info.request-message')}:</div></td>
                                        <td className="border_with_padding">{project.request_message}</td>
                                    </tr>
                                    <tr>
                                        <td className="bullet_circle"><div className="label">{_('requests.info.status')}:</div></td>
                                        <td className="uppercase border_with_padding">
                                            {project.status === 'activated' ?
                                                <div className="green">{_('requests.info.status.activated')}</div> :
                                                <div className="red">{_('requests.info.status.request')}</div>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bullet_circle"><div className="label">{_('requests.info.type')}:</div></td>
                                        <td className="uppercase border_with_padding">{projectTypes[project.type]}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <div className="mtop_25 res_btn_3">
                                                <Link to={`/accounts/${account.username}/project-requests`} className="gray_btn">{_('requests.info.go-to-list')}</Link>
                                                <Link className="gray_btn mleft_15" to={`/accounts/${account.username}/clients/${project.username}/project-requests/${project.uid}/edit`}>{_('common.edit')}</Link>
                                                {project.status === 'request' && <button className='red_btn mleft_15' onClick={() => this.props.activateProject(project.uid)}>{isFetching ? _('Opening...') : _('requests.info.activate')}</button>}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <p onClick={() => this.setState({ viewChangelog: !this.state.viewChangelog })}>{this.state.viewChangelog ? 'Hide Changelog' : 'View Changelog'}</p>
                            {this.state.viewChangelog && ChangelogTable(this.state.changelog)}
                        </div>
                    </div>
                </div>
            </React.Fragment>)
    }
})


// Container
const mapStateToProps = (state, { params }) => {
    const isFetching = state.admin_accounts.isFetching
    const account = state.account
    const project = getProject(state, params)
    return {
        isFetching,
        account,
        project,
    }
}

const mapDispatchToProps = (dispatch) => ({
    activateProject(projectUID) {
        dispatch(updateProject(projectUID, { status: 'activated' }))
    }
})

const ProjectInfo = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectInfoComponent)

export default ProjectInfo
