import 'babel-polyfill'
import React from 'react'
import { render } from 'react-dom'
import Router from './Router'
import './assets/css/style.css'
import { browserHistory } from 'react-router'
import { SetHeight } from './assets/js/common'
import axios from 'axios'
import * as conf from './conf'
import cookies from './helpers/cookies'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

let env = 'prod'
if (document.domain === 'local-admin.hashsnap.net') {
    env = 'local'
}


axios.interceptors.request.use(config => {
    if (env === 'local') {
        const token = cookies.getItem(conf.local_storage_variables.token + '_token')
        config.headers.Authorization =  token ? `Bearer ${token}` : ''
    }
    return {...config, withCredentials: true}
})

axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response && error.response.status === 401) {
            browserHistory.replace('/sign-out')
        } else {
            throw(error)
        }
    }
)

Sentry.init({
    dsn: 'https://aa8b06238a944250ab2dea26fa75b3c5@o911726.ingest.sentry.io/6451828',
    integrations: [new BrowserTracing()],
    environment: env,
    // tracesSampleRate: 1.0,
})

window.addEventListener('resize', () => {
    SetHeight()
})

render(
  <Router history={browserHistory} />,
  document.getElementById('container')
)
