import React from 'react'
import createReactClass from 'create-react-class'
import PropTypes from 'prop-types'
import axios from 'axios'
import * as conf from '../../conf'

const ChatsFormComponent = createReactClass({
    propTypes: {
        accountUsername: PropTypes.string,
    },
    getInitialState() {
        return {
            chatRoom: null,
            chatTitle: this.props.accountUsername,
            channelKey: '',
            maxUsers: 10,
            googleTranslateAPIKey: '',
            saving: false,
        }
    },
    componentDidMount() {
        axios.get(`${conf.host_api}/vchat/list`).then(res => {
            const chatRooms = res.data.filter(a => a.roomName === this.props.accountUsername && a.roomStatus === 'active')
            if (chatRooms.length > 0) {
                const room = chatRooms[0]
                this.setState({chatRoom:room, channelKey:room.roomId})
            } else {
                this.setState({chatRoom:{}})
            }
        }).catch(err => {
            console.error('Failed to fetch list of chat rooms', err)
            this.setState({chatRoom:'error'})
        })
    },
    componentWillReceiveProps(nextProps) {
        this.setState({chatTitle:nextProps.accountUsername})
    },
    saveRoom() {
        this.setState({saving: true})
        const payload = {
            roomName: this.state.chatTitle,
            maxUsers: this.state.maxUsers
        }
        if (this.state.channelKey) {
            axios.post(`${conf.host_api}/vchat/${this.state.channelKey}`, payload).then(() => {
                this.setState({saving:false})
            })
        } else {
            axios.put(`${conf.host_api}/vchat`, payload).then(res => {
                this.setState({saving:false, channelKey: res.data['roomId']})
            })
        }
      
    },
    render() {
        const spacer = <React.Fragment><div style={{height:20}}></div><div style={{height:20}}></div></React.Fragment>
        return (
          this.state.chatRoom === null ?
            <div>loading...</div> : this.state.chatRoom === 'error' ? <div>Failed to call VChat API</div> : (
            <div style={{display:'grid', columnGap:20, gridTemplateColumns:'max-content auto', rowGap:5, alignItems:'center'}}>
              <div><b>Chat title*</b></div>
              <input type="text" value={this.state.chatTitle} onChange={e => this.setState({chatTitle:e.target.value})} disabled={true} style={{width:'100%'}}/>
              <div></div>
              <div><i>Chat title will match account ID/username</i></div>
              {spacer}
              <div><b>Channel key</b></div>
              <input type="text" value={this.state.channelKey} disabled={true} style={{width:'100%'}} onChange={()=>{}}/>
              <div></div>
              <div><i>This is a unique key that is automatically generated</i></div>
              {spacer}
              <div><b>Max users*</b></div>
              <input type="number" max={10} min={2} value={this.state.maxUsers} onChange={e => this.setState({maxUsers:e.target.value})} style={{width:'100%'}}/>
              <div></div>
              <div><i>The maximum number of people in the chat (min: 2, max: 10)</i></div>
              {spacer}
              <div><b>Google Translate API key</b></div>
              <input type="text" max={10} value={this.state.googleTranslateAPIKey} onChange={e => this.setState({googleTranslateAPIKey:e.target.value})} style={{width:'100%'}}/>
              <div>(Optional)</div>
              <div></div>
              {spacer}
              <div></div>
              <div>{this.state.saving ? <div>processing...</div> : 
                <div className='button' onClick={() => this.saveRoom()}>{this.state.channelKey ? 'Save' : 'Create'}</div>}
              </div>
            </div>)
        )
    }
})

export default ChatsFormComponent