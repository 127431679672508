import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import { connect } from 'react-redux'
import { reduxForm, change, Field } from 'redux-form'

import { get, isNil, isEmpty } from 'lodash'

import { adminCreateAccount, checkUsername } from '../../actions/account'
import { usernameError, emailError, passwordError, requiredError } from '../../helpers/validators'
import { renderField } from '../inputs'

import { _, getLanguages } from '../../helpers/translations'
import countries from '../../helpers/countries'
import { timezones } from '../../helpers/timezones'

import { InitializeScroll, SetHeight } from '../../assets/js/common'
import { FormLegend, Label } from '../../helpers/utils'


const ClientNewComponent = createReactClass({
    propTypes: {
        account: PropTypes.object.isRequired,
        formValues: PropTypes.object,
        change: PropTypes.func,
        handleSubmit: PropTypes.func,
        back: PropTypes.func,
        asyncValidating: PropTypes.any,
        admins: PropTypes.array,
        languages: PropTypes.array,
        isFetching: PropTypes.bool,
    },
    componentDidMount() {
        SetHeight()
        InitializeScroll()
    },
    componentWillReceiveProps(nextProps) {
        if (parseInt(this.props.formValues.type, 10) !== parseInt(nextProps.formValues.type, 10)) {
            const accountType = parseInt(nextProps.formValues.type, 10)
            if (accountType === 1) {
                this.props.change('posts_max', 100)
            } else if (accountType === 2) {
                this.props.change('posts_max', 500)
            } else if (accountType === 3) {
                this.props.change('posts_max', 1000)
            }
        }
    },
    render() {
        const account = this.props.account
        return (
    <React.Fragment>
        <div className="box">
            <div className="layout">
                <div className="main_title">
                    <h1>{_('clients.new.header')}</h1>
                </div>
                <form onSubmit={this.props.handleSubmit}>
                    <fieldset className="mtop_25">
                        <div className="grid_2_first_s">
                            <div>
                                <div className="center profile_intro new">
                                    <div className="index_top">
                                        <div className="profile_name">
                                            <Field name='company' component={renderField} id="company" placeholder={_('clients.new.input.company')}  type='text'></Field>
                                        </div>
                                        <div className="profile_round mtop_25">
                                            <Field name='username' component={renderField} id="username" placeholder={_('clients.new.input.username')}  type='text'></Field>
                                        </div>
                                        <div className="profile_round mtop_15">
                                            <Field name='password' component={renderField} id="password" placeholder={_('clients.new.input.password')}  type='password'></Field>
                                        </div>
                                        {/* <div className="profile_round mtop_15">
                                            <div className="select_arrow">
                                                <Field name='level' component='select' id='level'>
                                                    <option value='1'>{_('common.demo')}</option>
                                                    <option value='2'>{_('common.standard')}</option>
                                                    <option value='3'>{_('common.vip')}</option>
                                                </Field>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="tools">
                                    <div className="adjust">
                                        <div>
                                            <button type='submit'>
                                                {this.props.isFetching ? <div><i className="icon-spinner9 size_25 orange spin"></i></div> : <div className="save_icon icon"></div>}
                                            </button>
                                            <div className="size_13 mtop_5">{_('common.save')}</div>
                                        </div>
                                        <div>
                                            <a href="#" className="round sing" onClick={this.props.back}>
                                                <div className="cancel_icon icon"></div>
                                            </a>
                                            <div className="size_13 mtop_5">{_('common.cancel')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="white_panel">
                                    <FormLegend></FormLegend>
                                    <table className="profile_data">
                                       <tbody>
                                        <tr>
                                            <td><Label required={true}>{_('common.country')}:</Label></td>
                                            <td>
                                                <div className="select_arrow">
                                                    <Field name='country' component='select' id='country'>
                                                        {countries.map((i, n) => <option key={n} value={i.code}>{i.name}</option>)}
                                                    </Field>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Label required={true}>{_('common.timezone')}:</Label></td>
                                            <td>
                                                <div className="select_arrow timezone-select">
                                                    <Field name='timezone' component='select' id='timezone'>
                                                        {timezones.map((i, n) => <option key={n} value={i.value}>{`${i.text} (${i.abbr})`}</option>)}
                                                    </Field>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Label required={false}>{_('clients.new.input.name')}:</Label></td>
                                            <td>
                                                <Field name='name' component={renderField} id="name" placeholder="" type='text'></Field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Label required={false}>{_('common.phone')}:</Label></td>
                                            <td>
                                                <Field name='phone' component={renderField} id="phone" placeholder=""  type='text'></Field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Label required={true}>{_('common.email')}:</Label></td>
                                            <td>
                                                <Field name='email' component={renderField} id="email" placeholder=""  type='email'></Field>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Label required={true}>{_('common.language')}:</Label></td>
                                            <td>
                                                <div className="select_arrow">
                                                    <Field name='language' component='select' id='language'>
                                                        {this.props.languages.map((l, n) => <option key={n} value={l}>{l}</option>)}
                                                    </Field>
                                                </div>
                                            </td>
                                        </tr>
                                        {account.topRole === 'superadmin' &&
                                            <tr>
                                                <td><Label required={true}>{_('common.admin')}:</Label></td>
                                                <td>
                                                    <div className="select_arrow">
                                                        <Field name='admin' component='select' id='admin'>
                                                            {this.props.admins.map((a, n) => <option key={n} value={a.id}>{a.username}</option>)}
                                                        </Field>
                                                    </div>
                                                </td>
                                            </tr>
                                        }

                                       </tbody>
                                    </table>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td><Label required={false}>{_('clients.new.input.extra')}:</Label></td>
                                        </tr>
                                        <tr>
                                            <td><Field name='extra' id="extra" component='textarea' rows="5"></Field></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                     </fieldset>
                </form>
            </div>
        </div>
    </React.Fragment>)
    }
})


// Container
const form = 'client_new'
const validate = (values) => {
    const errors = {}
    if (isNil(values) || isEmpty(values)) {
        return errors
    }
    if (usernameError(values.username)) {
        errors.username = usernameError(values.username)
    }
    if (emailError(values.email)) {
        errors.email = emailError(values.email)
    }
    if (passwordError(values.password)) {
        errors.password = passwordError(values.password)
    }
    if (requiredError(values.company)) {
        errors.company = requiredError(values.company)
    }
    // if (requiredError(values.name)) {
    //     errors.name = requiredError(values.name)
    // }
    // if (phoneError(values.phone)) {
    //     errors.phone = phoneError(values.phone)
    // }
    return errors
}

let ClientNew = reduxForm({
    form,
    enableReinitialize: true,
    validate,
    asyncValidate: checkUsername,
    asyncBlurFields: ['username'],
})(ClientNewComponent)

let mapStateToProps = (state) => {
    const account = state.account
    const initialValues = {
        company: '',
        username: '',
        type: '1',
        country: 'KR',
        timezone: 'Korea Standard Time',
        language: 'kr',
        name: '',
        phone: '',
        email: '',
        admin: account.id,
        is_admin: false,
        extra: '',
    }
    const formValues = get(state, 'form.client_new.values') || initialValues
    const languages = getLanguages()
    return {
        account,
        admins: [account, ...state.admin_accounts.accounts.filter(a => !a.deleted && (a.topRole === 'admin' || a.topRole === 'superadmin'))],
        languages,
        isFetching: state.admin_accounts.isFetching,
        formValues,
        initialValues,
    }
}

let mapDispatchToProps = (dispatch, ownProps) => ({
    onSubmit(data) {
        dispatch(adminCreateAccount(data))
        .then(client => {
            let url = `/accounts/${ownProps.params.account_username}/clients/${client.username}`
            ownProps.router.push(url)
        })
    },
    change(field, value) {
        dispatch(change(form, field, value))
    },
    back() {
        ownProps.router.goBack()
    },
})

ClientNew = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientNew)

export default ClientNew
