import axios from 'axios'
import * as conf from '../conf'
import { _ } from '../helpers/translations'


export const fetchAccount = (username, runOnBackground) => (dispatch, getState) => {
    if (!runOnBackground) {
        dispatch({type: 'REQUEST_ACCOUNT', username})
    }
    if (!username) {
        username = getState().auth.username
    }
    return axios.get(conf.paths.account + '/' + username)
    .then(({ data }) => {
        dispatch({type: 'RECEIVE_ACCOUNT', account: data})
        return data
    })
    .catch(() => {
        const msg = _('Failed to fetch account') + ` ${username}`
        dispatch({type: 'ACCOUNT_ERROR', error: msg})
    })
}

export const saveAccount = (runOnBackground) => (dispatch, getState) => {
  // Saving on background here means that reducers will not be notified,
  // therefore no loaders or confirmation dialogs will be shown.
    const state = getState()
    if (state.account.isFetching) {
        return false
    }
    if (!runOnBackground) {
        dispatch({type: 'REQUEST_SAVE_ACCOUNT'})
    }
    const account = state.account
    return axios.post(conf.paths.account + '/' + account.username, account)
  .then(({ data }) => {
      if (data.status === 0) {
          if (!runOnBackground) {
              dispatch({type: 'RECEIVE_SAVE_ACCOUNT'})
          }
          dispatch({type: 'RECEIVE_ACCOUNT', account: data.data})
      } else {
          dispatch({type: 'SAVE_ACCOUNT_ERROR', error: data.error})
      }
  })
  .catch(ex => {
      const msg = _('Failed to save data') + `: ${ex.message || ex}`
      dispatch({type: 'SAVE_ACCOUNT_ERROR', error: msg})
  })
}

export const updateAccount = (payload) => (dispatch) => {
    dispatch({type: 'UPDATE_ACCOUNT_INFO', payload})
    return dispatch(saveAccount())
}

export const fetchAdminAccounts = (username) => (dispatch) => {
    dispatch({type: 'REQUEST_ADMIN_ACCOUNTS'})
  // In normal operation we ask API to return our sub-accounts, and API figures who we are by our token
    let url = `${conf.paths.account}/${username}/list`
    return axios.get(url)
    .then(({ data }) => {
        dispatch({type: 'RECEIVE_ADMIN_ACCOUNTS', accounts: data})
    })
    .catch(ex => {
        const msg = _('Failed to fetch admin accounts') + `: ${ex.message || ex}`
        dispatch({type: 'ADMIN_ACCOUNTS_ERROR', error: msg})
        throw ex
    })
}

// Used in Admin editor
export const saveAdminAccount = (data) => (dispatch, getState) => {
    const state = getState()
    // Prevent multiple clicks
    if (state.admin_accounts.isFetching) {
        return Promise.reject('Previous submit is still processing')
    }
    dispatch({type: 'REQUEST_SAVE_ADMIN_ACCOUNT'})
    return axios.post(conf.paths.account + '/' + data.username, data)
    .then(({ data }) => {
        if (data.status === 0) {
            dispatch({type: 'RECEIVE_SAVE_ADMIN_ACCOUNT', account: data.data})
        } else {
            dispatch({type: 'SAVE_ADMIN_ACCOUNT_ERROR', error: data.error})
        }
    })
    .catch(ex => {
        const msg = _('Failed to save account') + `: ${ex.message || ex}`
        dispatch({type: 'SAVE_ADMIN_ACCOUNT_ERROR', error: msg})
        throw ex
    })
}

// This is used in AccountManagementNew
export const checkUsername = (values) => {
    return axios.get(conf.paths.account + '/' + values.username + '/available')
  .then(({ data }) => {
      if (data.status !== 0) {
          const error = {username: 'That username is taken'}
          throw error
      }
  })
}

// This is used in AccountsManagementEdit (it treets empty username as correct value)
// export const checkNewUsername = (values) => {
//     if (!values.new_username) {
//         return Promise.resolve()
//     }
//     return axios.get(conf.paths.account + '/' + values.new_username + '/available')
//   .then(({ data }) => {
//       if (data.status !== 0) {
//           const error = {new_username: _('That username is taken')}
//           throw error
//       }
//   })
// }

export const deleteAccount = (username) => (dispatch, getState) => {
    const state = getState()
    // Prevent multiple clicks
    if (state.admin_accounts.isFetching) {
        return Promise.reject('Previous submit is still processing')
    }
    dispatch({type: 'REQUEST_DELETE_ACCOUNT', username})
    return axios.delete(conf.paths.account + '/' + username)
  .then(({ data }) => {
      if (data.status === 0) {
          dispatch({type: 'RECEIVE_DELETE_ACCOUNT', username})
      } else {
          dispatch({type: 'DELETE_ACCOUNT_ERROR', error: data.error})
      }
  })
  .catch(ex => {
      const msg = _('Failed to delete account') + `: ${ex.message || ex}`
      dispatch({type: 'DELETE_ACCOUNT_ERROR', error: msg})
      throw ex
  })
}

export const adminCreateAccount = (data) => (dispatch, getState) => {
    const state = getState()
    // Prevent multiple clicks
    if (state.admin_accounts.isFetching) {
        return Promise.reject('Previous submit is still processing')
    }
    dispatch({type: 'REQUEST_ADMIN_CREATE_ACCOUNT'})
    return axios.put(conf.paths.account, data)
  .then(({ data }) => {
      if (data.status === 0) {
          dispatch({type: 'RECEIVE_ADMIN_CREATE_ACCOUNT', account: data.data})
      } else {
          dispatch({type: 'ADMIN_CREATE_ACCOUNT_ERROR', error: data.error})
      }
      return data.data
  })
  .catch(ex => {
      const msg = _('Failed to create account') + `: ${ex.message || ex}`
      dispatch({type: 'ADMIN_CREATE_ACCOUNT_ERROR', error: msg})
  })
}

