import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import { Link, browserHistory } from 'react-router'
import { connect } from 'react-redux'
import axios from 'axios'

import { getProject, formatDate, Explained } from '../../helpers/utils'
import { _ } from '../../helpers/translations'
import { InitializeScroll, SetHeight } from '../../assets/js/common'
import ChangelogTable from '../ChangelogTable'
import { deleteProject } from '../../actions/project'

import * as conf from '../../conf'


const ProjectInfoComponent = createReactClass({
    propTypes: {
        account: PropTypes.any,
        project: PropTypes.any,
        deleteProject: PropTypes.func,
    },
    getInitialState() {
        return {
            views_printer: [],
            views_printer_checked: false,
            viewChangelog: false,
            changelog: [],
            // backupButtonClicked: false,
        }
    },
    componentDidMount() {
        SetHeight()
        InitializeScroll()
        axios.get(`${conf.host_api}/changelog/${this.props.project.uid}`)
            .then(({ data }) => {
                this.setState({ changelog: data })
            })
    },
    componentWillUnmount() {
        this.setState({ views_printer: [], views_printer_checked: false })
    },
    componentWillReceiveProps(nextProps) {
        if (nextProps.project && !this.state.views_printer_checked) {
            axios.get(`${conf.host_api}/views_printer/${nextProps.project.uid}`)
                .then(({ data }) => {
                    this.setState({ views_printer: data, views_printer_checked: true })
                })
        }
    },
    // backupProject() {
    //     this.props.backupProject(this.props.project)
    // },
    projectStatus(start, end) {
        const now = (new Date()).toISOString()
        let color = '#808080'
        let title = _('projects.list.filters.finished')
        if (now < start) {
            color = '#ffd400'
            title = _('projects.list.filters.upcoming')
        } else if (start <= now && now < end) {
            color = '#81c147'
            title = _('projects.list.filters.ongoing')
        }
        return <div title={title} style={{ color: color }}>{title}</div>
    },
    render() {
        const project = this.props.project
        if (!project) {
            return null
        }
        // const projectTypes = {
        //     1: _('projects.info.types.demo'),
        //     2: _('projects.info.types.standard')
        // }
        // const projectPriorities = {
        //     1: _('projects.info.priorities.high'),
        //     2: _('projects.info.priorities.medium'),
        //     3: _('projects.info.priorities.low')
        // }
        const eventTypes = {
            hashtag: _('inputs.event-type.hashtag'),
            mention: _('inputs.event-type.mention'),
            hashtag_or_mention: _('inputs.event-type.hashtag-or-mention'),
            hashtag_and_mention: _('inputs.event-type.hashtag-and-mention'),
            my_page: _('inputs.event-type.my-page'),
            upload_only: _('inputs.event-type.upload-only'),
        }
        const account = this.props.account
        // const expiredTokensWarnings = project.keys.filter(key => key.is_expired).map((key, i) => (
        //     <div key={i} className="alert alert-icon alert-danger border-danger alert-dismissible fade show" role="alert">
        //         <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
        //         <i className="material-icons list-icon">not_interested</i>
        //         Your <strong>{key.resolver}</strong> token has expired. Please reconnect.
        //     </div>))
        project.abilities = project.abilities.map(i => i === 'cloudupload' ? 'Direct Upload' : i.replace(/_/g, ' '))
        return (
            <React.Fragment>
                <div className="box">
                    <div className="layout">
                        <div className="main_title">
                            <h1>{_('projects.info.header')}</h1>
                        </div>
                        <div className="white_panel shadow ptop_90 pleft_225">
                            {/*{expiredTokensWarnings.length > 0 && <div>{expiredTokensWarnings}</div>}*/}
                            <table className="profile_data">
                                <tbody>
                                    <tr>
                                        <td className="bullet_circle"><div className="label">{_('projects.info.name')}:</div></td>
                                        <td className="border_with_padding capitalize">{project.name}</td>
                                    </tr>
                                    <tr>
                                        <td className="bullet_circle"><div className="label">{_('projects.info.dates')}:</div></td>
                                        <td className="border_with_padding">
                                            <Explained message={_('explained.project-dates')}>
                                                {formatDate(project.start_date) + ' ~ ' + formatDate(project.end_date)}
                                            </Explained>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bullet_circle"><div className="label">{_('common.services')}:</div></td>
                                        <td className="uppercase border_with_padding">{project.services.join(', ')}</td>
                                    </tr>
                                    <tr>
                                        <td className="bullet_circle"><div className="label">{_('projects.info.event-type')}:</div></td>
                                        <td className="uppercase border_with_padding bold">{eventTypes[project.event_type]}</td>
                                    </tr>
                                    {project.event_type !== 'upload_only' && <tr>
                                        <td className="bullet_circle"><div className="label">{_('projects.info.social-networks')}:</div></td>
                                        <td className="uppercase border_with_padding">{project.social_networks.join(', ')}</td>
                                    </tr>}
                                    <tr>
                                        <td className="bullet_circle"><div className="label">{_('common.project.main-settings')}:</div></td>
                                        <td className="border_with_padding">
                                            <table className="settings_list">
                                                <tbody>
                                                    {['mention', 'my_page', 'upload_only'].indexOf(project.event_type) === -1 && (
                                                        <tr>
                                                            <td>{_('common.project.main-settings.max-tags')}</td>
                                                            <td>{project.tags_max}</td>
                                                        </tr>)}
                                                    <tr>
                                                        <td>{_('common.project.main-settings.max-posts')}</td>
                                                        <td>{project.posts_max}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{_('common.project.main-settings.max-views')}</td>
                                                        <td>{project.views_max}</td>
                                                    </tr>
                                                    {project.services.indexOf('printer') !== -1 &&
                                                        <tr>
                                                            <td>{_('common.project.max-printers')}</td>
                                                            <td>{project.physical_printers_count}</td>
                                                        </tr>}
                                                    {project.event_type !== 'upload_only' && project.social_networks.indexOf('instagram') !== -1 &&
                                                        (<tr>
                                                            <td>{_('common.project.main-settings.ig-hashtag-refresh-rate')}</td>
                                                            <td>{project.ig_hashtag_refresh_rate}</td>
                                                        </tr>)}
                                                    {/* {project.social_networks.indexOf('instagram') !== -1 &&
                                                (<tr>
                                                    <td>{_('common.project.main-settings.ig-phototag-refresh-rate')}</td>
                                                    <td>{project.ig_phototag_refresh_rate}</td>
                                                </tr>)}
                                                {project.social_networks.indexOf('instagram') !== -1 &&
                                                (<tr>
                                                    <td>{_('common.project.main-settings.ig-story-refresh-rate')}</td>
                                                    <td>{project.ig_story_refresh_rate}</td>
                                                </tr>)} */}
                                                    {project.event_type !== 'upload_only' && project.social_networks.indexOf('twitter') !== -1 &&
                                                        (<tr>
                                                            <td>{_('common.project.main-settings.tw-hashtag-refresh-rate')}</td>
                                                            <td>{project.tw_hashtag_refresh_rate}</td>
                                                        </tr>)}
                                                    {project.event_type !== 'upload_only' && project.social_networks.indexOf('twitter') !== -1 &&
                                                        (<tr>
                                                            <td>{_('common.project.main-settings.tw-mention-refresh-rate')}</td>
                                                            <td>{project.tw_mention_refresh_rate}</td>
                                                        </tr>)}
                                                    {/* <tr>
                                                    <td>{_('common.project.main-settings.vk-hashtag-refresh-rate')}</td>
                                                    <td>{project.vk_hashtag_refresh_rate}</td>
                                                </tr> */}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    {['mention', 'my_page', 'upload_only'].indexOf(project.event_type) === -1 && (
                                        <tr>
                                            <td className="bullet_circle"><div className="label">{_('common.project.tags')}:</div></td>
                                            <td className="uppercase border_with_padding">{project.tags.map(i => `${i}`).join(', ')}</td>
                                        </tr>)}
                                    {['mention', 'my_page', 'upload_only'].indexOf(project.event_type) === -1 && (
                                        <tr>
                                            <td className="bullet_circle"><div className="label">{_('common.project.condition')}:</div></td>
                                            <td className="uppercase border_with_padding">{project.tags_and ? _('common.project.tags-and') : _('common.project.tags-or')}</td>
                                        </tr>)}
                                    {/* <tr>
                                    <td className="bullet_circle"><div className="label">{_('common.project.optional-services')}:</div></td>
                                    <td className="uppercase border_with_padding yellow_tab">
                                        {project.abilities
                                            .filter(a => ['advanced moderation', 'custom styles', 'Direct Upload'].indexOf(a) === -1)
                                            .map((a, i) => <div key={i}>{a}</div>)
                                        }
                                    </td>
                                </tr> */}
                                    {/* <tr>
                                    <td className="bullet_circle"><div className="label">{_('common.project.crm-link')}:</div></td>
                                    <td className="border_with_padding"><a href={project.crm_link} target="_blank">{project.crm_link}</a></td>
                                </tr> */}
                                    {/* <tr>
                                    <td className="bullet_circle"><div className="label">{_('common.project.extra')}:</div></td>
                                    <td className="border_with_padding">{project.extra}</td>
                                </tr> */}
                                    {project.event_type !== 'upload_only' && <tr>
                                        <td className="bullet_circle"><div className="label">{_('common.project.parse-usernames')}:</div></td>
                                        <td className="uppercase border_with_padding">{project.parse_usernames ? _('yes') : _('no')}</td>
                                    </tr>}
                                    <tr>
                                        <td className="bullet_circle"><div className="label">{_('common.project.parse-location')}:</div></td>
                                        <td className="uppercase border_with_padding">{project.parse_location ? _('yes') : _('no')}</td>
                                    </tr>
                                    <tr>
                                        <td className="bullet_circle"><div className="label">{_('common.project.parse-sentiment')}:</div></td>
                                        <td className="uppercase border_with_padding">{project.parse_sentiment ? _('yes') : _('no')}</td>
                                    </tr>
                                    <tr>
                                        <td className="bullet_circle"><div className="label">{_('projects.info.status')}:</div></td>
                                        <td className="uppercase border_with_padding">{this.projectStatus(project.start_date, project.end_date)}</td>
                                    </tr>
                                    <tr>
                                        <td className="bullet_circle"><div className="label">{_('common.manager')}:</div></td>
                                        <td className="uppercase border_with_padding">{project.admin}</td>
                                    </tr>
                                    {/* <tr>
                                    <td className="bullet_circle"><div className="label">{_('projects.info.type')}:</div></td>
                                    <td className="uppercase border_with_padding">{projectTypes[project.type]}</td>
                                </tr> */}
                                    {/* <tr>
                                    <td className="bullet_circle"><div className="label">{_('projects.info.priority')}:</div></td>
                                    <td className="uppercase border_with_padding">{projectPriorities[project.priority]}</td>
                                </tr> */}
                                    {/* "Request backup" is not needed anymore, because we save all post media to S3 by default now */}
                                    {/* {this.props.account.topRole === 'superadmin' &&
                                <tr>
                                    <td className="bullet_circle"><div className="label">{_('projects.info.backup')}:</div></td>
                                    <td className="uppercase border_with_padding" style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                        <span style={{marginRight:20}}>{(project.backup_request === null || project.backup_request === '') && 'Never requested' || project.backup_request}</span>
                                        {this.state.backupButtonClicked &&
                                            <div style={{display:'flex', flexDirection:'row'}}>
                                                <span style={{marginRight:20,marginTop:3,marginLeft:20}}>Are you sure? This action is irreversible.</span>
                                                <button style={{marginRight:20,backgroundColor:'red',border:'none',color:'white'}} onClick={() => this.backupProject()}>Yes</button>
                                                <button style={{border:'none',backgroundColor:'grey',color:'white'}} onClick={() => this.setState({backupButtonClicked: false})}>No</button>
                                            </div> ||
                                            <button onClick={() => this.setState({backupButtonClicked: true})} type="button"><span aria-hidden="true">Request backup</span></button>
                                        }
                                    </td>
                                </tr>} */}
                                    <tr>
                                        <td></td>
                                        <td>
                                            <div className="mtop_25 project_info_btn">
                                                <Link className="gray_btn" to={`/accounts/${account.username}/clients/${project.username}/projects/${project.uid}/edit`}>{_('projects.info.edit')}</Link>
                                                <a className="gray_btn mleft_15" target="_blank" href={`${conf.host_client}/accounts/${project.username}/projects/${project.uid}`}>{_('common.login-as')}</a>
                                                <button className="gray_btn mleft_15" onClick={() => this.props.deleteProject(project.username, project.uid)}>{_('projects.info.delete')}</button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <p onClick={() => this.setState({ viewChangelog: !this.state.viewChangelog })}>{this.state.viewChangelog ? 'Hide Changelog' : 'View Changelog'}</p>
                            {this.state.viewChangelog && ChangelogTable(this.state.changelog)}
                        </div>
                    </div>
                </div>
            </React.Fragment>)
    }
})


// Container
const mapStateToProps = (state, { params }) => {
    const account = state.account
    const project = getProject(state, params)
    return {
        account,
        project,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    deleteProject(account, project_uid) {
        dispatch({
            type: 'MODAL_DELETE_SHOW', callback: (isDeleted) => {
                if (isDeleted) {
                    dispatch(deleteProject(project_uid))
                    browserHistory.push(`/accounts/${ownProps.params.account_username}/clients/${account}`)
                }
            }
        })
    }
})

const ProjectInfo = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectInfoComponent)

export default ProjectInfo
