/* global $ */
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import createReactClass from 'create-react-class'
import { reduxForm, change, Field } from 'redux-form'

import { isNil, isEmpty } from 'lodash'

import { ServicesInput, SocialNetworksInput, TagsInput, EventTypeInput, renderField } from '../inputs'

import { updateProject } from '../../actions/project'
import { requiredError } from '../../helpers/validators'

import { _ } from '../../helpers/translations'
import { Explained, getProject } from '../../helpers/utils'

import { InitializeScroll, SetHeight } from '../../assets/js/common'


const ProjectEditComponent = createReactClass({
    propTypes: {
        account: PropTypes.any,
        project: PropTypes.any,
        formValues: PropTypes.object,
        change: PropTypes.func,
        handleSubmit: PropTypes.func,
    },
    initPlugins() {
        SetHeight()
        InitializeScroll()

        $('#daterange').daterangepicker({
            autoUpdateInput: false,
            alwaysShowCalendars: true,
            showCustomRangeLabel: false,
            showDropdowns: true,
            ranges: {
                [_('common.daterange.today')]: [moment(), moment()],
                // [_('common.daterange.tomorrow')]: [moment().add(1, 'days'), moment().add(1, 'days')],
                // [_('common.daterange.next-2-days')]: [moment(), moment().add(1, 'days')],
                // [_('common.daterange.next-3-days')]: [moment(), moment().add(2, 'days')],
                // [_('common.daterange.next-4-days')]: [moment(), moment().add(3, 'days')],
                // [_('common.daterange.next-5-days')]: [moment(), moment().add(4, 'days')],
                [_('common.daterange.next-7-days')]: [moment(), moment().add(6, 'days')],
                // [_('common.daterange.next-30-days')]: [moment(), moment().add(29, 'days')],
            },
        })
        $('#daterange').on('apply.daterangepicker', (ev, picker) => {
            $('#daterange span').html(picker.startDate.format('MMM D, YY') + ' ~ ' + picker.endDate.format('MMM D, YY'))
            const startDate = picker.startDate.format('YYYY-MM-DD')
            const endDate = picker.endDate.format('YYYY-MM-DD')
            this.props.change('start_date', startDate)
            this.props.change('end_date', endDate)
        })
        $('#daterange span').html(
            moment(this.props.formValues.start_date).format('MMM D, YY')
            + ' ~ ' +
            moment(this.props.formValues.end_date).format('MMM D, YY'))
    },
    componentDidMount() {
        this.initPlugins()
    },
    componentDidUpdate() {
        this.initPlugins()
    },
    onChangeTags(tags) {
        if (this.props.formValues.tags_max >= tags.length) {
            this.props.change('tags', tags)
        }
    },
    projectStatus(start, end) {
        const now = (new Date()).toISOString()
        let color = '#808080'
        let title = _('projects.list.filters.finished')
        if (now < start) {
            color = '#ffd400'
            title = _('projects.list.filters.upcoming')
        } else if (start <= now && now < end) {
            color = '#81c147'
            title = _('projects.list.filters.ongoing')
        }
        return <div title={title} style={{ color: color }}>{title}</div>
    },
    render() {
        const project = this.props.project
        if (!project) {
            return null
        }
        const projectServices = this.props.formValues.services || []
        // const projectAbilities = this.props.formValues.abilities || []
        const projectSocialNetworks = this.props.formValues.social_networks || []
        return (
            <React.Fragment>
                <div className="box">
                    <div className="layout">
                        <div className="main_title">
                            <h1>{_('projects.edit.header')}</h1>
                        </div>
                        <div className="white_panel shadow ptop_90 pleft_225 __projects_form">
                            <form onSubmit={this.props.handleSubmit}>
                                <fieldset>
                                    <table className="profile_data">
                                        <tbody>
                                            <tr>
                                                <td className="bullet_circle"><div className="label">{_('projects.edit.input.name')}:</div></td>
                                                <td className="border_with_padding">
                                                    <Field name='name' className="project_name_input" component={renderField} id="name" type='text'></Field>
                                                </td>
                                            </tr>
                                            <tr className="project-edit-tr-project_dates">
                                                <td className="bullet_circle"><div className="label">{_('projects.edit.input.dates')}:</div></td>
                                                <td className="border_with_padding">
                                                    <Explained message={_('explained.project-dates')}>
                                                        <div className='daterange_icon'>
                                                            <div className='icon_control'>
                                                                <i className='icon-calendar'></i>
                                                            </div>
                                                            <div id="daterange" className="daterange_request">
                                                                <i className="fa fa-calendar"></i>
                                                                <span></span>
                                                                <i className="fa fa-caret-down"></i>
                                                            </div>
                                                        </div>
                                                    </Explained>
                                                </td>
                                            </tr>
                                            <tr className="project-edit-tr-services">
                                                <td className="bullet_circle"><div className="label">{_('projects.edit.input.services')}:</div></td>
                                                <td className="border_with_padding">
                                                    <div className="checkbox_round uppercase">
                                                        <ServicesInput
                                                            services={projectServices}
                                                            onChange={(services) => this.props.change('services', services)} />
                                                    </div>
                                                    {projectServices.indexOf('printer') !== -1 &&
                                                        <div className="with_label mtop_25">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="label">{_('common.project.max-printers')}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="select_arrow select_request">
                                                                                <Field name='physical_printers_count' component='select' id='physical_printers_count'>
                                                                                    <option value='1'>{_('common.project.max-printers.1')}</option>
                                                                                    <option value='2'>{_('common.project.max-printers.2')}</option>
                                                                                    <option value='3'>{_('common.project.max-printers.3')}</option>
                                                                                    <option value='4'>{_('common.project.max-printers.4')}</option>
                                                                                    <option value='5'>{_('common.project.max-printers.5')}</option>
                                                                                </Field>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    }
                                                </td>
                                            </tr>
                                            <tr className="project-edit-tr-main-settings">
                                                <td className="bullet_circle"><div className="label">{_('common.project.main-settings')}:</div></td>
                                                <td className="border_with_padding">
                                                    <EventTypeInput
                                                        value={this.props.formValues.event_type}
                                                        onChange={(event_type) => this.props.change('event_type', event_type)} />
                                                    {['upload_only'].indexOf(this.props.formValues.event_type) === -1 && <div className="social_select request_project_social_select">
                                                        <SocialNetworksInput
                                                            social_networks={projectSocialNetworks}
                                                            event_type={this.props.formValues.event_type}
                                                            onChange={(social_networks) => this.props.change('social_networks', social_networks)} />
                                                    </div>}
                                                    {['mention', 'my_page', 'upload_only'].indexOf(this.props.formValues.event_type) === -1 && (
                                                        <div className="with_label settings_request1">
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="label">{_('common.project.main-settings.max-tags')}</div>
                                                                        </td>
                                                                        <td className="table_input">
                                                                            <Field name='tags_max' component={renderField} id='tags_max' type="number" />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>)}
                                                    {/* <div className="with_label no_bg settings_request2">
                                                    <table className="settings_input">
                                                        <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="label">{_('common.project.main-settings.ig-update-speed')}</div>
                                                            </td>
                                                            <td className="table_input left settings_input1">
                                                                <div className="table_txt">{'' + this.props.formValues.ig_hashtag_refresh_rate * this.props.formValues.tags_max}</div>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div> */}
                                                    <div className="with_label settings_request3">
                                                        <table className="settings_input">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div className="label">{_('common.project.main-settings.max-posts')}</div>
                                                                    </td>
                                                                    <td className="table_input">
                                                                        <Field name='posts_max' component={renderField} id='posts_max' type='number' />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="with_label settings_request2">
                                                        <table className="settings_input">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div className="label">{_('common.project.main-settings.max-views')}</div>
                                                                    </td>
                                                                    <td className="table_input">
                                                                        <Field name='views_max' component={renderField} id='views_max' type='number' />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {(['upload_only'].indexOf(this.props.formValues.event_type) === -1 && this.props.formValues.social_networks && this.props.formValues.social_networks.indexOf('instagram') !== -1) &&
                                                        (<div className="with_label settings_request3">
                                                            <table className="settings_input">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="label">{_('common.project.main-settings.ig-hashtag-refresh-rate')}</div>
                                                                        </td>
                                                                        <td className="table_input">
                                                                            <Field name='ig_hashtag_refresh_rate' component={renderField} id='ig_hashtag_refresh_rate' type='number' />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>)}
                                                    {(this.props.formValues.social_networks && this.props.formValues.social_networks.indexOf('instagram') !== -1) &&
                                                        (<div className="with_label settings_request3">
                                                            <table className="settings_input">
                                                                <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div className="label">{_('common.project.main-settings.ig-phototag-refresh-rate')}</div>
                                                                    </td>
                                                                    <td className="table_input">
                                                                        <Field name='ig_phototag_refresh_rate' component={renderField} id='ig_phototag_refresh_rate' type='number' />
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>)}
                                                    {/* {(this.props.formValues.social_networks && this.props.formValues.social_networks.indexOf('instagram') !== -1) &&
                                                        (<div className="with_label settings_request3">
                                                            <table className="settings_input">
                                                                <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div className="label">{_('common.project.main-settings.ig-story-refresh-rate')}</div>
                                                                    </td>
                                                                    <td className="table_input">
                                                                        <Field name='ig_story_refresh_rate' component={renderField} id='ig_story_refresh_rate' type='number' />
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>)} */}
                                                    {(this.props.formValues.social_networks && this.props.formValues.social_networks.indexOf('twitter') !== -1) &&
                                                        (<div className="with_label settings_request3">
                                                            <table className="settings_input">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="label">{_('common.project.main-settings.tw-hashtag-refresh-rate')}</div>
                                                                        </td>
                                                                        <td className="table_input">
                                                                            <Field name='tw_hashtag_refresh_rate' component={renderField} id='tw_hashtag_refresh_rate' type='number' />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>)}
                                                    {(this.props.formValues.social_networks && this.props.formValues.social_networks.indexOf('twitter') !== -1) &&
                                                        (<div className="with_label settings_request3">
                                                            <table className="settings_input">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="label">{_('common.project.main-settings.tw-mention-refresh-rate')}</div>
                                                                        </td>
                                                                        <td className="table_input">
                                                                            <Field name='tw_mention_refresh_rate' component={renderField} id='tw_mention_refresh_rate' type='number' />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>)}
                                                    {/* <div className="with_label settings_request3">
                                                    <table className="settings_input">
                                                        <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="label">{_('common.project.main-settings.vk-hashtag-refresh-rate')}</div>
                                                            </td>
                                                             <td className="table_input">
                                                                <Field name='vk_hashtag_refresh_rate' component={renderField} id='vk_hashtag_refresh_rate' type='number' />
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div> */}
                                                </td>
                                            </tr>
                                            {['mention', 'my_page', 'upload_only'].indexOf(this.props.formValues.event_type) === -1 && (
                                                <tr>
                                                    <td></td>
                                                    <td className="border_with_padding">
                                                        <div className="label" style={{ marginBottom: 10 }}>{_('common.project.tags')}</div>
                                                        <div className="table_hashtag_input">
                                                            <div className="icon_control"><div>#</div></div>
                                                            <TagsInput tags={this.props.formValues.tags || []} onChange={this.onChangeTags} />
                                                        </div>
                                                        <div className="social_select" style={{ marginTop: '20px' }}>
                                                            <div>
                                                                <Field name='tags_condition' className="styled-checkbox" component='input' id='tags_or' value='tags_or' type='radio'></Field>
                                                                <label htmlFor="tags_or">{_('common.project.tags-or')}</label>
                                                            </div>
                                                            <div>
                                                                <Field name='tags_condition' className="styled-checkbox" component='input' id='tags_and' value='tags_and' type='radio'></Field>
                                                                <label htmlFor="tags_and">{_('common.project.tags-and')}</label>
                                                                <div style={{ transform: 'translate(135px, -28px)' }}>
                                                                    <Explained message={_('explained.and-condition')}></Explained>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>)}
                                            {['upload_only'].indexOf(this.props.formValues.event_type) === -1 && <tr>
                                                <td></td>
                                                <td className="border_with_padding social_select">
                                                    <input type="checkbox" id="parse_usernames" className='styled-checkbox' checked={this.props.formValues.parse_usernames ? true : false} onChange={(e) => this.props.change('parse_usernames', e.target.checked)} />
                                                    <label className="label-text" htmlFor="parse_usernames">{_('common.project.parse-usernames')}</label>
                                                </td>
                                            </tr>}
                                            <tr>
                                                <td></td>
                                                <td className="border_with_padding social_select">
                                                    <input type="checkbox" id="parse_location" className='styled-checkbox' checked={this.props.formValues.parse_location ? true : false} onChange={(e) => this.props.change('parse_location', e.target.checked)} />
                                                    <label className="label-text" htmlFor="parse_location">{_('common.project.parse-location')}</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className="border_with_padding social_select">
                                                    <input type="checkbox" id="parse_sentiment" className='styled-checkbox' checked={this.props.formValues.parse_sentiment ? true : false} onChange={(e) => this.props.change('parse_sentiment', e.target.checked)} />
                                                    <label className="label-text" htmlFor="parse_sentiment">{_('common.project.parse-sentiment')}</label>
                                                </td>
                                            </tr>
                                            {/* <tr>
                                            <td className="bullet_circle"><div className="label">{_('common.project.optional-services')}:</div></td>
                                            <td className="border_with_padding">
                                                <div className="checkbox_tab">
                                                    <AbilitiesInput
                                                    abilities={projectAbilities}
                                                    onChange={(abilities) => this.props.change('abilities', abilities)} />
                                                </div>
                                            </td>
                                        </tr> */}
                                            {/* <tr>
                                            <td className="bullet_circle"><div className="label">{_('common.project.crm-link')}:</div></td>
                                            <td className="border_with_padding">
                                                <Field name='crm_link' className="project_crm_link_input" component={renderField} id="crm_link" type='text'></Field>
                                            </td>
                                        </tr> */}
                                            {/* <tr>
                                            <td className="bullet_circle"><div className="label">{_('common.project.extra')}:</div></td>
                                            <td className="border_with_padding">
                                                <Field name='extra' className="project_extra_input" component={renderField} id="extra" type='text'></Field>
                                            </td>
                                        </tr> */}
                                            <tr>
                                                <td className="bullet_circle"><div className="label">{_('projects.info.status')}:</div></td>
                                                <td className="uppercase border_with_padding">{this.projectStatus(project.start_date, project.end_date)}</td>
                                            </tr>
                                            <tr>
                                                <td className="bullet_circle"><div className="label">{_('common.manager')}:</div></td>
                                                <td className="uppercase border_with_padding">{project.admin}</td>
                                            </tr>
                                            {/* <tr>
                                            <td className="bullet_circle"><div className="label">{_('projects.edit.priority')}:</div></td>
                                            <td className="border_with_padding">
                                                <Field name="priority" component="select" id="priority">
                                                    <option value="1">{_('projects.info.priorities.high')}</option>
                                                    <option value="2">{_('projects.info.priorities.medium')}</option>
                                                    <option value="3">{_('projects.info.priorities.low')}</option>
                                                </Field>
                                            </td>
                                        </tr> */}
                                            <tr>
                                                <td></td>
                                                <td>
                                                    <div className="mtop_25 project_info_btn">
                                                        <a href="#" className="gray_btn" type="button" onClick={this.props.back}>{_('common.cancel')}</a>
                                                        <a href="#" className="red_btn mleft_15" type="submit" onClick={this.props.handleSubmit}>{_('common.save')}</a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>)
    }
})


// Container
const form = 'project_edit'
const validate = (values) => {
    const errors = {}
    if (isNil(values) || isEmpty(values)) {
        return errors
    }
    if (requiredError(values.name)) {
        errors.name = requiredError(values.name)
    }
    if (values.services.length === 0) {
        errors.services = _('common.validation-erros.select-at-least-one-service')
    }
    if (values.social_networks.length === 0) {
        errors.social_networks = _('common.validation-erros.select-at-least-one-social-network')
    }
    if (requiredError(values.start_date)) {
        errors.start_date = requiredError(values.start_date)
    }
    if (requiredError(values.end_date)) {
        errors.end_date = requiredError(values.end_date)
    }
    if (values.start_date && values.end_date && values.start_date >= values.end_date) {
        errors.start_date = _('common.validation-erros.start-date-must-be-before-end-date')
    }
    return errors
}

let ProjectEdit = reduxForm({
    form,
    enableReinitialize: true,
    validate,
})(ProjectEditComponent)

let mapStateToProps = (state, { params }) => {
    const account = state.account
    const project = getProject(state, params)
    const formValues = ((state.form || {}).project_edit || {}).values || {}
    const props = {
        account,
        project,
        formValues
    }
    if (project) {
        props.initialValues = {
            uid: project.uid,
            name: project.name,
            event_type: project.event_type,
            services: project.services,
            type: project.type,
            priority: project.priority,
            abilities: project.abilities,
            social_networks: project.social_networks,
            start_date: project.start_date,
            end_date: project.end_date,
            tags: project.tags,
            tags_condition: project.tags_and ? 'tags_and' : 'tags_or',
            language: project.language,
            posts_max: project.posts_max,
            tags_max: project.tags_max,
            views_max: project.views_max,
            ig_hashtag_refresh_rate: project.ig_hashtag_refresh_rate,
            ig_phototag_refresh_rate: project.ig_phototag_refresh_rate,
            ig_story_refresh_rate: project.ig_story_refresh_rate,
            tw_hashtag_refresh_rate: project.tw_hashtag_refresh_rate,
            tw_mention_refresh_rate: project.tw_mention_refresh_rate,
            vk_hashtag_refresh_rate: project.vk_hashtag_refresh_rate,
            status: project.status,
            crm_link: project.crm_link,
            extra: project.extra,
            physical_printers_count: project.physical_printers_count,
            parse_usernames: project.parse_usernames,
            parse_location: project.parse_location,
            parse_sentiment: project.parse_sentiment,
        }
    }
    return props
}

let mapDispatchToProps = (dispatch, ownProps) => ({
    onSubmit(data) {
        if (data.services.length === 0) {
            dispatch({
                type: 'MODAL_ALERT_SHOW',
                level: 'error',
                message: _('common.modal-alert.enter-at-least-one-service'),
            })
            return
        }
        if (data.social_networks.length === 0) {
            dispatch({
                type: 'MODAL_ALERT_SHOW',
                level: 'error',
                message: _('common.modal-alert.select-social-platform'),
            })
            return
        }
        if (data.tags.length === 0 && ['mention', 'my_page', 'upload_only'].indexOf(data.event_type) === -1) {
            dispatch({
                type: 'MODAL_ALERT_SHOW',
                level: 'error',
                message: _('common.modal-alert.enter-at-least-one-hashtag'),
            })
            return
        }
        if (data.tags_condition === 'tags_and') {
            data.tags_and = true
        } else {
            data.tags_and = false
        }
        data.posts_max = parseInt(data.posts_max, 10)
        data.tags_max = parseInt(data.tags_max, 10)
        data.views_max = parseInt(data.views_max, 10)
        data.ig_hashtag_refresh_rate = parseInt(data.ig_hashtag_refresh_rate, 10)
        data.ig_phototag_refresh_rate = parseInt(data.ig_phototag_refresh_rate, 10)
        data.ig_story_refresh_rate = parseInt(data.ig_story_refresh_rate, 10)
        data.tw_hashtag_refresh_rate = parseInt(data.tw_hashtag_refresh_rate, 10)
        data.tw_mention_refresh_rate = parseInt(data.tw_mention_refresh_rate, 10)
        data.vk_hashtag_refresh_rate = parseInt(data.vk_hashtag_refresh_rate, 10)
        data.physical_printers_count = parseInt(data.physical_printers_count, 10)
        dispatch(updateProject(ownProps.params.project_uid, data))
            .then(() => {
                ownProps.router.goBack()
            })
    },
    back() {
        ownProps.router.goBack()
    },
    change(field, value) {
        console.log('>>>>>>>>>>', field, value)
        dispatch(change(form, field, value))
    },
})

ProjectEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectEdit)

export default ProjectEdit
