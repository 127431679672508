import React from 'react'
import createReactClass from 'create-react-class'
import moment from 'moment'
import { find } from 'lodash'
import { _ } from '../helpers/translations'


export const fromNow = (date) => {
    if (!date || date.startsWith('1970')) {
        return null
    } else {
        return moment(date).fromNow()
    }
}

export const formatDate = (date) => {
    if (!date || date.startsWith('1970')) {
        return null
    } else {
        return new Date(date).toISOString().slice(0, 10)
    }
}

export const sortAndKeepNullsAtTheEnd = (inputList, key, asc) => {
    let outputList = [...inputList]
    outputList.sort((a, b) => {
        if (!a[key] || (typeof a[key] === 'string' && a[key].startsWith('1970'))) {
            return 1
        }
        if (!b[key] || (typeof b[key] === 'string' && b[key].startsWith('1970'))) {
            return -1
        }
        if (a[key] > b[key]) {
            return asc ? 1 : -1
        }
        if (a[key] < b[key]) {
            return asc ? -1 : 1
        }
        return 0
    })
    return outputList
}

export const topRole = (roles) => {
    if (roles.includes('ROLE_SUPERADMIN')) {
        return 'superadmin'
    } else if (roles.includes('ROLE_ADMIN')) {
        return 'admin'
    }
    return 'user'
}

export const getProject = (state, params) => {
    const accounts = [state.account, ...state.admin_accounts.accounts]
    if (state.admin_accounts && state.admin_accounts.accounts) {
        const projects = state.admin_accounts.accounts
            .reduce((agg, a) => [
                ...agg,
                ...a.projects.map(p => ({
                    ...p,
                    username: a.username,
                    admin: (find(accounts, i => i.id === a.admin_id) || {}).username,
                    views: [],
                }))
            ], [])
        const project = find(projects, p => p.uid === params.project_uid)
        return project
    }
}

export const getRouteName = (pathname) => {
    if (pathname.match(/^\/accounts\/[a-zA-Z0-9_-]+\/projects$/)) {
        return 'projects'
    } else if (pathname.match(/^\/accounts\/[a-zA-Z0-9_-]+\/admins$/)) {
        return 'admins'
    } else if (pathname.match(/^\/accounts\/[a-zA-Z0-9_-]+\/clients$/) || pathname.match(/^\/accounts\/[a-zA-Z0-9_-]+\/clients\/[^/]+$/)) {
        return 'clients'
    } else if (pathname.match(/^\/accounts\/[a-zA-Z0-9_-]+\/translations/)) {
        return 'translations'
    } else if (pathname.match(/^\/accounts\/[a-zA-Z0-9_-]+\/project-requests$/)) {
        return 'project-requests'
    } else if (pathname.match(/^\/accounts\/[a-zA-Z0-9_-]+\/projects\/[^/]+$/)) {
        return 'project-info'
    } else if (pathname.match(/^\/accounts\/[a-zA-Z0-9_-]+\/projects\/[^/]+\/settings$/)) {
        return 'project-settings'
    } else if (pathname.match(/^\/accounts\/[a-zA-Z0-9_-]+\/projects\/[^/]+\/moderation$/)) {
        return 'project-moderation'
    } else if (pathname.match(/^\/accounts\/[a-zA-Z0-9_-]+\/projects\/[^/]+\/dashboard$/)) {
        return 'project-dashboard'
    } else if (pathname.match(/^\/accounts\/[a-zA-Z0-9_-]+\/projects\/[^/]+\/active-screens$/)) {
        return 'project-active-screens'
    } else if (pathname.match(/^\/accounts\/[a-zA-Z0-9_-]+\/clients\/[^/]+$/)) {
        return 'clients'
    } else if (pathname.match(/^\/accounts\/[a-zA-Z0-9_-]+\/clients\/[^/]+\/edit$/)) {
        return 'clients'
    }
    return ''
}

export const Label = createReactClass({
    render() {
        return <div className="label" style={{ display: 'flex' }}>
            {this.props.children}
            {this.props.required ? <span style={{ color: 'red' }}>*</span> : null}
        </div>
    }
})

export const Explained = createReactClass({
    getInitialState() {
        return {
            showMessage: false
        }
    },
    render() {
        return <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute' }}>
            <div style={{ whiteSpace: 'nowrap', flexShrink: 0 }}>{this.props.children}</div>
            <div style={{
                flexShrink: 0,
                marginLeft: 10,
                width: 15,
                height: 15,
                borderRadius: 15,
                backgroundColor: 'grey',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: 4,
                paddingLeft: 1
            }}
                onMouseEnter={() => this.setState({ showMessage: true })}
                onMouseLeave={() => this.setState({ showMessage: false })}>&#8505;</div>
            {this.state.showMessage && <div style={{
                position: 'relative',
                top: 0,
                left: 5,
                zIndex: 10,
                width: 400,
                padding: '10px 15px',
                borderRadius: 5,
                backgroundColor: 'lightgrey',
                color: 'white'
            }} dangerouslySetInnerHTML={{ __html: this.props.message }}></div>}
        </div>
    }
})

export const FormLegend = createReactClass({
    render() {
        return <div style={{ fontSize: 13, display: 'flex', justifyContent: 'end' }}>
            <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}><span style={{ color: 'red' }}>*</span>{_('common.form.required-input')}</div>
        </div>
    }
})