// When develop = true, the store is saved to localStorage on each update, and is read from localStorage on app load.
export const develop = false
export const local_storage_variables = {
    token: '_hsjwt',
    state: '_hsstate',
    language: '_hslang',
    admin_login_as_username: '_hsloginas',
}

let protocol = 'https:'
export let env = 'local'
export let host_client = `${protocol}//client-v2.hashsnap.net`
export let host_admin = `${protocol}//admin.hashsnap.net`
export let host_api = `${protocol}//api.hashsnap.net`
export let host_utils = `${protocol}//api.hashsnap.net/v2`
export let host_views = `${protocol}//views.hashsnap.net`
export let host_auth = `${protocol}//auth.hashsnap.net`
export let host_printer = `${protocol}//printer.hashsnap.net`

export const paths = {
    root: host_api,
    login: host_api + '/login_check',
    account: host_api + '/accounts',
    account_groups: host_api + '/account-groups',
    restore_password: host_api + '/reset-password',
    post: host_api + '/posts',
    posts_api: host_api + '/posts',
    translations: host_api + '/translations',
    s3: 'https://s3.ap-northeast-2.amazonaws.com/hashsnap-reports/data-export',
}

export const pool_conf = {
    pool_limit: 10000,
    page_size: 100,
    min_posts_before_ready: 25,
    min_before_end: 30,
    looping: false,
}

export const firebase = {
    api_key: 'AIzaSyD7mxVAIZQ4-Dl7Km_K6XDq9vcnqy5Gtvo',
    database_url: 'https://awesomewall-e1f59.firebaseio.com/',
}

export const instagram = {
  // client_id: '1f67e44b87714a2a893a0a1000b514b6', // sandbox
    client_id: '810b2c7516d742428d06c61a235216d2', // production
}

