import React from 'react'
import { connect } from 'react-redux'
import createReactClass from 'create-react-class'
import { _ } from '../helpers/translations'
import axios from 'axios'
import * as conf from '../conf'
import {sortBy} from 'lodash'

const emptyKioskForm = {
    id: null,
    name: '',
    windows_cd_key: '',
    printer_model: '',
    printer_serial_number: '',
    location: '',
    rent_or_sold: '-',
    client_id: '-',
    comment: '',
}

const emptyKioskConnectionForm = {
    kiosk_uid: '',
    view_uid: '-',
}

let KiosksComponent = createReactClass({
    getInitialState() {
        return {
            loading: true,
            kiosks: [],
            views: [],
            connections: [],
            showKioskPopup: false,
            showConnectionPopup: false,
            kioskForm: emptyKioskForm,
            connectionForm: emptyKioskConnectionForm,
            viewSearch: '',
            clientSearch: '',
        }
    },
    componentDidMount() {
        let async_calls = 3,
            async_calls_finished = 0
        axios.get(`${conf.paths.root}/kiosks`)
        .then(({data}) => {
            this.setState({loading: ++async_calls_finished !== async_calls, kiosks: data})
        })
        axios.get(`${conf.paths.root}/views_printer`)
        .then(({data}) => {
            this.setState({loading: ++async_calls_finished !== async_calls, views: data})
        })
        axios.get(`${conf.paths.root}/kiosks/connections`)
        .then(({data}) => {
            this.setState({loading: ++async_calls_finished !== async_calls, connections: data})
        })
    },
    kioskFormConfirm() {
        let kiosk = this.state.kioskForm
        if (!kiosk.name) {
            alert('Kiosk has to have a name')
            return
        }
        if (!kiosk.windows_cd_key) {
            alert('Kiosk has to have a Windows CD key')
            return
        }
        if (!kiosk.printer_model) {
            alert('Kiosk has to have a printer model')
            return
        }
        if (!kiosk.printer_serial_number) {
            alert('Kiosk has to have a printer serial number')
            return
        }
        if (!kiosk.location) {
            alert('Kiosk has to have a location')
            return
        }
        if (!kiosk.rent_or_sold || kiosk.rent_or_sold === '-') {
            alert('Is this kiosk rented or sold?')
            return
        }
        if (!kiosk.client_id || kiosk.client_id === '-') {
            alert('Kiosk has to have a client')
            return
        }
        if (kiosk.id !== null) {
            this.updateKiosk(kiosk)
        } else {
            this.addKiosk(kiosk)
        }
    },
    connectionFormConfirm(view_uid) {
        let connection = this.state.connectionForm
        connection.view_uid = view_uid
        this.addConnection(connection)
    },
    addKiosk(kiosk) {
        axios.put(`${conf.paths.root}/kiosks`, kiosk)
        .then(({data}) => {
            this.setState({kiosks: data, showKioskPopup: false, kioskForm: emptyKioskForm})
        })
        .catch(ex => {
            if (ex.response.status === 409) {
                alert(`Kiosk with name ${this.state.kioskForm.name} alredy exists.`)
            } else {
                alert('Unexpected error happened')
            }
            console.log('Failed to add kiosk: ' + ex.message)
        })
    },
    updateKiosk(kiosk) {
        axios.post(`${conf.paths.root}/kiosks/${kiosk.id}`, kiosk)
        .then(({data}) => {
            this.setState({kiosks: data, showKioskPopup: false, kioskForm: emptyKioskForm})
        })
        .catch(ex => {
            if (ex.response.status === 409) {
                alert(`Kiosk with name ${this.state.kioskForm.name} alredy exists.`)
            } else {
                alert('Unexpected error happened')
            }
            console.log('Failed to add kiosk: ' + ex.message)
        })
    },
    deleteKiosk(kiosk) {
        axios.delete(`${conf.paths.root}/kiosks/${kiosk.id}`)
        .then(({data}) => {
            this.setState({kiosks: data})
        })
        .catch(ex => {
            console.log('Failed to delete kiosk: ' + ex.message)
        })
    },
    addConnection(connection) {
        axios.put(`${conf.paths.root}/kiosks/connections`, connection)
        .then(({data}) => {
            this.setState({connections: data, showConnectionPopup: false, connectionForm: emptyKioskConnectionForm})
        })
        .catch(ex => {
            console.log('Failed to add connection: ' + ex.message)
        })
    },
    deleteConnection(connection) {
        axios.delete(`${conf.paths.root}/kiosks/connections/${connection.id}`)
        .then(({data}) => {
            this.setState({connections: data})
        })
        .catch(ex => {
            console.log('Failed to delete connection: ' + ex.message)
        })
    },
    handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.kioskFormConfirm()
        }
    },
    allowSession(kioskItem) {
        axios.post(`${conf.paths.root}/kiosks/connections/${kioskItem.connection_id}/allow`)
        .then(({data}) => {
            this.setState({connections: data})
        })
        .catch(ex => {
            console.log('Failed to delete connection: ' + ex.message)
        })
    },
    disallowSession(kioskItem) {
        axios.post(`${conf.paths.root}/kiosks/connections/${kioskItem.connection_id}/disallow`)
        .then(({data}) => {
            this.setState({connections: data})
        })
        .catch(ex => {
            console.log('Failed to delete connection: ' + ex.message)
        })
    },
    render() {
        const kiosks = this.state.kiosks
        const connections = this.state.connections
        const views = sortBy(this.state.views, ['account_name', 'project_name', 'data.name'], ['asc'])
        .map(i => {
            if (!i.account_name) i.account_name = 'UNKNOWN'
            if (!i.project_name) i.project_name = 'UNKNOWN'
            if (!i.data.name) i.data.name = 'UNKNOWN'
            return i
        })
        .filter(i => i.account_name.toLowerCase().indexOf(this.state.viewSearch.toLowerCase()) !== -1
            || i.project_name.toLowerCase().indexOf(this.state.viewSearch.toLowerCase()) !== -1
            || i.data.name.toLowerCase().indexOf(this.state.viewSearch.toLowerCase()) !== -1)
        const kioskItems = kiosks.map(i => {
            const kioskConnections = connections.filter(c => c.kiosk_uid === i.id)
            if (kioskConnections.length > 0) {
                i.connected = true
                const kioskConnection = kioskConnections[0]
                i.connection_id = kioskConnection.id
                i.session = kioskConnection.session
                i.sessions_allowed = kioskConnection.allowed
                i.view_uid = kioskConnection.view_uid
                const kioskViews = views.filter(v => v.id === i.view_uid)
                if (kioskViews.length > 0) {
                    const kioskView = kioskViews[0]
                    i.account_name = kioskView.account_name
                    i.project_uid = kioskView.project_uid
                    i.project_name = kioskView.project_name
                    i.view_name = kioskView.data.name
                }
            } else {
                i.connected = false
            }
            return i
        })
        const clients = this.props.clients.sort((a, b) => {
            if (a.username > b.username) return 1
            if (a.username < b.username) return -1
            return 0
        })
        .filter(i => i.username.toLowerCase().indexOf(this.state.clientSearch.toLowerCase()) !== -1
            || i.name.toLowerCase().indexOf(this.state.clientSearch.toLowerCase()) !== -1
            || i.email.toLowerCase().indexOf(this.state.clientSearch.toLowerCase()) !== -1)
        return <div className="box">
            <div className="popup" style={{display: this.state.showKioskPopup ? 'flex' : 'none'}} onClick={(e) => e.target.className === 'popup' ? this.setState({kioskForm: emptyKioskForm, showKioskPopup: false}) : null}>
                <div id="kiosk-form">
                    {this.state.kioskForm.id ? <div id="kiosk-form-id"><span>KioskID:&nbsp;</span><input type="text" readOnly={true} value={this.state.kioskForm.id} /></div> : null}
                    <div id="kiosk-form-fields">
                        <div className="kiosk-form-field">
                            <label htmlFor="kiosk-name">{_('kiosks.kiosk-name')}</label>
                            <input type="text" id="kiosk-name" name="kiosk-name" value={this.state.kioskForm.name} onChange={(e) => this.setState({kioskForm: {...this.state.kioskForm, name: e.target.value}})} onKeyDown={this.handleKeyDown}></input>
                        </div>
                        <div className="kiosk-form-field">
                            <label htmlFor="kiosk-windows-cd-key">{_('kiosks.kiosk-windows-cd-key')}</label>
                            <input type="text" id="kiosk-windows-cd-key" name="kiosk-name" value={this.state.kioskForm.windows_cd_key} onChange={(e) => this.setState({kioskForm: {...this.state.kioskForm, windows_cd_key: e.target.value}})} onKeyDown={this.handleKeyDown}></input>
                        </div>
                        <div className="kiosk-form-field">
                            <label htmlFor="kiosk-printer-model">{_('kiosks.kiosk-printer-model')}</label>
                            <input type="text" id="kiosk-printer-model" name="kiosk-printer-model" value={this.state.kioskForm.printer_model} onChange={(e) => this.setState({kioskForm: {...this.state.kioskForm, printer_model: e.target.value}})} onKeyDown={this.handleKeyDown}></input>
                        </div>
                        <div className="kiosk-form-field">
                            <label htmlFor="kiosk-printer-serial-number">{_('kiosks.kiosk-printer-serial-number')}</label>
                            <input type="text" id="kiosk-printer-serial-number" name="kiosk-printer-serial-number" value={this.state.kioskForm.printer_serial_number} onChange={(e) => this.setState({kioskForm: {...this.state.kioskForm, printer_serial_number: e.target.value}})} onKeyDown={this.handleKeyDown}></input>
                        </div>
                        <div className="kiosk-form-field">
                            <label htmlFor="kiosk-location">{_('kiosks.kiosk-location')}</label>
                            <input type="text" id="kiosk-location" name="kiosk-location" value={this.state.kioskForm.location} onChange={(e) => this.setState({kioskForm: {...this.state.kioskForm, location: e.target.value}})} onKeyDown={this.handleKeyDown}></input>
                        </div>
                        <div className="kiosk-form-field">
                            <label htmlFor="kiosk-rent-or-sold">{_('kiosks.kiosk-rent-or-sold')}</label>
                            <div id="kiosk-rent-or-sold">
                                <input type="radio" name="kiosk-rent-or-sold" checked={this.state.kioskForm.rent_or_sold === 'rent'} onChange={() => this.setState({kioskForm: {...this.state.kioskForm, rent_or_sold: 'rent'}})}/>
                                <span>Rent</span>
                                <input type="radio" name="kiosk-rent-or-sold" checked={this.state.kioskForm.rent_or_sold === 'sold'} onChange={() => this.setState({kioskForm: {...this.state.kioskForm, rent_or_sold: 'sold'}})}/>
                                <span>Sold</span>
                            </div>
                        </div>
                        <div className="kiosk-form-field">
                            <label htmlFor="kiosk-client">{_('kiosks.kiosk-client')}</label>
                            <input type="text" id="kiosk-client" name="kiosk-client" placeholder="Search..." value={this.state.clientSearch} onChange={(e) => this.setState({clientSearch: e.target.value})}/>
                            <div id="view-select" style={{width: 622}}>
                                {clients.map((client, n) => <div className={`view-select-item ${this.state.kioskForm.client_id === client.id ? 'selected' : ''}`} key={n} onClick={() => this.setState({kioskForm: {...this.state.kioskForm, client_id: client.id}})}>{client.username} | {client.name} | {client.email}</div>)}
                            </div>
                        </div>
                        <div className="kiosk-form-field">
                            <label htmlFor="kiosk-comment">{_('kiosks.kiosk-comment')}</label>
                            <input type="text" id="kiosk-comment" name="kiosk-comment" value={this.state.kioskForm.comment} onChange={(e) => this.setState({kioskForm: {...this.state.kioskForm, comment: e.target.value}})} onKeyDown={this.handleKeyDown}></input>
                        </div>
                    </div>
                    <div id="kiosk-form-buttons">
                        <button onClick={() => this.kioskFormConfirm()}>{_('common.save')}</button>
                        <button onClick={() => this.setState({kioskForm: emptyKioskForm, showKioskPopup: false})}>{_('common.cancel')}</button>
                    </div>
                </div>
            </div>
            <div className="popup" style={{display: this.state.showConnectionPopup ? 'flex' : 'none'}} onClick={(e) => e.target.className === 'popup' ? this.setState({connectionForm: emptyKioskConnectionForm, showConnectionPopup: false}) : null}>
                <div id="kiosk-form">
                    <div id="kiosk-form-fields">
                        <label htmlFor="view_search">{_('common.search')}</label>
                        <input type="text" id="view-search" name="view_search" placeholder="Search..." value={this.state.viewSearch} onChange={(e) => this.setState({viewSearch: e.target.value})}/>
                        <div id="view-select">
                            {views.map((i, n) =>
                                <div className="view-select-item" key={n} onClick={() => this.connectionFormConfirm(i.id)}>{i.account_name} | {i.project_name} | {i.data.name}</div>
                            )}
                        </div>
                    </div>
                    <div id="kiosk-form-buttons">
                        <button onClick={() => this.setState({connectionForm: emptyKioskConnectionForm, showConnectionPopup: false})}>{_('common.cancel')}</button>
                    </div>
                </div>
            </div>
            <div className="layout">
                <div className="main_title">
                    <h1>{_('kiosks.header')}</h1>
                    <button className='add_btn table_list_btn' onClick={() => this.setState({showKioskPopup: true})}>{_('kiosks.add')}</button>
                </div>
                <div className="white_panel shadow">
                    {this.state.loading ?
                        <div>Loading...</div> :
                        kioskItems.length === 0 ?
                            <div>No kiosks</div> :
                            <div>
                                <div className="kiosk-item">
                                    <span>Kiosk Name</span>
                                    <span>Connected to Event?</span>
                                    <span>Session ID</span>
                                    <span>Session Allowed?</span>
                                    <span></span>
                                </div>
                                {kioskItems.map((i, n) => <div className="kiosk-item" key={n}>
                                        <span>{i.name}</span>
                                        <span>
                                            {i.connected ?
                                                <a href={`${conf.paths.root}/views_printer/${i.project_uid}/${i.view_uid}`} target="_blank">{`${i.account_name} | ${i.project_name} | ${i.view_name}`}</a> :
                                            'No'}
                                        </span>
                                        <span>{i.connected ? (i.session ? i.session : 'No') : ''}</span>
                                        <span>{i.connected ? (i.session ? (i.sessions_allowed ? 'Yes' : 'No') : '') : ''}</span>
                                        <span></span>
                                        {(i.connected && i.session) ?
                                            (i.sessions_allowed ?
                                                <span className='kiosk-allow kiosk-allowed'><a href='#' onClick={() => this.disallowSession(i)}>{_('kiosks.disallow')}</a></span> :
                                                <span className='kiosk-allow'><a href='#' onClick={() => this.allowSession(i)}>{_('kiosks.allow')}</a></span>) :
                                            null
                                        }
                                        {i.connected ?
                                            <span className='kiosk-connect kiosk-connected'><a href='#' onClick={() => this.deleteConnection({id: i.connection_id})}>{_('kiosks.disconnect')}</a></span> :
                                            <span className='kiosk-connect'><a href='#' onClick={() => this.setState({showConnectionPopup: true, connectionForm: {...this.state.connectionForm, kiosk_uid: i.id}})}>{_('kiosks.connect')}</a></span>
                                        }
                                        <span><a href='#' onClick={() => this.setState({showKioskPopup: true, kioskForm: i})}>{_('common.edit')}</a></span>
                                        <span><a href='#' onClick={() => this.deleteKiosk(i)}>{_('common.delete')}</a></span>
                                    </div>)}
                            </div>}
                </div>
            </div>
        </div>
    }
})

const mapStateToProps = state => ({
    clients: state.admin_accounts.accounts.filter(i => i.roles.length === 1 && i.roles[0] === 'ROLE_USER')
})
const mapStateToDispatch = () => ({

})

KiosksComponent = connect(
    mapStateToProps,
    mapStateToDispatch
)(KiosksComponent)

export default KiosksComponent