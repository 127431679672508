import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'
import { connect } from 'react-redux'

import SideMenu from './SideMenu'
import Header from './Header'

import DeleteModal from './Modals/DeleteModal'
import ConfirmModal from './Modals/ConfirmModal'
import AlertModal from './Modals/AlertModal'
import { getRouteName } from '../helpers/utils'
import logo_icon from '../assets/images/logo_icon.png'

import { _ } from '../helpers/translations'

import { InitializeDatatables, InitializeScroll, SetHeight } from '../assets/js/common'


const MainComponent = createReactClass({
    propTypes: {
        account: PropTypes.object.isRequired,
        isFetching: PropTypes.bool.isRequired,
        auth: PropTypes.object.isRequired,
        children: PropTypes.any,
        routeName: PropTypes.string,
    },
    componentDidMount() {
        SetHeight()
        InitializeDatatables()
        InitializeScroll()
    },
    componentWillReceiveProps(nextProps) {
        if (this.props.account.language !== nextProps.account.language) {
            setTimeout(() => {
                this.forceUpdate()
            }, 500)
        }
    },
    render() {
        const account = this.props.account
        const auth = this.props.auth
        let isFetching = this.props.isFetching
        if (isFetching) {
            return <div className="global_loading">
                <div className="txt">
                    {/* <div className="pulse"></div> */}
                    <img alt="HashsnapLogo" className="pulse" src={logo_icon} />
                    <div className="txt_logo">{_('main.hashsnap')}</div>
                    <div className="txt_loading">{_('main.loading')}</div>
                </div>
            </div>
        }
        return (
            <React.Fragment>
                {this.props.isDeleteModalVisible && <DeleteModal />}
                {this.props.isConfirmModalVisible && <ConfirmModal />}
                {this.props.isAlertModalVisible && <AlertModal />}
                <Header account={account} auth={auth} routeName={this.props.routeName} />
                <div className="container">
                    <SideMenu account={account} routeName={this.props.routeName} />
                    <div className="content">
                        {this.props.children}
                    </div>
                </div>
            </React.Fragment>
        )
    }
})


// Container
const mapStateToProps = (state, ownProps) => {
    const account = state.account
    const routeName = getRouteName(ownProps.location.pathname)
    const isFetching = state.admin_accounts.isFetching
    return {
        account,
        isFetching,
        routeName,
        auth: state.auth,
        isAlertModalVisible: state.modalAlert.isVisible,
        isConfirmModalVisible: state.modalConfirm.isVisible,
        isDeleteModalVisible: state.modalDelete.isVisible
    }
}

const mapDispatchToProps = () => ({ })

const Main = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainComponent)

export default Main
