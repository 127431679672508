import axios from 'axios'
import * as conf from '../conf'
import { _ } from '../helpers/translations'


export const fetchProject = (projectUid, runOnBackground) => (dispatch) => {
    if (!runOnBackground) {
        dispatch({type: 'REQUEST_FETCH_PROJECT'})
    }
    return axios.get(`${conf.paths.root}/projects/${projectUid}`)
    .then(({ data }) => {
        dispatch({type: 'RECEIVE_FETCH_PROJECT', project: data})
        return data
    })
    .catch(ex => {
        const msg = _('Failed to fetch project')
        dispatch({type: 'FETCH_PROJECT_ERROR', error: msg})
        throw ex
    })
}


export const createProject = (data, accountId) => (dispatch, getState) => {
    const state = getState()
    if (state.account.isFetching) {
        return false
    }
    dispatch({type: 'REQUEST_CREATE_PROJECT'})
    if (!accountId) {
        accountId = state.account.id
    }
    return axios.put(`${conf.paths.account}/${accountId}/projects`, data)
    .then(({ data }) => {
        if (data.status === 0) {
            dispatch({type: 'RECEIVE_CREATE_PROJECT', accountId, project: data.data})
            return data.data
        } else {
            throw new Error(data.error)
        }
    })
    .catch(ex => {
        const msg = _('Failed to create project')
        dispatch({type: 'CREATE_PROJECT_ERROR', error: msg})
        throw ex
    })
}

export const deleteProject = (projectUid) => (dispatch, getState) => {
    const state = getState()
    if (state.account.isFetching) {
        return false
    }
    dispatch({type: 'REQUEST_DELETE_PROJECT'})
    return axios.delete(`${conf.paths.root}/projects/${projectUid}`)
    .then(({ data }) => {
        if (data.status === 0) {
            dispatch({type: 'RECEIVE_DELETE_PROJECT', projectUid})
        } else {
            throw new Error(_('Failed to delete project'))
        }
    })
    .catch(ex => {
        dispatch({type: 'DELETE_PROJECT_ERROR', error: ex.message})
    })
}

export const updateProject = (projectUid, projectData, runOnBackground) => (dispatch, getState) => {
    const state = getState()
    if (state.admin_accounts.isFetching) {
        return false
    }
    if (!runOnBackground) {
        dispatch({type: 'REQUEST_UPDATE_PROJECT'})
    }
    return axios.post(`${conf.paths.root}/projects/${projectUid}`, projectData)
  .then(({ data }) => {
      if (data.status === 0) {
          if (!runOnBackground) {
              dispatch({type: 'RECEIVE_UPDATE_PROJECT', project: data.data})
          }
      } else {
          throw new Error(_('Failed to update project'))
      }
  })
  .catch(ex => {
      dispatch({type: 'UPDATE_PROJECT_ERROR', error: ex.message})
  })
}
