import { values } from 'lodash'
import { topRole } from '../helpers/utils'


const account = (state = {
    isFetching: true,
    projects: [],
}, action) => {
    switch (action.type) {
    case 'REQUEST_ACCOUNT':
        return state
    case 'REQUEST_SAVE_ACCOUNT':
    case 'REQUEST_FETCH_PROJECT':
    case 'REQUEST_CREATE_PROJECT':
    case 'REQUEST_DELETE_PROJECT':
    case 'REQUEST_UPDATE_PROJECT':
        return {...state, isFetching: true}
    case 'RECEIVE_ACCOUNT':
        return {
            ...state,
            ...action.account,
            isFetching: false,
            topRole: topRole(action.account.roles),
        }
    case 'RECEIVE_SAVE_ADMIN_ACCOUNT':
        return parseInt(state.id, 10) === parseInt(action.account.id, 10) ? {...state, ...action.account} : state
    case 'RECEIVE_CREATE_PROJECT':
        return {
            ...state,
            isFetching: false,
            projects: action.accountId === state.id ? [...state.projects, action.project] : state.projects
        }
    case 'RECEIVE_DELETE_PROJECT':
        return {
            ...state,
            isFetching: false,
            projects: state.projects.filter(i => i.uid !== action.projectUid)
        }
    case 'RECEIVE_FETCH_PROJECT':
    case 'RECEIVE_UPDATE_PROJECT':
        return {
            ...state,
            isFetching: false,
            projects: state.projects.map(i => i.uid !== action.project.uid ? i : ({
                ...action.project,
                whitelisted_users: values(action.project.whitelisted_users),
                blacklisted_users: values(action.project.blacklisted_users),
                keys: values(action.project.keys),
            }))
        }
    case 'UPDATE_ACCOUNT_INFO':
        return {...state, ...action.payload}
    case 'ACCOUNT_ERROR':
    case 'SAVE_ACCOUNT_ERROR':
    case 'FETCH_PROJECT_ERROR':
    case 'CREATE_PROJECT_ERROR':
    case 'DELETE_PROJECT_ERROR':
    case 'UPDATE_PROJECT_ERROR':
        return {...state, isFetching: false, error: action.error}
    default:
        return state
    }
}

export default account
