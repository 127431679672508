const reducer = (state={
    isVisible: false,
    level: 'info',
    message: '',
    callback: () => {}
}, action) => {
    switch (action.type) {
    case 'MODAL_ALERT_SHOW':
        return {isVisible: true, level: action.level || 'info', message: action.message, callback: action.callback}
    case 'MODAL_ALERT_HIDE':
    case 'MODAL_CONFIRM_SHOW':
    case 'MODAL_DELETE_SHOW':
        return {isVisible: false, level: 'info', message: '', callback: () => {}}
    default:
        return state
    }
}

export default reducer