/* global $ */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import createReactClass from 'create-react-class'

import { deleteAccount } from '../../actions/account'

import { formatDate, sortAndKeepNullsAtTheEnd, Explained } from '../../helpers/utils'
import { _ } from '../../helpers/translations'

import * as conf from '../../conf'

import { InitializeDatatables, InitializeScroll, SetHeight } from '../../assets/js/common'


const ClientsListComponent = createReactClass({
    propTypes: {
        isFetching: PropTypes.bool,
        accounts: PropTypes.array,
        account: PropTypes.object,
        deleteClient: PropTypes.func,
    },
    componentDidMount() {
        SetHeight()
        InitializeDatatables()
        InitializeScroll()

        $('.sidebar').removeClass('opened')
    },
    componentDidUpdate() {
        InitializeDatatables()
    },
    getInitialState() {
        return {
            accounts: sortAndKeepNullsAtTheEnd(this.props.accounts, 'created_date', false),
            filter: 'all',
            orderIdAsc: true,
            orderNameAsc: true,
            orderUsernameAsc: true,
            orderTypeAsc: true,
            orderCreatedDateAsc: false,
        }
    },
    componentWillReceiveProps(nextProps) {
        this.setState({accounts: sortAndKeepNullsAtTheEnd(nextProps.accounts, 'created_date', false)})
    },
    filterClients(clients) {
        let filtered_clients = []
        if (this.state.filter === 'demo') {
            filtered_clients = clients.filter(a => parseInt(a.type, 10) === 1)
        } else if (this.state.filter === 'standard') {
            filtered_clients = clients.filter(a => parseInt(a.type, 10) === 2)
        } else if (this.state.filter === 'pro') {
            filtered_clients = clients.filter(a => parseInt(a.type, 10) === 3)
        } else {
            filtered_clients = clients
        }
        return filtered_clients
    },
    render() {
        const account = this.props.account
        let clients = this.state.accounts.filter(a => a.topRole === 'user')
        clients = this.filterClients(clients).map(a => ({
            ...a,
            latestPostDate: a.projects.map(i => i.latest_post_date).reduce((agg, i) => i > agg ? i : agg, '')
        }))
        $('.table').DataTable().destroy()

        const all_accounts_by_ids = this.state.accounts.reduce((map, account) => {
            map[account.id] = {
                username: account.username,
            }
            return map
        }, {})
        // const types = {
        //     1: _('common.demo'),
        //     2: _('common.standard'),
        //     3: _('common.vip'),
        // }

        // Add self
        all_accounts_by_ids[account.id] = {username: account.username}
        return (
    <React.Fragment>
        <div className="box">
            <div className="layout">
                <div className="main_title">
                    <h1>
                        {_('clients.list.header')}
                        <Link title='Add' className='add_btn table_list_btn' role='button' to={`/accounts/${account.username}/clients/new`}>{_('clients.list.add-new')}</Link>
                    </h1>
                </div>
                {/* <div className="filters">
                    <button type='button' onClick={() => this.setState({filter: 'all'})}>{_('common.all')}</button>
                    <button type='button' onClick={() => this.setState({filter: 'demo'})}>{_('common.demo')}</button>
                    <button type='button' onClick={() => this.setState({filter: 'standard'})}>{_('common.standard')}</button>
                    <button type='button' onClick={() => this.setState({filter: 'pro'})}>{_('common.vip')}</button>
                </div> */}
                <table className="table">
                    <thead>
                        <tr>
                            <th className="all" style={{width:100}}>{_('common.column.id')}</th>
                            <th className="all">{_('clients.list.column.name')}</th>
                            <th className="no_sort all" style={{zIndex: 1}}>
                                <div style={{display:'flex'}}>
                                    <div>{_('common.login-as')}</div>
                                    <div style={{transform:'translateX(3px)'}}>
                                        <Explained message={_('explained.projects-login-as')}></Explained>
                                    </div>
                                </div>
                            </th>
                            <th className="no_sort all">{_('clients.list.column.add-project')}</th>
                            <th>{_('clients.list.column.username')}</th>
                            {/* <th>{_('clients.list.column.type')}</th> */}
                            <th>{_('clients.list.column.joined')}</th>
                            <th>{_('common.manager')}</th>
                            <th className="no_sort">{_('common.delete')}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {clients.map((client, index) => (
                        <tr key={index}>
                            <td className="center relative">{client.id}<div className="table_hover"></div></td>
                            <td className='capitalize'>
                                <Link title='Edit' to={`/accounts/${account.username}/clients/${client.username}`} className='theme'>
                                    {client.company}
                                </Link>
                            </td>
                            <td className="center relative">
                                <a target='_blank' title='Sign in' href={`${conf.host_client}/accounts/${client.username}/projects`} className="icon_action sing relative">
                                    <div className="login_list_icon icon"></div>
                                </a>
                            </td>
                            <td className="center relative">
                                <Link title='Edit' to={`/accounts/${account.username}/clients/${client.username}/projects/new`} className="icon_action sing relative">
                                    <div className='add_project_icon icon'></div>
                                </Link>
                            </td>
                            <td className="center">{client.username}</td>
                            {/* <td className="center uppercase">{types[client.type]}</td> */}
                            <td className="center">{formatDate(client.created_date)}</td>
                            <td className="center">{(client.admin_id && all_accounts_by_ids[client.admin_id]) ? all_accounts_by_ids[client.admin_id].username : ''}</td>
                            <td className="center relative">
                                <a href='#' role='button' className="cursor relative icon_action" onClick={() => this.props.deleteClient(client.username)}>
                                    <div className="delete_list_icon icon"></div>
                                </a>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    </React.Fragment>)
    }
})

// Container
const mapStateToProps = (state) => {
    return {
        isFetching: state.admin_accounts.isFetching,
        accounts: state.admin_accounts.accounts.filter(i => !i.deleted),
        account: state.account,
    }
}
const mapDispatchToProps = (dispatch) => ({
    deleteClient(username) {
        dispatch({type: 'MODAL_DELETE_SHOW', callback: (isDeleted) => {
            if (isDeleted) {
                dispatch(deleteAccount(username))
            }
        }})
    },
})
const ClientsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientsListComponent)
export default ClientsList
