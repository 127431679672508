import React from 'react'
import { connect } from 'react-redux'

import { _ } from '../../helpers/translations'


let DeleteModalComponent = ({ model, hide, onInput }) => (
    <div id="deleteModal" className="___delete modal">
        <div className="text">{_('Enter DELETE to confirm')}</div>
        <div className="block">
            <div>
                <button className="cancel_modal" type="submit" onClick={() => hide(model, false)}>
                    <div className="circle">«</div>
                    <div>{_('Cancel')}</div>
                </button>
            </div>
            <input placeholder="write DELETE" value={model.inputValue} onChange={onInput}/>
            {model.noMatch && <p style={{color:'white'}}>{_('Entered text does not match DELETE')}</p>}
            <div>
                <button className="delete_modal" type="submit" onClick={() => hide(model, true)}>
                    <div className="circle">!</div>
                    <div>{_('Delete')}</div>
                </button>
            </div>
        </div>
    </div>)

let mapStateToProps = (state) => ({
    model: state.modalDelete
})

let mapDispatchToProps = (dispatch) => ({
    hide(model, isDeleted) {
        if (!isDeleted || model.inputValue === 'DELETE') {
            model.callback(isDeleted)
            dispatch({type: 'MODAL_DELETE_HIDE'})
        } else {
            dispatch({type: 'MODAL_DELETE_NO_MATCH'})
        }
        
    },
    onInput(e) {
        dispatch({type: 'MODAL_DELETE_ON_INPUT', value: e.target.value})
    }
})

DeleteModalComponent = connect(
    mapStateToProps,
    mapDispatchToProps
  )(DeleteModalComponent)

export default DeleteModalComponent