const auth = (state={
    token: null,
    username: null,
    isFetching: false,
}, action) => {
    switch (action.type) {
    case 'SET_TOKEN':
        return {
            token: action.token,
            username: action.username,
            isFetching: false,
        }
    default:
        return state
    }
}

export default auth
