import React from 'react'

let displayChange = (change) => {
    let from = change.old
    if (typeof(change.old) == typeof(true)){
        from = change.old ? 'Yes' : 'No'
    }
    let to = change.new
    if (typeof(change.new) == typeof(true)){
        to = change.new ? 'Yes' : 'No'
    }
    return <code>
        <span>{change.field}</span>&nbsp;
            <span style={{color:'red'}}>{from}</span>
            &nbsp;=>&nbsp;
            <span style={{color:'green'}}>{to}</span>
        </code>
}

let ChangelogTable = (changelog) => (
    <table className='changelog-table'>
        <thead>
            <tr>
                <th style={{width:230}}>When</th>
                <th style={{width:180}}>Who</th>
                <th>Diff</th>
            </tr>
        </thead>
        <tbody>
            {changelog.sort((a, b) => a.date === b.date ? 0 : a.date > b.date ? 1 : -1).map((i, n) => <tr key={n}>
                <td>{i.date}</td>
                <td>{i.user}</td>
                <td>
                    {i.diff.map((_i, _n) => <div key={_n}>
                        {displayChange(_i)}
                    </div>)}
                </td>
            </tr>)}
        </tbody>
    </table>
)

export default ChangelogTable
