import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import { _ } from '../helpers/translations'
import classnames from 'classnames'


const SideMenu = ({ account, routeName }) => {

    return (
      <div className="sidebar">
        <ul className="menu">
          {account.topRole === 'superadmin' &&
          <li className={classnames({'active': routeName === 'admins'})}>
            <Link to={`/accounts/${account.username}/admins`} className="has_icon sidebar_label">
              <div className='icon_control'>
                <div className="sidebar_admins_icon"></div>
              </div>
              {_('sidebar.admins')}
            </Link>
          </li>}
          <li className={classnames({'active': routeName === 'clients'})}>
            <Link to={`/accounts/${account.username}/clients`} className="has_icon sidebar_label">
              <div className='icon_control'>
                <div className="sidebar_clients_icon"></div>
              </div>
              {_('sidebar.clients')}
            </Link>
          </li>
          <li className={classnames({'active': routeName === 'projects'})}>
            <Link to={`/accounts/${account.username}/projects`} className="has_icon sidebar_label">
              <div className='icon_control'>
                <div className="sidebar_projects_icon"></div>
              </div>
              {_('sidebar.projects')}
            </Link>
          </li>
          {/* <li className={classnames({'active': routeName === 'project-requests'})}>
            <Link to={`/accounts/${account.username}/project-requests`} className="has_icon sidebar_label">
              <div className='icon_control'>
                <div className="sidebar_requests_icon"></div>
              </div>
              {_('sidebar.requests')}
            </Link>
          </li>
          {account.topRole === 'superadmin' &&
          <li className={classnames({'active': routeName === 'kiosks'})}>
            <Link to={`/accounts/${account.username}/kiosks`} className="has_icon sidebar_label">
              <div className='icon_control'>
                <div className="sidebar_kiosks_icon"></div>
              </div>
              {_('sidebar.kiosks')}
            </Link>
          </li>} */}
        </ul>
      </div>)
}
SideMenu.propTypes = {
    account: PropTypes.object,
    routeName: PropTypes.string,
}

export default SideMenu
