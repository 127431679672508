import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, browserHistory } from 'react-router'
import createReactClass from 'create-react-class'
import axios from 'axios'

import moment from 'moment'

import { saveAdminAccount, deleteAccount } from '../../actions/account'
import { deleteProject } from '../../actions/project'

import { _, getLanguages } from '../../helpers/translations'
import countries from '../../helpers/countries'
import { formatDate } from '../../helpers/utils'
import { timezones } from '../../helpers/timezones'
import ChangelogTable from '../ChangelogTable'
import { find } from 'lodash'

import * as conf from '../../conf'

import { InitializeDatatables, InitializeScroll, SetHeight } from '../../assets/js/common'
// import vip_icon from '../../assets/images/icons/vip_icon.png'


const ClientInfoComponent = createReactClass({
    propTypes: {
        account: PropTypes.object,
        client: PropTypes.object,
        deleteAccount: PropTypes.func,
        deleteProject: PropTypes.func,
        admins: PropTypes.array,
        languages: PropTypes.array,
    },
    getInitialState() {
        return {
            viewChangelog: false,
            changelog: []
        }
    },
    componentDidMount() {
        SetHeight()
        InitializeDatatables()
        InitializeScroll()
        axios.get(`${conf.host_api}/changelog/${this.props.client.username}`)
            .then(({data}) => {
                this.setState({changelog: data})
            })
    },
    render() {
        const account = this.props.account
        const client = this.props.client
        if (!client) {
            return null
        }
        return (
            <React.Fragment>
                <div className="box">
                    <div className="layout">
                        <div className="main_title">
                            <h1>{_('clients.info.header')}</h1>
                        </div>
                        <div className="grid_2_first_s">
                            <div>
                                <div className="center profile_intro">
                                    {/* {parseInt(client.type, 10) === 3 &&
                                        <div className="type">
                                             <img src={ vip_icon } />
                                             <div>{_('common.vip')}</div>
                                        </div>
                                    }
                                    {parseInt(client.type, 10) === 2 &&
                                        <div className="type">
                                             <div>{_('common.standard')}</div>
                                        </div>
                                    }
                                    {parseInt(client.type, 10) === 1 &&
                                        <div className="type">
                                             <div>{_('common.demo')}</div>
                                        </div>
                                    } */}
                                    <div className="index_top">
                                        <div className="profile_name">{client.company}</div>
                                        <div className="size_30">({client.username})</div>
                                        <div className="date">{_('common.created-on')}&nbsp;{formatDate(client.created_date)}</div>
                                    </div>
                                </div>
                                <div className="tools">
                                    <div className="adjust">
                                        <div>
                                            <Link className="round sing" to={`/accounts/${account.username}/clients/${client.username}/edit`}>
                                                <div className="edit_icon icon"></div>
                                            </Link>
                                            <div className="size_13 mtop_5">{_('common.edit')}</div>
                                        </div>
                                        <div>
                                            <a className="round sing" href={`${conf.host_client}/accounts/${client.username}/projects`} target="_blank">
                                                <div className="login_icon icon"></div>
                                            </a>
                                            <div className="size_13 mtop_5">{_('common.login-as')}</div>
                                        </div>
                                        <div>
                                            <a className="round delete cursor" onClick={() => this.props.deleteAccount(client.username)}>
                                                <div className="delete_icon icon"></div>
                                            </a>
                                            <div className="size_13 mtop_5">{_('common.delete')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="white_panel">
                                    <table className="profile_data">
                                        <tbody>
                                            <tr>
                                                <td><div className="label">{_('common.country')}:</div></td>
                                                <td>{(find(countries, i => i.code === client.country) || {}).name}</td>
                                            </tr>
                                            <tr>
                                                <td><div className="label">{_('common.timezone')}:</div></td>
                                                <td>{(find(timezones, i => i.value === client.timezone) || {}).text}</td>
                                            </tr>
                                            <tr>
                                                <td><div className="label">{_('clients.edit.input.name')}:</div></td>
                                                <td>{client.name || '-'}</td>
                                            </tr>
                                            <tr>
                                                <td><div className="label">{_('common.phone')}:</div></td>
                                                <td>{client.phone || '-'}</td>
                                            </tr>
                                            <tr>
                                                <td><div className="label">{_('common.email')}:</div></td>
                                                <td>{client.email}</td>
                                            </tr>
                                            <tr>
                                                <td><div className="label">{_('common.language')}:</div></td>
                                                <td>{client.language.split('_')[0]}</td>
                                            </tr>
                                            {account.topRole === 'superadmin' &&
                                            <tr>
                                                <td><div className="label">{_('common.admin')}:</div></td>
                                                <td>{(find(this.props.admins, i => i.id === client.admin_id) || {}).username}</td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td><div className="label border">{_('clients.info.extra')}</div></td>
                                        </tr>
                                        <tr>
                                            <td>{client.extra}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="mtop_25">
                            <h3>
                                <div className="projects_icon"></div>{_('clients.info.projects-list')}
                                <Link title='Edit' className='add_btn inner_list_btn' role='button' to={`/accounts/${account.username}/clients/${client.username}/projects/new`}>{_('clients.info.projects-list.new')}</Link>
                            </h3>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="all">{_('common.column.id')}</th>
                                        <th className="all">{_('clients.info.projects-list.name')}</th>
                                        <th>{_('common.services')}</th>
                                        <th>{_('clients.info.projects-list.package')}</th>
                                        <th>{_('clients.info.projects-list.printer')}</th>
                                        <th>{_('clients.info.projects-list.period')}</th>
                                        <th>{_('clients.info.projects-list.created')}</th>
                                        <th>{_('clients.info.projects-list.status')}</th>
                                        <th className="no_sort all">{_('common.cancel')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {client.projects.filter(i => !i.deleted).map((project, index) => (
                                    <tr key={index}>
                                        <td className="center relative">{project.id}<div className="table_hover"></div></td>
                                        <td className='capitalize relative'>
                                            <Link to={`/accounts/${account.username}/clients/${client.username}/projects/${project.uid}`} className='theme'>{project.name}</Link>
                                        </td>
                                        <td className='center size_12 uppercase'>{project.services.join(', ')}</td>
                                        <td className='center size_12 uppercase'>
                                            {parseInt(project.type, 10) === 3 &&
                                                <div className="type">
                                                     <div className="mtop_5 uppercase">{_('common.vip')}</div>
                                                </div>
                                            }
                                            {parseInt(project.type, 10) === 2 &&
                                                <div className="type">
                                                     <div className="mtop_5 uppercase">{_('common.standard')}</div>
                                                </div>
                                            }
                                            {parseInt(project.type, 10) === 1 &&
                                                <div className="type">
                                                     <div className="mtop_5 uppercase">{_('common.demo')}</div>
                                                </div>
                                            }
                                        </td>
                                        <td className="center">-</td>
                                        <td className='center size_12'>{moment(project.end_date).diff(project.start_date, 'days') + ' ' + _('clients.info.projects-list.days')}</td>
                                        <td className='center size_12'>{formatDate(project.created_date)}</td>
                                        <td>{project.status === 'activated' ?
                                            <div className="center"><i className="icon-checkmark-circle active_icon size_25 green"></i></div> :
                                            <div className="center"><i className="con-spinner9 spin active_icon size_25 orange"></i></div>
                                        }</td>
                                        <td className="center relative">
                                            <a href='#' role='button' className="cursor relative icon_action" onClick={() => this.props.deleteProject(project.uid)}>
                                                <div className="delete_list_icon icon"></div>
                                            </a>
                                        </td>
                                    </tr>))}
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <p onClick={() => this.setState({viewChangelog: !this.state.viewChangelog})}>{this.state.viewChangelog ? 'Hide Changelog' : 'View Changelog'}</p>
                            {this.state.viewChangelog && ChangelogTable(this.state.changelog)}
                        </div>
                    </div>
                </div>
            </React.Fragment>)
    }
})


let mapStateToProps = (state, ownProps) => {
    const client = find(state.admin_accounts.accounts, a => a.username === ownProps.params.client_username)
    const account = state.account
    const languages = getLanguages()
    return {
        account,
        client,
        languages,
        admins: [account, ...state.admin_accounts.accounts.filter(a => a.roles.includes('ROLE_ADMIN'))],
    }
}

let mapDispatchToProps = (dispatch, ownProps) => ({
    onSubmit(data) {
        dispatch(saveAdminAccount(data))
    },
    deleteAccount(username) {
        dispatch({type: 'MODAL_DELETE_SHOW', callback: (isDeleted) => {
            if (isDeleted) {
                browserHistory.push(`/accounts/${ownProps.params.account_username}/clients`)
                dispatch(deleteAccount(username))
            }
        }})
    },
    deleteProject(projectUid) {
        dispatch({type: 'MODAL_DELETE_SHOW', callback: (isDeleted) => {
            if (isDeleted) {
                dispatch(deleteProject(projectUid))
            }
        }})
    },
})

const ClientInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientInfoComponent)

export default ClientInfo

