import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, browserHistory } from 'react-router'
import createReactClass from 'create-react-class'
import axios from 'axios'

import { saveAdminAccount, deleteAccount } from '../../actions/account'

import { _ } from '../../helpers/translations'
import countries from '../../helpers/countries'
import { timezones } from '../../helpers/timezones'
import { formatDate } from '../../helpers/utils'
import ChangelogTable from '../ChangelogTable'
import { find } from 'lodash'

import * as conf from '../../conf'

import { InitializeDatatables, InitializeScroll, SetHeight } from '../../assets/js/common'


const AdminInfoComponent = createReactClass({
    propTypes: {
        account: PropTypes.object,
        admin: PropTypes.object,
        deleteClient: PropTypes.func,
        deleteAdmin: PropTypes.func,
        clients: PropTypes.array,
    },
    getInitialState() {
        return {
            viewChangelog: false,
            changelog: []
        }
    },
    componentDidMount() {
        SetHeight()
        InitializeDatatables()
        InitializeScroll()
        axios.get(`${conf.host_api}/changelog/${this.props.admin.username}`)
        .then(({data}) => {
            this.setState({changelog: data})
        })
    },
    render() {
        const admin = this.props.admin
        if (!admin) {
            return null
        }
        const account = this.props.account
        return (
            <React.Fragment>
                <div className="box">
                    <div className="layout">
                        <div className="main_title">
                            <h1>{_('admins.info.header')}</h1>
                        </div>
                        <div className="grid_2_first_s">
                            <div>
                                <div className="center profile_intro">
                                    <div className="index_top">
                                        <div className="profile_name">{admin.name}</div>
                                        <div className="size_30">({admin.username})</div>
                                        <div className="date">{_('common.created-on')} {formatDate(admin.created_date)}</div>
                                    </div>
                                </div>
                                <div className="tools">
                                    <div className="adjust">
                                        <div>
                                            <Link className="round sing" to={`/accounts/${account.username}/admins/${admin.username}/edit`}>
                                                <div className="edit_icon icon"></div>
                                            </Link>
                                            <div className="size_13 mtop_5">{_('common.edit')}</div>
                                        </div>
                                        <div>
                                            <a className="round sing" href={`${conf.host_admin}/accounts/${admin.username}/clients`} target="_blank">
                                                <div className="login_icon icon"></div>
                                            </a>
                                            <div className="size_13 mtop_5">{_('common.login-as')}</div>
                                        </div>
                                        <div>
                                            <a className="round delete cursor" onClick={() => this.props.deleteAdmin(admin.username)}>
                                                <div className="delete_icon icon"></div>
                                            </a>
                                            <div className="size_13 mtop_5">{_('common.delete')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="white_panel">
                                    <table className="profile_data">
                                       <tbody>
                                        <tr>
                                            <td><div className="label">{_('common.country')}:</div></td>
                                            <td>{(find(countries, i => i.code === admin.country) || {}).name}</td>
                                        </tr>
                                        <tr>
                                            <td><div className="label">{_('common.timezone')}:</div></td>
                                            <td>{(find(timezones, i => i.value === admin.timezone) || {}).text}</td>
                                        </tr>
                                        <tr>
                                            <td><div className="label">{_('common.phone')}:</div></td>
                                            <td>{admin.phone}</td>
                                        </tr>
                                        <tr>
                                            <td><div className="label">{_('common.email')}:</div></td>
                                            <td>{admin.email}</td>
                                        </tr>
                                        <tr>
                                            <td><div className="label">{_('common.language')}:</div></td>
                                            <td>{admin.language.split('_')[0]}</td>
                                        </tr>
                                       </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="mtop_25">
                            <h3>
                                <div className="projects_icon"></div>{_('admins.info.clients-list')}
                                <Link title='Add' className='add_btn' role='button' to={`/accounts/${account.username}/clients/new`}>{_('clients.list.add-new')}</Link>
                            </h3>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>{_('admins.info.clients-list.name')}</th>
                                        <th>{_('admins.info.clients-list.username')}</th>
                                        <th>{_('admins.info.clients-list.type')}</th>
                                        <th>{_('admins.info.clients-list.joined')}</th>
                                        <th>{_('common.manager')}</th>
                                        <th>{_('common.active')}</th>
                                        <th className="no_sort">{_('common.login-as')}</th>
                                        <th className="no_sort">{_('common.delete')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.clients.map((client, index) => (
                                    <tr key={index}>
                                        <td className='capitalize relative'><div className="table_hover"></div>
                                            <Link to={`/accounts/${account.username}/clients/${client.username}`} className='theme'>{client.name}</Link>
                                        </td>
                                        <td className='center size_12'>{client.username}</td>
                                        <td className='center size_12 uppercase'>
                                            {parseInt(client.type, 10) === 3 &&
                                                <div className="type">
                                                     <div className="mtop_5 uppercase">{_('common.vip')}</div>
                                                </div>
                                            }
                                            {parseInt(client.type, 10) === 2 &&
                                                <div className="type">
                                                     <div className="mtop_5 uppercase">{_('common.standard')}</div>
                                                </div>
                                            }
                                            {parseInt(client.type, 10) === 1 &&
                                                <div className="type">
                                                     <div className="mtop_5 uppercase">{_('common.demo')}</div>
                                                </div>
                                            }
                                        </td>
                                        <td className='center size_12'>{formatDate(client.created_date)}</td>
                                        <td className='center size_12'>{admin.username}</td>
                                        <td>{client.active ?
                                            <div className="center"><i className="icon-checkmark-circle active_icon size_25 green"></i></div> :
                                            <div className="center"><i className="con-spinner9 spin active_icon size_25 orange"></i></div>
                                        }</td>
                                        <td className="center relative">
                                            <a target='_blank' title='Sign in' href={`${conf.host_client}/accounts/${client.username}/projects`} className="icon_action sing relative">
                                                <div className="login_list_icon icon"></div>
                                            </a>
                                        </td>
                                        <td className="center relative">
                                            <a href='#' role='button' className="cursor relative icon_action" onClick={() => this.props.deleteClient(client.username)}>
                                                <div className="delete_list_icon icon"></div>
                                            </a>
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <p onClick={() => this.setState({viewChangelog: !this.state.viewChangelog})}>{this.state.viewChangelog ? 'Hide Changelog' : 'View Changelog'}</p>
                            {this.state.viewChangelog && ChangelogTable(this.state.changelog)}
                        </div>
                    </div>
                </div>
            </React.Fragment>)
    }
})

let mapStateToProps = (state, ownProps) => {
    const account = state.account
    const admin = find(state.admin_accounts.accounts, a => a.username === ownProps.params.admin_username)
    const clients = state.admin_accounts.accounts.filter(a => !a.deleted && a.admin_id === admin.id && a.topRole === 'user')
    return {
        account,
        admin,
        clients,
    }
}

let mapDispatchToProps = (dispatch, ownProps) => ({
    onSubmit(data) {
        dispatch(saveAdminAccount(data))
    },
    deleteClient(username) {
        dispatch({type: 'MODAL_DELETE_SHOW', callback: (isDeleted) => {
            if (isDeleted) {
                dispatch(deleteAccount(username))
            }
        }})
    },
    deleteAdmin(username) {
        dispatch({type: 'MODAL_DELETE_SHOW', callback: (isDeleted) => {
            if (isDeleted) {
                browserHistory.push(`/accounts/${ownProps.params.account_username}/clients`)
                dispatch(deleteAccount(username))
            }
        }})
    },
})

const AdminInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminInfoComponent)

export default AdminInfo

