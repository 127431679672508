const reducer = (state={
    isVisible: false,
    callback: () => {}
}, action) => {
    switch (action.type) {
    case 'MODAL_CONFIRM_SHOW':
        return {isVisible: true, callback: action.callback}
    case 'MODAL_CONFIRM_HIDE':
    case 'MODAL_ALERT_SHOW':
    case 'MODAL_DELETE_SHOW':
        return {isVisible: false, callback: () => {}}
    default:
        return state
    }
}

export default reducer